import * as Database from '../';

export const ratiosSchema = {
    title: 'ratiosSchema',
    description: 'ratios',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        type: {
            type: 'string',
        },
        ratio: {
            type: 'number',
        },
        isActivate: {
            type: 'boolean',
        },
        createdTime: {
            type: 'string',
            format: 'date-time',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        }
    }
}

// select ratios
export const selectRatiosByVoucher = async (type) => {
    const db = await Database.get();
    const query = {
        selector: {
            'type': type,
            'isActivate': true,
        },
        sort: [{ createdTime: 'desc' }],
    }

    const result = await db.ratios.findOne(query).exec();

    return JSON.parse(JSON.stringify(result));
}