import * as Database from '..';
import { localGet } from "../../utils/localStorage";

export const walletSchema = {
    title: 'walletSchema',
    description: 'wallet info',
    version: 0,
    primaryKey: 'wallet',
    type: 'object',
    properties: {
        wallet: {
            type: 'string',
            maxLength: 100,
        },
        privateKey: {
            type: 'string',
        },
        mnemonic: {
            type: 'object'
        }
    }
};

/**
 * Save wallet info
 * @param {string} wallet wallet address
 * @param {string} privateKey private key
 * @param {*} mnemonic mnemonic
 */
export const saveWalletInfo = async (wallet, privateKey, mnemonic) => {
    const db = await Database.get();
    await db.wallet.upsert({ wallet, privateKey, mnemonic });
};

/**
 * Get wallet info
 * @returns {Promise<{wallet: string, privateKey: string, mnemonic: object}>}
 */
export const getWalletInfo = async () => {
    const db = await Database.get();

    return await db.wallet.findOne({
        selector: { wallet: localGet('authAddress') }
    }).exec();
}