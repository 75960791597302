
import { tokenApi } from 'api';
import { setShowLoading, showToastMessage } from 'components/GlobalLoading';
import { BuySplitTicket } from 'components/Notification';
import { getDrawsByDrawId } from 'db/schema/draws';
import { getAllImages } from 'db/schema/images';
import { useEffect, useState } from 'react';
import { Draw } from 'typings/draw';
import { AdditionalInfo } from 'typings/notification';
import { Ticket } from 'typings/ticket';
import picture from './Group 2251.png';
import style from './inviteSplitPopUp.module.css';
type InfoType = Pick<AdditionalInfo, 'organizerId' | 'ticketNumber' | 'drawId'>
const InviteSplitPopUp = () => {
    const [isBuySplitTicketOpen, setIsBuySplitTicketOpen] = useState<boolean>(false)
    const [show, setShow] = useState(false)
    const [infos, setInfos] = useState<Ticket>()
    const [draw, setDraw] = useState<Draw>()
    const [info, setInfo] = useState<InfoType>()
    // const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(() => {
        PubSub.subscribe('syncData', async (msg, searchName) => {
            if (!searchName.splitTicketInvites) {
            } else {
                setInfos(searchName.splitTicketInvites[0]);
                setShow(true)
            }
        })
    }, [])
    const onOpenSplitTicket = async (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setShowLoading(true)
        try {
            const draw: Draw = await getDrawsByDrawId(infos?.draw)
            const images = await getAllImages()
            draw.drawImage = images[draw.drawImage]
            setIsBuySplitTicketOpen(true)
            setDraw(draw)
            setInfo({ organizerId: infos?.organizer._id, ticketNumber: infos?.ticketNumber, drawId: draw.id })
            // console.log('Debug', draw, info, infos, isBuySplitTicketOpen)
        } catch (error) {
            showToastMessage('Unknown Error.')
        }
        finally {
            setShowLoading(false)
        }
    }
    const onClosePage = () => {
        setIsBuySplitTicketOpen(false)
        setShow(false)
    }
    const onCancelConfirm = () => {
        setIsBuySplitTicketOpen(false)
    }
    const onRejectSplitTicket = async () => {
        setShowLoading(true)
        try {
            const { code, detail } = await tokenApi('/splitTicket/reject', {
                organizerId: infos?.organizer._id,
                drawId: infos?.draw,
                ticketNumber: infos?.ticketNumber,
                paymentMethod: "money",
                amount: 0
            })
            /* const { code, detail } = await tokenApi('/splitTicket/reject', {
                ticketId: infos?._id,
                paymentMethod: "money",
            }) */
            switch (Number(code)) {
                case 200:
                    break;
                default:
                    showToastMessage(detail ?? 'Unknown Error.')
                    break;
            }
        } catch (error) {
        } finally {
            setShow(false)
            setShowLoading(false)
        }
    }
    return (
        <div className={style.inviteSplitPopUp} style={{ display: show ? 'flex' : 'none' }}>
            <div className={style.main}>
                <img src={picture} alt="" />
                <p className={style.name}>{infos?.organizer?.nickname}</p>
                <div className={style.description}>Invited you to join the </div>
                <div className={style.description}>split ticket</div>
                <div className={style.info}>
                    <span>Draw Prize</span>
                    <p>${infos?.prize?.toLocaleString()}</p>
                </div>
                <div className={style.acts}>
                    <div className={style.accept} onClick={onOpenSplitTicket}>Accept</div>
                    <div className={style.reject} onClick={onRejectSplitTicket}>Reject</div>
                </div>
                <div className={style.close} onClick={() => setShow(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15 9L9 15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 9L15 15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            <div className={style.mask} onClick={() => setShow(false)}></div>
            {info && infos && draw && <BuySplitTicket isOpen={isBuySplitTicketOpen} info={info} ticket={infos} draw={draw} close={onClosePage} cancel={onCancelConfirm} />}
            {/* <IonLoading
                cssClass="my-custom-class"
                isOpen={isLoading}
                message={'Please wait...'}
                duration={10000000}
                mode="ios"
                spinner="crescent"
            /> */}
        </div>
    );
}

export default InviteSplitPopUp;