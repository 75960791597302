import { IonContent, IonPage } from "@ionic/react"
import { useUser } from "hooks"
import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { useStore } from "store"
import { tokenApi } from "../../api"
import { setShowLoading, showToastMessage } from "../../components/GlobalLoading"
import { deleteShare, saveShare } from "../../db/schema/share"
import icon from '../../image/share/share.png'
import { localGet, localSet } from "../../utils/localStorage"
import style from './Share.module.css'

const Share = () => {
    const [show, setshow] = useState(false)
    const location = useLocation()
    const { changeModalState } = useStore()
    const { getProfile, logout } = useUser()
    const initShare = async () => {
        setShowLoading(true)
        const user = await getProfile()
        if (user) return logout({ returnTo: window.location.href })
        const searchParams = new URLSearchParams(location.search);
        const code: any = searchParams.get('invite_id');
        const voucher: any = searchParams.get('voucher');
        console.log('searchParamssearchParams', code, voucher, voucher === null);

        if (code?.length > 0) {
            // localSet('inviteID', code)
            if (voucher === null) {
                await saveShare(code)
            } else {
                await saveShare(code, voucher);
            }
            if (localGet('usename')) {
                localStorage.clear()
                localSet('haslogin', true)
                logout({ returnTo: `${window.location.origin}/share?code=${code}` })
            }
            await tokenApi('/inviteLinkStatus', {
                inviteId: code,
                voucherId: voucher === null ? '' : voucher
            }).then(async (res) => {
                const user_info = localGet('user_info')
                if (user_info) return
                if (res.data) {
                    if (res?.data.status) {
                        localSet('haslogin', true)
                        setShowLoading(false)
                        changeModalState('isSignUpModalOpen', true)
                        return
                    } else {
                        // localStorage.removeItem('inviteID')
                        await deleteShare();
                        setShowLoading(false)
                        setshow(true)
                        return
                    }
                }
                if (res.detail) {
                    showToastMessage(res.detail)
                    // localStorage.removeItem('inviteID')
                    await deleteShare();
                    setTimeout(() => {
                        setShowLoading(false)
                        // history.push('/login')
                        changeModalState('isSignUpModalOpen', true)
                        return
                    }, 3000);
                }
            }).catch(() => {
                showToastMessage('Share Link Error, Please Register')
            })
        }
    }
    useEffect(() => {
        initShare()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);
    return (
        <IonPage>
            <IonContent>

                {show &&
                    < div className={style.share}>
                        <img src={icon} alt="" />
                        <p>Link Timeout</p>
                        <div>
                            <p>This link has exceeded 24 hours</p>
                            <p>Please contact the inviter. </p>
                        </div>
                    </div>}
            </IonContent>
        </IonPage >
    )
}
export default Share