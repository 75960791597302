import React, { FC } from 'react'
import style from "../style/Topdata.module.css";
import { hoursUntilTomorrow } from 'utils/time';
/* import { useOnce } from 'hooks';
import { getQuests } from 'db/schema/quests';
import { Daily, DailyType } from 'typings/daily';
import { showToastMessage } from './GlobalLoading';
const PROGRESS_BACKGROUND = 'linear-gradient(270.17deg, #7190FF 0.15%, #AC8FFF 99.88%)'
const FINISH_BACKGROUND = 'linear-gradient(270.17deg, #5CE46A 0.15%, #2EA51B 99.88%)' */
/* interface DailyProps {

}
const DailyUI: FC<DailyProps> = () => {
    const [dailies, setDailies] = useState<Daily[]>([])
    const quests = useMemo(() => dailies?.filter(daily => daily.questCategory.type === DailyType.join_draw), [dailies]);
    const checkins = useMemo(() => dailies?.filter(daily => daily.questCategory.type === DailyType.check_in).sort((prev, next) => prev.questCategory.targetQty - next.questCategory.targetQty), [dailies])
    useOnce(async () => {
        showToastMessage('This feature is not yet available.')
        const dailies = await getQuests()
        console.log(dailies)
        setDailies(dailies)
    })
    return (
        <div className={style.daily}>
            {checkins.length > 0 && <DailyCheckIn checkins={checkins} />}
            {quests.length > 0 && <DailyQuests quests={quests} />}
        </div>
    )
}

export default DailyUI

interface DailyCheckInProps {
    checkins: Daily[]
}
interface CheckInProps {
    qty: number,
    targetQty: number,
    award: number
} */
/* const DailyCheckIn: FC<DailyCheckInProps> = ({ checkins }) => {
    const max_days = checkins[checkins.length - 1].questCategory.targetQty
    const current_days = checkins[0].qty

    const CheckIn: FC<CheckInProps> = ({ qty, targetQty, award }) => {
        const percent = ((qty / targetQty) * 100).toFixed(2);
        // const isOverDays = qty >= targetQty
        return <>
            <div className={style.liner}>
                <div className={style.lining} style={{ width: percent + '%', background: Number(percent) >= 100 ? FINISH_BACKGROUND : PROGRESS_BACKGROUND }}></div>
            </div>
            <div className={style.one}>
                <div>
                    <PointSVG />
                    <span>{award}</span>
                </div>
                <span>Day {targetQty}</span>
            </div>
        </>
    }
    return <>
        <div className={style.tit}>
            <p>Daily Check in</p>
            <span>
                <CalendarSVG />
                Day {current_days} of {max_days}
            </span>
        </div>
        <div className={style.checkIn}>
            <div className={style.top}>
                {checkins.map(checkin => <CheckIn qty={checkin.qty} targetQty={checkin.questCategory.targetQty} award={checkin.questCategory.award} />)}
                                <div className={style.liner}>
                    <div className={style.lining}></div>
                </div>
                <div className={style.one}>
                    <div>
                        <PointSVG />
                        <span>10</span>
                    </div>
                    <span>Day 14</span>
                </div>
                <div className={style.liner}></div>
                <div className={style.one}>
                    <div>
                        <PointSVG />
                        <span>20</span>
                    </div>
                    <span>Day 21</span>
                </div>
            </div>
            <div className={style.bottom}>
                <CheckSVG />
                Checked-in
            </div>
        </div></>
} */
/* interface DailyQuestsProps {
    quests: Daily[]
}
interface QuestProps {
    type: string,
    qty: number,
    targetQty: number,
    award: number
}
const DailyQuests: FC<DailyQuestsProps> = ({ quests }) => {
    const hours = hoursUntilTomorrow()
    const Quest: FC<QuestProps> = ({ type, qty, targetQty, award }) => {
        const percent = ((qty / targetQty) * 100).toFixed(2);
        return <li>
            <div className={style.left}>
                <div className={style.des}>
                    <p>{type}</p>
                    <span>{qty}/{targetQty}</span>
                </div>
                <div className={style.liner}>
                    <div className={style.lin1} style={{ width: percent + '%', background: Number(percent) >= 100 ? FINISH_BACKGROUND : PROGRESS_BACKGROUND }}></div>
                </div >
            </div >
            <div className={style.right}>
                <PointSVG />
                <span>{award}</span>
            </div>
        </li >
    }
    return <>
        <div className={style.tit}>
            <p>Daily Quests</p>
            <span>
                <TimeSVG />
                {hours} Hours
            </span>
        </div>
        <ul className={style.task}>
            {
                quests.map((quest) => <Quest key={quest._id} type={quest.questCategory.type} qty={quest.qty} targetQty={quest.questCategory.targetQty} award={quest.questCategory.award} />)
            }
        </ul>
    </>
} */


type SVGProps = React.SVGProps<SVGSVGElement>;

const CalendarSVG: FC<SVGProps> = (props) => <svg  {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.333 2.00002H12.6663V0.666687H11.333V2.00002H4.66634V0.666687H3.33301V2.00002H2.66634C1.93301 2.00002 1.33301 2.60002 1.33301 3.33335V14C1.33301 14.7334 1.93301 15.3334 2.66634 15.3334H13.333C14.0663 15.3334 14.6663 14.7334 14.6663 14V3.33335C14.6663 2.60002 14.0663 2.00002 13.333 2.00002ZM13.333 14H2.66634V6.66669H13.333V14ZM13.333 5.33335H2.66634V3.33335H13.333V5.33335Z" fill="#FF9950" />
</svg>

const PointSVG: FC<SVGProps> = (props) => <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_314_6109)">
        <path d="M2.48118 2H6.94672C6.99616 1.99999 7.04489 2.01476 7.08894 2.04311C7.13299 2.07146 7.1711 2.11258 7.20017 2.1631L8.39785 4.24388C8.41914 4.2809 8.42962 4.32611 8.42751 4.3718C8.4254 4.41749 8.41082 4.46084 8.38626 4.49448L4.82893 9.36427C4.81426 9.3843 4.79648 9.40028 4.77669 9.41121C4.75691 9.42214 4.73555 9.42778 4.71395 9.42778C4.69236 9.42778 4.671 9.42214 4.65121 9.41121C4.63143 9.40028 4.61364 9.3843 4.59898 9.36427L1.04165 4.49487C1.01701 4.46121 1.00238 4.41778 1.00027 4.372C0.998152 4.32622 1.00868 4.28094 1.03005 4.24388L2.22774 2.1631C2.2568 2.11258 2.29492 2.07146 2.33897 2.04311C2.38302 2.01476 2.43175 1.99999 2.48118 2Z" fill="#42E0A7" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.11133 8.76621L7.32342 10.4254C7.33809 10.4454 7.35587 10.4614 7.37566 10.4723C7.39544 10.4832 7.4168 10.4889 7.4384 10.4889C7.45999 10.4889 7.48135 10.4832 7.50114 10.4723C7.52092 10.4614 7.53871 10.4454 7.55337 10.4254L11.1107 5.55557C11.1353 5.52194 11.1498 5.47858 11.152 5.43289C11.1541 5.3872 11.1436 5.342 11.1223 5.30498L9.92461 3.2242C9.89555 3.17367 9.85743 3.13256 9.81338 3.10421C9.76933 3.07586 9.7206 3.06109 9.67117 3.0611H8.56259L9.24339 4.24387C9.26468 4.28089 9.27516 4.32609 9.27305 4.37178C9.27093 4.41747 9.25636 4.46082 9.2318 4.49446L6.11133 8.76621Z" fill="#42E0A7" />
    </g>
    <defs>
        <clipPath id="clip0_314_6109">
            <rect width="12" height="12" fill="white" />
        </clipPath>
    </defs>
</svg>
const CheckSVG: FC<SVGProps> = (props) =>
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99967 0.333313C3.31967 0.333313 0.333008 3.31998 0.333008 6.99998C0.333008 10.68 3.31967 13.6666 6.99967 13.6666C10.6797 13.6666 13.6663 10.68 13.6663 6.99998C13.6663 3.31998 10.6797 0.333313 6.99967 0.333313ZM6.99967 12.3333C4.05967 12.3333 1.66634 9.93998 1.66634 6.99998C1.66634 4.05998 4.05967 1.66665 6.99967 1.66665C9.93967 1.66665 12.333 4.05998 12.333 6.99998C12.333 9.93998 9.93967 12.3333 6.99967 12.3333ZM10.0597 4.05331L5.66634 8.44665L3.93967 6.72665L2.99967 7.66665L5.66634 10.3333L10.9997 4.99998L10.0597 4.05331Z" fill="#42E05C" />
    </svg>


const TimeSVG: FC<SVGProps> = (props) => <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 1H6V2.33333H10V1ZM7.33333 9.66667H8.66667V5.66667H7.33333V9.66667ZM12.6867 5.26L13.6333 4.31333C13.3467 3.97333 13.0333 3.65333 12.6933 3.37333L11.7467 4.32C10.7133 3.49333 9.41333 3 8 3C4.68667 3 2 5.68667 2 9C2 12.3133 4.68 15 8 15C11.32 15 14 12.3133 14 9C14 7.58667 13.5067 6.28667 12.6867 5.26ZM8 13.6667C5.42 13.6667 3.33333 11.58 3.33333 9C3.33333 6.42 5.42 4.33333 8 4.33333C10.58 4.33333 12.6667 6.42 12.6667 9C12.6667 11.58 10.58 13.6667 8 13.6667Z" fill="#FF9950" />
</svg>
const DailyUI = () => {
    const hours = hoursUntilTomorrow()
    return (
        <div className={style.daily}>
            <div className={style.tit}>
                <p>Daily Check in</p>
                <span>
                    <CalendarSVG />
                    Day 10 of 14
                </span>
            </div>
            <div className={style.checkIn}>
                <div className={style.top}>
                    <div className={style.linerStart}></div>
                    <div className={style.one}>
                        <div>
                            <PointSVG />
                            <span>5</span>
                        </div>
                        <span>Day 7</span>
                    </div>
                    <div className={style.liner}>
                        <div className={style.lining}></div>
                    </div>
                    <div className={style.one}>
                        <div>
                            <PointSVG />
                            <span>10</span>
                        </div>
                        <span>Day 14</span>
                    </div>
                    <div className={style.liner}></div>
                    <div className={style.one}>
                        <div>
                            <PointSVG />
                            <span>20</span>
                        </div>
                        <span>Day 21</span>
                    </div>
                </div>
                <div className={style.bottom}>
                    <CheckSVG />
                    Checked-in
                </div>
            </div>
            <div className={style.tit}>
                <p>Daily Quests</p>
                <span>
                    <TimeSVG />
                    {hours} Hours
                </span>
            </div>
            <ul className={style.task}>
                <li>
                    <div className={style.left}>
                        <div className={style.des}>
                            <p>Join 3 draws</p>
                            <span>1/3</span>
                        </div>
                        <div className={style.liner}>
                            <div className={style.lin1}></div>
                        </div>
                    </div>
                    <div className={style.right}>
                        <PointSVG />
                        <span>20</span>
                    </div>
                </li>
                <li>
                    <div className={style.left}>
                        <div className={style.des}>
                            <p>Invite 5 Friends</p>
                            <span>5/5</span>
                        </div>
                        <div className={style.liner}>
                            <div className={style.lin2}></div>
                        </div>
                    </div>
                    <div className={style.right}>
                        <PointSVG />
                        <span>8</span>
                    </div>
                </li>
                <li>
                    <div className={style.left}>
                        <div className={style.des}>
                            <p>Online 20 minutes</p>
                            <span>17/20</span>
                        </div>
                        <div className={style.liner}>
                            <div className={style.lin1} style={{ width: '85%' }}></div>
                        </div>
                    </div>
                    <div className={style.right}>
                        <PointSVG />
                        <span>5</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default DailyUI

