import { IonContent, IonModal } from "@ionic/react";
import { User } from "@supabase/supabase-js";
import { inviteFriendsByUserID } from "api/chat";
import { useLocalStorage, usePreview } from "hooks";
import React, { Dispatch, SetStateAction, useState } from "react";
import { setShowLoading, showToastMessage } from "../../../components/GlobalLoading";
import { useStore } from "../../../store";
import style from "../../../style/Friends.module.css";
import { localGet } from "../../../utils/localStorage";
import { FriendItem } from "./groupModal";
import { isNotNullAndUndefined } from "utils/type";

interface NewGroupProps {
  chooseFriend: FriendItem[];
  handleDeleteFriend: (user_id: string) => void;
  setChooseFriend: Dispatch<SetStateAction<FriendItem[]>>;
}

const NewGroup: React.FC<NewGroupProps> = ({ chooseFriend, handleDeleteFriend, setChooseFriend }) => {
  const { isNewGroupModalOpen, changeModalState } = useStore();
  const [groupName, setGroupName] = useState<any>('');
  const [file, setFile] = useState<any>(null);
  const [userInfo] = useLocalStorage<User | null>('user_info')
  const [avatarUrl, setAvatarUrl] = usePreview()
  const handleChange = (e: any) => {
    e.preventDefault();
    setGroupName(e.target.value);
  }

  const handleChangeFile = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    const imgtype = ['image/jpeg', 'image/png', 'image/jpg']
    if (!imgtype.includes(file?.type)) {
      setShowLoading(false)
      showToastMessage('The type of file uploaded is not an image.')
      return
    }
    if (file?.size > 100000) {
      setShowLoading(false)
      showToastMessage('The uploaded file exceeds the size limit.')
      return
    }
    setFile(file)
    setAvatarUrl(file)
    /*     const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img: any = new Image();
          img.onload = async () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx: any = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height);

            const dataURL = canvas.toDataURL('image/jpeg', 0.1);
            const blob = dataURLtoBlob(dataURL);
            const formData: any = new FormData();
            formData.append('imageURL', blob, 'image.jpeg');
            setImageUrl(dataURL);
          }
          img.src = event.target?.result;
        } */
  }


  const createNewChat = async () => {
    if (chooseFriend.length < 2) {
      return showToastMessage('Please choose at least two friend.')
    }
    if (groupName.length > 20) {
      return showToastMessage('The maximum length of a group chat name is 20 characters.')
    }

    const friendIds = chooseFriend.map(friend => friend.user_id).filter(isNotNullAndUndefined)
    if (groupName) {
      if (friendIds.length > 1) {
        try {
          setShowLoading(true);
          await inviteFriendsByUserID({ friendIds, roomAvatar: file, roomName: groupName })
          showToastMessage('Successfully invited friends.')
          changeModalState('isNewGroupModalOpen', false)
        } catch (error: any) {
          showToastMessage(error.message)
        }
        finally {
          setShowLoading(false)
          changeModalState('isNewGroupModalOpen', false)
          changeModalState('isGroupModalOpen', false)
        }
      } else {
        showToastMessage('Group must have at least 2 members.');
        console.log('Groups must have at least 2 members.;');
      }

    } else {
      showToastMessage('Group name is required.');
      console.log('groupName is empty.');
    }
  }

  const handleCloseModal = () => {
    changeModalState('isNewGroupModalOpen', false)
    setChooseFriend([]);
    setAvatarUrl(null)
  }

  return (
    <IonModal
      isOpen={isNewGroupModalOpen}
      mode="ios"
      onDidDismiss={() => {
        setFile(null);
        setGroupName('')
        setAvatarUrl(null)
      }}
    >
      <IonContent>
        <div className={style.newChatModal}>
          <div className={style.tit}>
            <span
              onClick={handleCloseModal}
              style={{ marginRight: '29.81px' }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
              </svg>
            </span>
            <div>
              <span>New Group</span>
            </div>
            <span onClick={createNewChat}>
              <i style={{
                filter: chooseFriend.length < 2 ? 'grayscale(100%) opacity(0.5)' : 'none',
                backgroundColor: '#9C94FF',
                color: '#000',
                padding: '4px 8px',
                marginTop: '0px',
                fontSize: '12px',
                borderRadius: '4px',
                fontStyle: 'normal'
              }}>Create</i>
            </span>
          </div>
          <div className={style.newGroupInfo}>
            <input
              type="file"
              onChange={handleChangeFile}
              style={{ width: 82, height: 82, borderRadius: '83px', filter: 'Alpha(opacity=0)', position: 'absolute', zIndex: '2', opacity: 0 }}
              accept="image/*"
            />
            {avatarUrl ? <img src={avatarUrl} alt='' className={style.avatarInput} /> : null}
            <div className={style.avatarInput} style={avatarUrl ? { display: "none" } : {}}>
              <svg className={style.avatarInputIcon} xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
                <path d="M24.6665 2.66667H20.4398L17.9998 0H9.99984L7.55984 2.66667H3.33317C1.8665 2.66667 0.666504 3.86667 0.666504 5.33333V21.3333C0.666504 22.8 1.8665 24 3.33317 24H24.6665C26.1332 24 27.3332 22.8 27.3332 21.3333V5.33333C27.3332 3.86667 26.1332 2.66667 24.6665 2.66667ZM24.6665 21.3333H3.33317V5.33333H8.73317L11.1732 2.66667H16.8265L19.2665 5.33333H24.6665V21.3333ZM13.9998 6.66667C10.3198 6.66667 7.33317 9.65333 7.33317 13.3333C7.33317 17.0133 10.3198 20 13.9998 20C17.6798 20 20.6665 17.0133 20.6665 13.3333C20.6665 9.65333 17.6798 6.66667 13.9998 6.66667ZM13.9998 17.3333C11.7998 17.3333 9.99984 15.5333 9.99984 13.3333C9.99984 11.1333 11.7998 9.33333 13.9998 9.33333C16.1998 9.33333 17.9998 11.1333 17.9998 13.3333C17.9998 15.5333 16.1998 17.3333 13.9998 17.3333Z" fill="#A6A9B8" />
              </svg>
            </div>
            <div className={style.newGroupName}>
              <span>Group Name</span>
              <input type="text" value={groupName} onChange={handleChange} />
            </div>
          </div>
          <div className={style.newGroupMember}>
            <p className={style.newGroupMemberTitle}>Members:  {chooseFriend?.length + 1}</p>
            <div className={style.memberAvatar}>
              <div className={style.addMember} onClick={() => { changeModalState('isNewGroupModalOpen', false) }}>
                <svg className={style.addMemberIcon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M19.3332 11.3334H11.3332V19.3334H8.6665V11.3334H0.666504V8.66675H8.6665V0.666748H11.3332V8.66675H19.3332V11.3334Z" fill="white" />
                </svg>
              </div>
              <div className={style.addMy}>
                <img src={localGet('chatProps')[0] || ''} alt="" style={{ width: 60, height: 60, borderRadius: '50%' }} />
                <p style={{ fontSize: '12px', lineHeight: '16px', color: '#fff', marginTop: '6px' }}>{userInfo?.user_metadata?.user_name}</p>
              </div>
              {
                chooseFriend?.map((item) => {
                  return (
                    <div key={item.user_id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '16px', paddingBottom: '10px', position: 'relative' }}>
                      <img src={item.avatar ?? ''} alt="" style={{ width: 60, height: 60, borderRadius: '50%' }} />
                      <p style={{ fontSize: '12px', lineHeight: '16px', color: '#fff', marginTop: '6px' }}>{item.user_name}</p>
                      <div
                        onClick={() => { handleDeleteFriend(item.user_id ?? '') }}
                        style={{ width: 20, height: 20, background: '#434958', borderRadius: '24px', position: 'absolute', left: '44px' }}>
                        <svg style={{ position: "absolute", right: "20.83%", top: "20.83%" }} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                          <path d="M9.66683 1.27325L8.72683 0.333252L5.00016 4.05992L1.2735 0.333252L0.333496 1.27325L4.06016 4.99992L0.333496 8.72659L1.2735 9.66659L5.00016 5.93992L8.72683 9.66659L9.66683 8.72659L5.94016 4.99992L9.66683 1.27325Z" fill="white" />
                        </svg>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
}


export default NewGroup;