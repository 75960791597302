import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const invitesSchema = {
    title: 'invitesSchema',
    description: 'invites',
    version: 1,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        channelId: {
            type: 'string',
        },
        initiator: {
            type: 'object',
        },
        invitees: {
            type: 'object',
        },
        inviteMethod: {
            type: 'string',
        },
        inviteType: {
            type: 'string',
        },
        groupName: {
            type: 'string',
        },
        groupAvatar: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        invitationTime: {
            type: 'string',
            format: 'date-time',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        }
    }
}

// select invites
export const selectInvites = async (pageNumber, pageSize) => {
    const db = await Database.get();

    const invitesData = await db.invites.find({
        selector: {
            // 'invitees._id': localGet('authAddress'),
            $or: [
                { 'initiator._id': localGet('authAddress') },
                { 'invitees._id': localGet('authAddress') },
            ]
        },
        sort: [{ invitationTime: 'desc' }],
    }).skip((pageNumber - 1) * pageSize).limit(pageSize).exec();

    return JSON.parse(JSON.stringify(invitesData));
}

/**
 * select invites count
 * @returns {Promise<number>}
 */
export const selectInvitesCountByinviteesId = async () => {
    const db = await Database.get();

    const invitesData = await db.invites.find({
        selector: {
            'invitees._id': localGet('authAddress'),
            'state': 'pending',
        },
    }).exec();

    return JSON.parse(JSON.stringify(invitesData)).length;
}