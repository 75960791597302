import '@ionic/react/css/ionic-swiper.css';
import { Dispatch, FC, SetStateAction } from "react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import style from '../style/BuyTicketSwiper.module.css';
import { ticketPrize } from "../utils/formatPrize";
import { localGet } from "../utils/localStorage";
// import { getAllImages } from "../db/schema/images";
import { User } from "@supabase/supabase-js";
import { useLocalStorage } from "hooks";
import img1 from "../image/upcoming/1.png";

interface Props {
    list: any
    drawImages: any,
    setSwiperIndex: Dispatch<SetStateAction<number>>
}
const BuyTicketSwiper: FC<Props> = ({ list, drawImages, setSwiperIndex }) => {
    const [userInfo] = useLocalStorage<User | null>('user_info')
    // const [drawImages, setDrawImages] = useState<any>()
    // useEffect(() => {
    //     const setimages = async () => {
    //         setDrawImages(await getAllImages())
    //     }
    //     setimages()
    //     //eslint-disable-next-line
    // }, [])
    const handleSlideChange = (swiper: SwiperClass) => {
        setSwiperIndex(swiper.activeIndex);
    };
    return (
        <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            centeredSlides={true}
            pagination={true}
            modules={[Pagination]}
            className={style.mySwiper}
            onSlideChange={handleSlideChange}
        >
            {
                list.map((item: any, index: any) => (
                    <SwiperSlide key={index} className={style.oneticket}>
                        <div className={style.one}>
                            {
                                localGet('click_Item') &&
                                <img src={localGet('click_Item')?.drawImage === '' ? img1 : `${drawImages?.[localGet('click_Item')?.drawImage] || img1}`} alt="" />
                            }
                            <div className={style.circleline}>
                                <div className={style.margin} style={{ marginLeft: '-16px' }}></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div className={style.margin} style={{ marginRight: '-16px' }}></div>
                            </div>
                            <div className={style.jackpot}>
                                <span>Jackpot</span>
                                <p>${ticketPrize(localGet('click_Item')?.prize)}</p>
                            </div>
                        </div>
                        <div className={style.bottom}>
                            <p className={style.Tick}>Ticket Number <span>#{item}</span></p>
                            <p className={style.Owner}>Owner <span>{userInfo?.user_metadata?.user_name}</span>
                            </p>
                        </div>
                    </SwiperSlide>
                ))}

        </Swiper>
    )
}
export default BuyTicketSwiper