// import axios from 'axios'
import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const drawsSchema = {
    title: 'drawsSchema',
    description: 'Draw info',
    version: 4,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            maxLength: 100,
        },
        drawId: {
            type: 'string',
        },
        drawName: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        contract: {
            type: 'string',
        },
        prize: {
            type: 'number',
            default: 0,
        },
        maxTickets: {
            type: 'number',
            default: 0,
        },
        maxTicketsPerUser: {
            type: 'number',
        },
        ticketsSold: {
            type: 'number',
            default: 0,
        },
        ticketPrice: {
            type: 'number',
            default: 0,
        },
        winnerAvatarURL: {
            type: 'string',
        },
        active: {
            type: 'boolean',
        },
        winnerWallet: {
            type: 'string'
        },
        winnerMembers: {
            type: 'array',
        },
        drawImage: {
            type: 'string',
        },
        isBiggest: {
            type: 'boolean',
        },
        isJoined: {
            type: 'boolean',
        },
        isSplitTicket: {
            type: 'boolean',
        },
        isEjected: {
            type: 'boolean',
        },
        promoted: {
            type: 'boolean',
        },
        videoLinks: {
            type: 'array',
        },
        startTime: {
            type: 'string',
            format: 'date-time',
        },
        endTime: {
            type: 'string',
            format: 'date-time',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        },
        winnerNickname: {
            type: 'string',
        },
        ticketCount: {
            type: 'number',
        },
    },
    required: [
        'id',
        'drawId',
        'drawName',
        'contract',
        'prize',
        'maxTickets',
        'ticketsSold',
        'ticketPrice',
        'winnerAvatarURL',
        'active',
        'winnerWallet',
        'drawImage',
        'isBiggest',
        'isJoined',
        'isEjected',
        'promoted',
        'videoLinks',
        'startTime',
        'endTime',
        'lastUpdateTime'
    ]
};

// Get all draws from localDB
export const filterDraws = async (filter, startTimeSort, prizeSort, pageSize, pageNumber) => {
    const db = await Database.get();

    const query = {
        selector: {},
        sort: [],
    };

    // if there is a sort, add a sort
    if (startTimeSort !== '' && startTimeSort !== undefined && startTimeSort !== null) {
        /* if (filter === 'past') {
            query.sort.push({ endTime: startTimeSort });
        } else {
            query.sort.push({ startTime: startTimeSort });
        } */
        // query.sort.push({ lastUpdateTime: startTimeSort });
        if (filter === 'upcoming') {
            query.sort.push({ startTime: startTimeSort });
        } else {
            query.sort.push({ endTime: startTimeSort });
        }
    }
    if (prizeSort !== '' && prizeSort !== undefined && prizeSort !== null) {
        query.sort.push({ prize: prizeSort });
    }

    switch (filter) {
        case 'upcoming':
            // query.selector.active = true;
            console.log('upcoming');

            // const filterTime = new Date(Date.now() - 60 * 60 * 1000).toISOString();
            const filterTime = new Date(Date.now() - 5 * 1000).toISOString();
            query.selector.$or = [
                { $and: [{ active: true }, { promoted: false }] },
                { $and: [{ winnerWallet: '' }, { promoted: false }] },
                { $and: [{ lastUpdateTime: { $gt: filterTime } }, { promoted: false }] },
                // ticketSold === maxTickets
                // { $and: [{ ticketsSold: { $gt: 0 } }, { ticketsSold: { $eq: { $maxTickets: '$maxTickets' } } }] },
            ]

            // const nowMinus15Seconds = new Date(Date.now() - 1 * 60 * 1000).toISOString();
            // query.selector.$or = [
            //     { active: true },
            //     // { endTime: { $gt: nowMinus15Seconds } }
            //     // { $and: [{ isEjected: false }, { endTime: { $gt: nowMinus15Seconds } }] }
            // ]

            /* query.selector.$or = [
                { active: true },
                { $and: [{ isJoined: true }, { isEjected: false }] }
            ]; */
            break;
        case 'joined':
            console.log('joined');
            query.selector.isJoined = true;
            break;
        case 'past':
            console.log('past');
            query.selector.active = false;
            // winnerWallet is not empty
            query.selector.winnerWallet = { $ne: '' };
            break;
        default:
            console.log('default');
            query.selector.active = true;
            query.sort.push({ prize: 'desc' });
    }

    console.log('query', query);
    const draws = await db.draw.find(query).skip((pageNumber - 1) * pageSize).limit(pageSize).exec();
    // let drawsJson = JSON.parse(JSON.stringify(draws));
    // console.log(drawsJson);
    return draws;
};

// filter draws is promoted
export const filterPromotedDraws = async () => {
    const db = await Database.get();
    const draw = db.draw.findOne({
        // selector: { promoted: true, active: true },
        selector: { promoted: true },
        // sort: [{ lastUpdateTime: 'desc' }],
    }).exec();
    return draw;
}

export const clearDrawsDB = async () => {
    let databaseName = `rxdb-dexie-mydatabase--${drawsSchema.version}--draw`;
    var request = window.indexedDB.open(databaseName);

    request.onsuccess = function (event) {
        var db = event.target.result;

        var objectStore = db.transaction(["docs"], "readwrite").objectStore("docs");
        var request = objectStore.clear();

        request.onsuccess = function (event) {
            console.log("The storage object has been cleared");
        };

        request.onerror = function (event) {
            console.log("An error occurred while clearing the storage object");
        };
    };

    request.onerror = function (event) {
        console.log("An error occurred while opening the database");
    };
}

export const LogoutDrawsDBAction = async (onCompletion) => {
    // find all data where isJoined is true, set isJoined to false
    const db = await Database.get();
    const draw = db.draw.find({
        selector: { isJoined: true },
    }).exec();
    draw.then(async (draws) => {
        const jsonDraws = JSON.parse(JSON.stringify(draws));
        if (jsonDraws.length === 0) {
            deletedAuthAddressCheckpoint()
            return;
        };
        const updateDraws = jsonDraws.map((item) => ({ ...item, isJoined: false, isEjected: false }))
        await db.draw.bulkUpsert(updateDraws).then(() => {
            deletedAuthAddressCheckpoint()
        });
    });

    // delete the data in the ticket table
    async function deletedAuthAddressCheckpoint() {
        await db.checkpoint.findOne(localGet('authAddress')).exec().then(async (checkpointDoc) => {
            await checkpointDoc.remove().then(() => {
                onCompletion(true);
            });
        });
    }
}

// Get all won pending draws count
export const getWonPendingDrawsCount = async () => {
    let counts = {
        all: 0,
        won: 0,
        pending: 0,
    }

    const db = await Database.get();
    const draws = await db.draw.find({
        selector: {
            isJoined: true,
        },
        sort: [{ lastUpdateTime: 'desc' }],
    }).exec();

    const won = await db.tickets.find({
        selector: {
            owner: localGet('authAddress'),
            isWinner: true,
        },
    }).exec()
    const wonlist = won.filter((currentItem, index, array) => {
        if (index === array.length - 1) {
            return true;
        }

        return currentItem.draw !== array[index + 1].draw;
    });
    const jsonDraws = JSON.parse(JSON.stringify(draws));
    counts.all = jsonDraws.length;
    counts.won = wonlist.length;
    counts.pending = jsonDraws.filter((item) => item.winnerWallet === '').length;

    return counts;
}

// Get draw record
export const getDrawRecord = async (selector, pageSize, pageNumber) => {
    const db = await Database.get();
    const draws = await db.draw.find({
        selector: selector,
        sort: [{ endTime: 'desc' }],
    }).skip((pageNumber - 1) * pageSize).limit(pageSize).exec();
    return draws;
};

// query draws where endTime is in 60 minutes
export const getDrawsInOneHour = async () => {
    const db = await Database.get();
    const now = new Date();
    const oneHourLater = new Date(now.getTime() - 60 * 60 * 1000);
    const draws = await db.draw.find({
        selector: {
            endTime: {
                $gt: oneHourLater.toISOString(),
            }
        },
        sort: [{ endTime: 'desc' }],
    }).exec();
    return draws;
}

// get max prize draw
export const getMaxPrizeDraw = async () => {
    const db = await Database.get();
    const now = new Date();
    let sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    let draws;

    // Repeat until we find data
    while (true) {
        draws = await db.draw.find({
            selector: {
                endTime: {
                    $gt: sevenDaysAgo.toISOString(),
                },
                prize: {
                    $gt: 1000000,
                }
            },
            sort: [{ prize: 'desc' }, { endTime: 'desc' }],
        }).exec();

        // If we found data, break the loop
        if (draws && draws.length > 0) {
            break;
        }

        // If no data, go back another 7 days
        sevenDaysAgo = new Date(sevenDaysAgo.getTime() - 7 * 24 * 60 * 60 * 1000);
    }

    return JSON.parse(JSON.stringify(draws));
};

export const getDrawsByDrawId = async (drawId) => {
    const db = await Database.get();
    const draws = await db.draw.findOne({
        selector: {
            drawId: drawId,
        },
    }).exec();
    return JSON.parse(JSON.stringify(draws));
}

export const setJoinedDraw = async (drawId) => {
    const db = await Database.get();
    await db.draw.findOne({
        selector: { drawId: drawId }
    }).exec().then(async (doc) => {
        await doc.patch({ isJoined: true });
    });
}