import { ChannelList, Chatroom, useChatroom, useSupabase } from '@DataAssetStore/chatcomponent'
import { useSetState } from 'ahooks'
import { useEffect } from 'react'
import { useStore } from 'store'
import back from "../../../image/upcoming/ill.png"
import errorstyle from "../../../style/Upcoming.module.css"
import BlockModal from '../block-modal'
import ChatModal from './chat-modal'


type ModalType = 'BlockModal' | 'PrivateChatModal' | 'UserInfoModal' | 'GroupChatModal'
const RecentContact = () => {
    const { setCurrentChatroom, chatroomList } = useChatroom()
    const { isChatModalOpen, changeModalState } = useStore()
    const { getChatroom } = useSupabase()
    const [modalState, setModalState] = useSetState<Record<ModalType, boolean>>({ BlockModal: false, PrivateChatModal: false, UserInfoModal: false, GroupChatModal: false })
    useEffect(() => {
        getChatroom()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onOpenChatModal = (room_info: Chatroom) => {
        changeModalState(room_info.isGroup ? 'isChatModalOpen' : 'isChatModalOpen', true)
        // setCurrentRoomInfo(room_info)
        setCurrentChatroom(room_info)

    }
    useEffect(() => {
        console.log(chatroomList)
    },[chatroomList])
    const onCloseChatModal = (isGroup: boolean) => {
        changeModalState(isGroup ? 'isChatModalOpen' : 'isChatModalOpen', false)
        // setCurrentRoomInfo(null)
    }
    return (
        <section className="channels">
            {chatroomList.length === 0 && <NoRecentContacts />}
            <ChannelList isUseSwipe={true} onItemClick={onOpenChatModal}></ChannelList>
            <BlockModal isOpen={modalState.BlockModal} closeModal={() => setModalState({ BlockModal: false })} />
            <ChatModal isOpen={isChatModalOpen} closeModal={onCloseChatModal} />
        </section>
    )
}

export default RecentContact

const NoRecentContacts = () => (
    <div className={errorstyle.cartex}>
        <img src={back} alt="" />
        <p style={{ width: '100%', textAlign: 'center' }}>Oh, you haven't added any friends yet.</p>
    </div>
)