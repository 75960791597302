
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import '@ionic/react/css/ionic-swiper.css';
import "swiper/css";
import "swiper/css/pagination";
// import style from '../style/myticketSwiper.module.css'
import style from '../style/BuyTicketSwiper.module.css';

import { useEffect, useState } from "react";
import { Pagination } from "swiper";
// import { addCommas } from "../utils/addCommas";
// import { formatDate } from "../utils/formatDate";
import { ticketPrize } from "../utils/formatPrize";
import { localGet } from "../utils/localStorage";
// import circlesline from '../image/component/bg-line.png';
import { User } from "@supabase/supabase-js";
import { useLocalStorage } from "hooks";
import { InviterState } from "typings/ticket";
import { getAllImages } from "../db/schema/images";
import { selectTicketsByOwner } from "../db/schema/tickets";
import img1 from "../image/upcoming/1.png";

const MyticketSwiper = () => {
    const [userInfo] = useLocalStorage<User | null>('user_info')
    const [drawImages, setDrawImages] = useState<any>()
    useEffect(() => {
        const setimages = async () => {
            setDrawImages(await getAllImages())
        }
        setimages()
        //eslint-disable-next-line
    }, [localGet('images')])
    const [page, setPage] = useState(1);
    const [tickets, setTickets] = useState(Array);

    useEffect(() => {
        selectTicketsByOwner(5, page, {
            owner: localGet('authAddress'),
            state: 'activate',
        }).then(newTickets => {
            setTickets([...tickets, ...newTickets]);

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleSlideChange = (swiper: any) => {
        if (swiper.isEnd) {
            setPage(page + 1);
        }
    };

    return (
        <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            centeredSlides={true}
            pagination={true}
            modules={[Pagination]}
            onSlideChange={handleSlideChange}
            className={style.mySwiper}
        >
            {
                tickets.map((item: any, index: any) => {
                    if (item?.ticketShare !== 10000) {
                        let showImgs: any = []
                        const members = item.invitees ?? []
                        members?.map((i: any) => {
                            if (i?._id === userInfo?.user_metadata?.wallet_address) {
                                showImgs.unshift(i)
                            } else {
                                showImgs.push(i)
                            }
                            return i
                        })
                        const _friends = showImgs.filter((_: any) => _.state === InviterState.Activate).slice(0, 2)
                        const friends = [item.organizer, ..._friends]
                        return (
                            <SwiperSlide key={index} className={style.oneticket}>
                                <div className={style.splitClass}>
                                    <div className={style.progress}>
                                        <p>Split Ticket ({item.ticketPercentage})</p>
                                    </div>
                                    <div className={style.one}>
                                        <img src={item.drawData.drawImage === '' ? img1 : `${drawImages?.[item.drawData.drawImage] || img1}`} alt="" />
                                        <div className={style.circleline}>
                                            <div className={style.margin} style={{ marginLeft: '-16px' }}></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div className={style.margin} style={{ marginRight: '-16px' }}></div>
                                        </div>
                                        <div className={style.jackpot}>
                                            <span>Jackpot</span>
                                            <p>${ticketPrize(item.drawData.prize)}</p>
                                        </div>
                                    </div>
                                    <div className={style.bottom}>
                                        <p className={style.Tick}>Ticket Number <span>#{item.ticketNumber}</span></p>
                                        <div className={style.ticket_share}>
                                            <ul>
                                                {friends.map((friend: any) => (
                                                    <li key={friend._id}>
                                                        <img src={friend.avatarURL} alt={friend.nickname} />
                                                    </li>
                                                ))}
                                            </ul>
                                            <p className={style.ticket_share_txt}>share with <span>{friends.map((_: any) => _.nickname).join(' & ')} </span>{friends.length > 3 ? '...' : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    } else {
                        return (
                            <SwiperSlide key={index} className={style.oneticket}>
                                <p style={{ height: '28px' }}></p>
                                <div className={style.one}>
                                    <img src={item.drawData.drawImage === '' ? img1 : `${drawImages?.[item.drawData.drawImage] || img1}`} alt="" />
                                    <div className={style.circleline}>
                                        <div className={style.margin} style={{ marginLeft: '-16px' }}></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div className={style.margin} style={{ marginRight: '-16px' }}></div>
                                    </div>
                                    <div className={style.jackpot}>
                                        <span>Jackpot</span>
                                        <p>${ticketPrize(item.drawData.prize)}</p>
                                    </div>
                                </div>
                                <div className={style.bottom}>
                                    <p className={style.Tick}>Ticket Number <span>#{item.ticketNumber}</span></p>
                                    <p className={style.Owner}>Owner <span>{userInfo?.user_metadata?.user_name}</span>
                                    </p>
                                </div>
                            </SwiperSlide>
                        )
                    }


                })}
        </Swiper>
    )
}
export default MyticketSwiper