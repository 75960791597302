import { useEffect, useState } from 'react';
import { localGet, localSet } from 'utils/localStorage';

const useLocalStorage = <T>(
  key: string,
  initialValue?: T
): [T, (newValue: T) => void] => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localGet(key);
    return storedValue !== null ? storedValue : initialValue;
  });

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      console.log(event.key);
      if (event.key === key) {
        const newValue = event.newValue !== null ? localGet(key) : null;
        setValue(newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  const setStoredValue = (newValue: T) => {
    setValue(newValue);
    localSet(key, newValue);
  };

  return [value, setStoredValue];
};

export default useLocalStorage;
