import * as Database from "../";

export const channelsSchema = {
    title: 'channelsSchema',
    description: 'channels',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        name: {
            type: 'string',
        },
        lang: {
            type: 'string',
        },
        isPublic: {
            type: 'boolean',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        },
    }
};

// select channels
export const selectChannels = async () => {
    const db = await Database.get();

    let channelsJson;
    await db.channels.find().exec().then((channels) => {
        channelsJson = JSON.parse(JSON.stringify(channels));
    });

    return channelsJson;
}

// select channel by lang
export const selectChannelByLang = async (lang) => {
    const db = await Database.get();
    let lange = lang.includes('-') ? lang.substring(0, lang.indexOf('-')) : lang
    // console.log('langelangelange',lange)
    const regex = new RegExp(lange, 'i');

    let channelsJson;
    await db.channels.find().where('lang').regex(regex).exec().then((channels) => {
        channelsJson = JSON.parse(JSON.stringify(channels));
    });

    return channelsJson;
}