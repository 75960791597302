import { useEffect } from "react"
import { useHistory } from "react-router"

const NotExist = () => {
    const history = useHistory()
    useEffect(() => {
        history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <></>
    )
}
export default NotExist