import * as Database from '../';

export const imagesSchema = {
    title: 'imagesSchema',
    description: 'images',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        image: {
            type: 'string',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        },
    },
    required: ['image', 'lastUpdateTime']
}

// get all images
export const getAllImages = async () => {
    const db = await Database.get();
    const arr = await db.images.find().exec();
    const result = arr.reduce((acc, obj) => {
        acc[obj._id] = obj.image;
        return acc;
    }, {});
    return result;
}
