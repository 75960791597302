
import { IonModal, IonContent } from "@ionic/react";
import mixstyle from "../../../style/Topdata.module.css";
import { useStore } from "../../../store";
import SVGIcon from "components/SvgComponent";


const TakeASelfieModal: React.FC = () => {
    const { isTakeASelfieModalOpen,changeModalState } = useStore();
    return (
        <IonModal isOpen={isTakeASelfieModalOpen}
            mode="ios" onDidDismiss={() => changeModalState('isTakeASelfieModalOpen', false)}>
            <IonContent>
                <div className={mixstyle.driverLicenseModal}>
                    <div className={mixstyle.tit} onClick={() => changeModalState('isTakeASelfieModalOpen', false)}>
                        <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                        Video Selfie
                    </div>
                    <div className={mixstyle.logo}>
                        <SVGIcon name="TakeASelfieIcon" width="64" height="64" viewBox="0 0 64 64" fill="none"/>
                        <p>Position yourself in the centre of the camera  and then move your face left and right to show both sides.</p>
                    </div>
                    <div className={mixstyle.act}>
                        <div className={mixstyle.use}>
                            Get Started
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default TakeASelfieModal