import { create } from 'zustand';
import { ChatRoomState, createChatRoomSlice } from './chatRoomSlice';
import { GlobalState, createGlobalSlice } from './globalSlice';
import { ModalState, createModalSlice } from './modalSlice';
import { MyUserInterface,createUserInfoSlice } from './userInfoSlice'
interface StoreState extends GlobalState, ModalState, ChatRoomState , MyUserInterface {}
export const useStore = create<StoreState>((...state) => ({
  ...createGlobalSlice(...state),
  ...createModalSlice(...state),
  ...createChatRoomSlice(...state),
  ...createUserInfoSlice(...state)
}));
