import { IonCheckbox, IonContent, IonHeader, IonModal, IonRefresher, IonRefresherContent, IonSearchbar, RefresherEventDetail } from "@ionic/react";
import { tokenApi } from "api";
import { getAllFriends } from "api/chat";
import { FriendItem } from "pages/friends/components/groupModal";
import { Dispatch, FC, SetStateAction, memo, useState } from "react";
import { ticketPrize } from "utils/formatPrize";
import back from "../image/friends/Group.png";
import { useStore } from "../store";
import style from '../style/SplitTicket.module.css';
import { setShowLoading, showToastMessage } from "./GlobalLoading";
import Button from "./common/Button";
import { isNotNullAndUndefined } from "utils/type";
interface SplitTicketProps {
    ticketNumber: number
    drawImage: string
    drawId: number
    prize: number
}
enum RequestStatus {
    Init,
    RequestError,
    Null,
    Success
}
const confirmButtonStyle: React.CSSProperties = {
    width: '68px',
    backgroundColor: '#9C94FF',
    color: '#000',
    height: '30px',
    marginTop: '0px',
    fontSize: '12px',
    borderRadius: '4px'
}
const SplitTicket: FC<SplitTicketProps> = memo(({ ticketNumber, drawImage, drawId, prize }) => {
    // const { user } = useAuth0()
    // const client = useRef() as any
    // const [isLoading, setIsLoading] = useState<boolean>(false)
    const [friends, setFriends] = useState<FriendItem[]>([])
    const [chooseFriend, setChooseFriend] = useState<FriendItem[]>([])
    const { changeModalState, isSplitTicketModalOpen, isSplitTicketFriendsModalOpen } = useStore();
    const [status, setState] = useState<RequestStatus>(RequestStatus.Init)
    const onCloseModal = () => {
        changeModalState('isSplitTicketModalOpen', false)
    }
    const onOpenShare = () => {
        changeModalState('isSplitTicketShareModalOpen', true)
    }
    const onCloseShare = () => {
        changeModalState('isSplitTicketShareModalOpen', false)
    }
    /*     const getFriends = useCallback(async () => {
            let localClient
            if (!client.current) {
                localClient = await myClient(user?.picture, localStorage.getItem('chatString'))
                client.current = localClient
            }
            const privateChannel: any = await (client.current ?? localClient).channel('team', `hide-${userInfo?.user_metadata?.wallet_address}`);
            const queryMember: any = await privateChannel.queryMembers({ id: { "$nin": [userInfo?.user_metadata?.wallet_address] } })
            const members: FriendItem[] = queryMember?.members
            members.forEach(_ => _.show = true)
            return members
        }, [user?.picture]) */
    const getFriendList = async () => {
        setShowLoading(true)
        setState(RequestStatus.Init)
        try {
            const _friends = await getAllFriends()
            if (_friends.length > 0) {
                setFriends(_friends.map(_ => ({ ..._, show: true })))
                setState(RequestStatus.Success)
            } else {
                setState(RequestStatus.Null)
            }
        } catch (error) {
            setState(RequestStatus.RequestError)
        }
        finally {
            setShowLoading(false)
        }
    }
    const resetSplitTicket = () => {
        setFriends([])
        setChooseFriend([])
        changeModalState('isSplitTicketFriendsModalOpen', false)
    }
    const onHandleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
        setState(RequestStatus.Init)
        /*         try {
                    const _friends = await getFriends()
                    if (_friends.length > 0) {
                        setFriends(_friends)
                        setState(RequestStatus.Success)
                    } else {
                        setState(RequestStatus.Null)
                    }
                } catch (err) {
                    setState(RequestStatus.RequestError)
                }
                finally {
                    event.detail.complete()
                } */

    }
    const selectFriendsProps = {
        status,
        onOpenShare,
        setFriends,
        chooseFriend,
        setChooseFriend,
        friends,
    };
    const referProps = {
        getFriendList,
    };
    const shareProps = {
        prize,
        drawId,
        chooseFriend,
        close: onCloseShare,
        drawImages: drawImage,
        ticketNumber,
        friend: friends,
    };
    return (
        <IonModal onDidDismiss={resetSplitTicket} isOpen={isSplitTicketModalOpen}>
            <IonHeader className={style.header_wrapper}>
                <div className={style.header}>
                    <CloseSVG onClick={onCloseModal} />
                    <div className={style.title}>
                        <h1>
                            Split Ticket
                        </h1>
                        {isSplitTicketFriendsModalOpen ? <span>{chooseFriend.length}/{friends.length}</span> : ''}
                    </div>
                    {isSplitTicketFriendsModalOpen && chooseFriend.length > 0 && <Button onClick={onOpenShare} style={confirmButtonStyle}>Confirm</Button>}
                    {!isSplitTicketFriendsModalOpen && <span>Learn More</span>}
                </div>

            </IonHeader>
            <IonContent className="ion-padding">
                <IonRefresher slot="fixed" onIonRefresh={onHandleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {isSplitTicketFriendsModalOpen ? <SelectFriends {...selectFriendsProps} /> : <Refer {...referProps} />}
            </IonContent>
            <Share {...shareProps} />
            {/* <IonLoading
                cssClass="my-custom-class"
                isOpen={isLoading}
                message={'Please wait...'}
                duration={10000000}
                mode="ios"
                spinner="crescent"
            /> */}
        </IonModal>
    )
})

export default SplitTicket



interface SelectFriendsProps {
    status: RequestStatus
    friends: FriendItem[]
    chooseFriend: FriendItem[],
    setChooseFriend: Dispatch<SetStateAction<FriendItem[]>>
    setFriends: Dispatch<SetStateAction<FriendItem[]>>
}
const SelectFriends: FC<SelectFriendsProps> = memo(({ status, friends, chooseFriend, setChooseFriend, setFriends }) => {

    const onRemoveFriends = (id: string) => {
        return () => setChooseFriend(prev => prev.filter(_ => _.user_id !== id))
    }
    const onSearchFriends = (event: CustomEvent) => {
        const inputValue = (event.target as HTMLInputElement).value
        const _friends = friends.map(friend => {
            if (!friend.user_name) return null
            if (friend.user_name.includes(inputValue)) {
                friend.show = true
            } else {
                friend.show = false
            }
            return friend
        }).filter(isNotNullAndUndefined)
        setFriends(_friends)
    }
    return (

        <div className={style.select_friends}>
            <IonSearchbar className={style.search_bar} onIonInput={onSearchFriends}></IonSearchbar>
            <div className={style.selected_wrapper}>
                {chooseFriend.length > 0 ?
                    <ul>
                        {chooseFriend.map((friend) => (
                            <li key={friend.user_id}>
                                <div>
                                    {friend.avatar && friend.user_name && <img src={friend.avatar} alt={friend.user_name} />}
                                    {friend.user_id && <CloseSelectFriendsSVG onClick={onRemoveFriends(friend.user_id)} />}
                                </div>
                                <span>{friend.user_name}</span>
                            </li>
                        ))
                        }
                    </ul> : ''}
            </div>
            <div className={style.select_list}>
                <Friend chooseFriend={chooseFriend} setChooseFriend={setChooseFriend} status={status} friends={friends} />
            </div>
        </div>
    )
})
const List = memo(({ friends, chooseFriend, setChooseFriend }: { friends: FriendItem[], chooseFriend: FriendItem[], setChooseFriend: Dispatch<SetStateAction<FriendItem[]>> }) => {
    console.log('friends list refresh')
    const onSelectFriends = (user: FriendItem) => {
        return () => {
            const chooseIndex = chooseFriend.findIndex((item) => item.user_id === user.user_id);
            if (chooseIndex < 0) {
                setChooseFriend(prev => [...prev, user]);
            } else {
                setChooseFriend((prev) => {
                    return prev.filter((item) => item.user_id !== user.user_id);
                });
            }
        }
    }
    return (
        <ul>
            {friends.map((item) => {
                return (
                    item.show ? <li onClick={onSelectFriends(item)} key={item.user_id}>
                        <IonCheckbox
                            className={style.checkbox}
                            value={item.user_id}
                            checked={chooseFriend?.some((_) => _.user_id === item.user_id)}
                        ></IonCheckbox>
                        {item.avatar && item.user_name && <img src={item.avatar} alt={item.user_name} />}
                        <div className={style.select_txt}>
                            <p>{item.user_name}</p>
                        </div>
                    </li> : ''
                )
            })}
        </ul>
    )
})
const Friend = memo(({ status, friends, chooseFriend, setChooseFriend }: { status: RequestStatus, friends: FriendItem[], chooseFriend: FriendItem[], setChooseFriend: Dispatch<SetStateAction<FriendItem[]>> }) => {
    switch (status) {
        case RequestStatus.Null:
            return <NoFriends />
        case RequestStatus.RequestError:
            return <UnknownError />
        default:
            return <List chooseFriend={chooseFriend} setChooseFriend={setChooseFriend} friends={friends} />
    }
})
const NoFriends = () => (<div className={style.no_friends}>
    <img src={back} alt="No friends found" />
    <p >No friends found</p>
</div>)
const UnknownError = () => (<div className={style.no_friends}>
    <img src={back} alt="No friends found" />
    <p >There seems to be a problem with the network. Please scroll down and refresh.</p>
</div>)


interface ReferProps {
    getFriendList: () => void
}
const Refer: FC<ReferProps> = ({ getFriendList }) => {
    const { changeModalState } = useStore()
    const onClickNext = () => {
        getFriendList()
        requestAnimationFrame(() => {
            // setIsSelectFriendsOpen(true)
            changeModalState('isSplitTicketFriendsModalOpen', true)
        })
    }
    return (
        <div className={style.refer_wrapper}>
            <div className={style.refer}>
                <ReferSVG />
                <p>Split your ticket and invite your friends to get part of it to share the wins.</p>
            </div>
            <div className={style.bottom_wrapper}>
                <Button onClick={onClickNext} style={{ backgroundColor: '#9C94FF', color: "#000" }}>
                    Next
                </Button>
            </div>
        </div>
    )

}

interface ShareProps {
    drawImages: string
    ticketNumber: number
    chooseFriend: FriendItem[],
    friend: FriendItem[],
    drawId: number,
    prize: number,
}
const Share: FC<ShareProps> = memo(({ drawImages, drawId, ticketNumber, chooseFriend = [], friend = [], prize }) => {
    const MAX_FRIENDS_LENGTH = 3
    const { isSplitTicketShareModalOpen, changeModalState, closeAllModalStates } = useStore()
    const isOver = chooseFriend.length > MAX_FRIENDS_LENGTH
    const friends = chooseFriend.slice(0, 3)
    const onTicketInvite = async () => {
        setShowLoading(true)
        try {
            const { code, detail } = await tokenApi('/splitTicket', {
                drawId,
                ticketNumber,
                participants: chooseFriend.map(_ => _.user_id)
            })
            if (code === 200) {
                showToastMessage('Sent successfully.')
            } else {
                showToastMessage(detail ?? 'Unknown Error.')
            }
            closeAllModalStates(false)

        } catch (error: any) {
            showToastMessage(error.message)
        }
        finally {
            setShowLoading(false)
        }
    }
    const onCloseShare = () => {
        changeModalState('isSplitTicketShareModalOpen', false)
    }
    return (
        <IonModal isOpen={isSplitTicketShareModalOpen}>
            <IonHeader>
                <div className={style.share_header}>
                    <CloseSVG onClick={onCloseShare} />
                </div>
            </IonHeader>
            <IonContent className='ion-padding'>
                <div className={style.share_container}>
                    <div className={style.share}>
                        <div className={style.share_background}>
                            <div className={style.progress}>
                                <p>Split Ticket</p>
                            </div>
                            <div className={style.share_wrapper}>
                                <img src={drawImages} alt={ticketNumber + ''} />
                                <div className={style.circle_line}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className={style.jackpot}>
                                    <span>Jackpot</span>
                                    <p>${ticketPrize(prize)}</p>
                                </div>
                            </div>
                            <div className={style.ticket_bottom}>
                                <p className={style.ticket_number}>Ticket Number <span>#{ticketNumber}</span></p>
                                <div className={style.ticket_share}>
                                    <ul>
                                        {friends.map(friend => (
                                            <li key={friend.user_id}>
                                                {friend.avatar && friend.user_name && <img src={friend.avatar} alt={friend.user_name} />}
                                            </li>
                                        ))}
                                    </ul>
                                    <p className={style.ticket_share_txt}>share with <span>{friends.map(_ => _.user_name).join(' & ')}</span>{isOver && '...'}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={style.share_bottom}>
                        <Button onClick={onTicketInvite}>
                            Split Ticket Invite ({chooseFriend.length}/{chooseFriend.length})
                        </Button>
                    </div>
                </div>

            </IonContent>
        </IonModal>

    )
})
// SVG

type SVGProps = React.SVGProps<SVGSVGElement>;
const CloseSVG: FC<SVGProps> = (props) => (
    <svg  {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
    </svg>
)
const ReferSVG: FC<SVGProps> = (props) => (
    <svg {...props} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1446_17653)">
            <path d="M14.7302 33.3966C11.8594 33.3966 9.52381 31.061 9.52381 28.1902C9.52381 25.3195 11.8594 22.9839 14.7302 22.9839C17.6009 22.9839 19.9365 25.3195 19.9365 28.1902C19.9365 31.061 17.6009 33.3966 14.7302 33.3966ZM14.7302 25.7775C13.3997 25.7775 12.3175 26.8598 12.3175 28.1902C12.3175 29.5206 13.3997 30.6029 14.7302 30.6029C16.0606 30.6029 17.1429 29.5206 17.1429 28.1902C17.1429 26.8598 16.0606 25.7775 14.7302 25.7775Z" fill="#9C94FF" />
            <path d="M22.3492 42.4355C21.5778 42.4355 20.9524 41.8101 20.9524 41.0386V39.6189C20.9524 38.2885 19.8701 37.2062 18.5397 37.2062H10.9206C9.59022 37.2062 8.50794 38.2885 8.50794 39.6189V41.0386C8.50794 41.8101 7.88254 42.4355 7.11111 42.4355C6.33968 42.4355 5.71429 41.8101 5.71429 41.0386V39.6189C5.71429 36.7482 8.04991 34.4126 10.9206 34.4126H18.5397C21.4104 34.4126 23.746 36.7482 23.746 39.6189V41.0386C23.746 41.8101 23.1206 42.4355 22.3492 42.4355Z" fill="#9C94FF" />
            <path d="M49.2698 18.0318C46.3991 18.0318 44.0635 15.6962 44.0635 12.8255C44.0635 9.95476 46.3991 7.61914 49.2698 7.61914C52.1406 7.61914 54.4762 9.95476 54.4762 12.8255C54.4762 15.6962 52.1406 18.0318 49.2698 18.0318ZM49.2698 10.4128C47.9394 10.4128 46.8571 11.4951 46.8571 12.8255C46.8571 14.1559 47.9394 15.2382 49.2698 15.2382C50.6003 15.2382 51.6825 14.1559 51.6825 12.8255C51.6825 11.4951 50.6003 10.4128 49.2698 10.4128Z" fill="#9C94FF" />
            <path d="M56.8889 27.0707C56.1175 27.0707 55.4921 26.4453 55.4921 25.6739V24.2542C55.4921 22.9238 54.4098 21.8415 53.0794 21.8415H45.4603C44.1299 21.8415 43.0476 22.9238 43.0476 24.2542V25.6739C43.0476 26.4453 42.4222 27.0707 41.6508 27.0707C40.8794 27.0707 40.254 26.4453 40.254 25.6739V24.2542C40.254 21.3835 42.5896 19.0479 45.4603 19.0479H53.0794C55.9501 19.0479 58.2857 21.3835 58.2857 24.2542V25.6739C58.2857 26.4453 57.6603 27.0707 56.8889 27.0707Z" fill="#9C94FF" />
            <path d="M62.6032 3.80952H60.1905V1.39683C60.1905 0.625397 59.5651 0 58.7937 0C58.0222 0 57.3968 0.625397 57.3968 1.39683V3.80952H54.9841C54.2127 3.80952 53.5873 4.43492 53.5873 5.20635C53.5873 5.97778 54.2127 6.60318 54.9841 6.60318H57.3968V9.01587C57.3968 9.7873 58.0222 10.4127 58.7937 10.4127C59.5651 10.4127 60.1905 9.7873 60.1905 9.01587V6.60318H62.6032C63.3746 6.60318 64 5.97778 64 5.20635C64 4.43492 63.3746 3.80952 62.6032 3.80952Z" fill="#CCCCCC" />
            <path d="M49.2698 52.5714C46.3991 52.5714 44.0635 50.2358 44.0635 47.365C44.0635 44.4943 46.3991 42.1587 49.2698 42.1587C52.1406 42.1587 54.4762 44.4943 54.4762 47.365C54.4762 50.2358 52.1406 52.5714 49.2698 52.5714ZM49.2698 44.9523C47.9394 44.9523 46.8571 46.0346 46.8571 47.365C46.8571 48.6955 47.9394 49.7777 49.2698 49.7777C50.6003 49.7777 51.6825 48.6955 51.6825 47.365C51.6825 46.0346 50.6003 44.9523 49.2698 44.9523Z" fill="#9C94FF" />
            <path d="M56.8889 61.6098C56.1175 61.6098 55.4921 60.9844 55.4921 60.2129V58.7933C55.4921 57.4629 54.4098 56.3806 53.0794 56.3806H45.4603C44.1299 56.3806 43.0476 57.4629 43.0476 58.7933V60.2129C43.0476 60.9844 42.4222 61.6098 41.6508 61.6098C40.8794 61.6098 40.254 60.9844 40.254 60.2129V58.7933C40.254 55.9225 42.5896 53.5869 45.4603 53.5869H53.0794C55.9501 53.5869 58.2857 55.9225 58.2857 58.7933V60.2129C58.2857 60.9844 57.6603 61.6098 56.8889 61.6098Z" fill="#9C94FF" />
            <path d="M62.666 48.7717C61.8952 48.7199 61.2309 49.3033 61.1798 50.073C60.7657 56.3158 55.5342 61.2061 49.2698 61.2061C42.688 61.2061 37.3333 55.8514 37.3333 49.2696C37.3333 43.0052 42.2236 37.7737 48.4664 37.3596C49.2362 37.3086 49.8188 36.6432 49.7677 35.8734C49.7167 35.1038 49.0513 34.5205 48.2815 34.5721C43.6456 34.8796 39.6147 37.3394 37.1383 40.9333L28.2263 35.9934C29.0192 34.1864 29.4603 32.1914 29.4603 30.095C29.4603 28.4593 29.192 26.8853 28.6976 25.4141L36.4413 21.9618C38.9726 26.4344 43.7742 29.4601 49.2698 29.4601C57.0003 29.4601 63.4561 23.4239 63.9674 15.7182C64.0184 14.9485 63.4358 14.2831 62.666 14.232C61.8952 14.1807 61.2309 14.7636 61.1798 15.5333C60.7657 21.7761 55.5342 26.6664 49.2698 26.6664C42.688 26.6664 37.3333 21.3118 37.3333 14.7299C37.3333 8.46567 42.2236 3.23417 48.4664 2.81995C49.2362 2.7689 49.8188 2.10351 49.7677 1.33373C49.7167 0.563952 49.0513 -0.0185239 48.2815 0.0323967C40.5759 0.543635 34.5397 6.99951 34.5397 14.7299C34.5397 16.3656 34.808 17.9396 35.3023 19.4108L27.5587 22.8631C25.0274 18.3905 20.2258 15.3648 14.7302 15.3648C6.608 15.3648 0 21.9727 0 30.095C0 38.2173 6.608 44.8252 14.7302 44.8252C19.7592 44.8252 24.2071 42.2912 26.8659 38.4335L35.7771 43.3731C34.9815 45.1841 34.5397 47.1801 34.5397 49.2696C34.5397 57.3919 41.1477 63.9998 49.2698 63.9998C57.0003 63.9998 63.4561 57.9636 63.9674 50.2579C64.0184 49.4881 63.4358 48.8227 62.666 48.7717ZM14.7302 42.0315C8.14832 42.0315 2.79365 36.6768 2.79365 30.095C2.79365 23.5132 8.14832 18.1585 14.7302 18.1585C21.312 18.1585 26.6667 23.5132 26.6667 30.095C26.6667 36.6768 21.312 42.0315 14.7302 42.0315Z" fill="#CCCCCC" />
            <path d="M62.6032 38.3491H60.1905V35.9364C60.1905 35.1649 59.5651 34.5396 58.7937 34.5396C58.0222 34.5396 57.3968 35.1649 57.3968 35.9364V38.3491H54.9841C54.2127 38.3491 53.5873 38.9745 53.5873 39.7459C53.5873 40.5173 54.2127 41.1427 54.9841 41.1427H57.3968V43.5554C57.3968 44.3269 58.0222 44.9522 58.7937 44.9522C59.5651 44.9522 60.1905 44.3269 60.1905 43.5554V41.1427H62.6032C63.3746 41.1427 64 40.5173 64 39.7459C64 38.9745 63.3746 38.3491 62.6032 38.3491Z" fill="#CCCCCC" />
        </g>
        <defs>
            <clipPath id="clip0_1446_17653">
                <rect width="64" height="64" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
const CloseSelectFriendsSVG: FC<SVGProps> = (props) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6667 4.2735L11.7267 3.3335L8.00001 7.06016L4.27334 3.3335L3.33334 4.2735L7.06001 8.00016L3.33334 11.7268L4.27334 12.6668L8.00001 8.94016L11.7267 12.6668L12.6667 11.7268L8.94001 8.00016L12.6667 4.2735Z" fill="white" />
    </svg>

)
