import { AccountParams, retrieveUser, updateAccount } from 'api/account';
import { supabase } from 'api/initSupabase';
import { showToastMessage } from 'components/GlobalLoading';
import { localSet } from 'utils/localStorage';

const useUser = () => {
  // const history = useHistory();

  const updateProfile = async (params: Omit<AccountParams, 'id'>) => {
    try {
      const user = await retrieveUser();
      if (!user) return;
      const res = await updateAccount({ id: user.id, ...params });
      return res;
    } catch (error: any) {
      showToastMessage(error.message);
    }
  };
  const getProfile = async () => {
    try {
      const user = await retrieveUser();
      return user;
    } catch (error: any) {
      // showToastMessage(error?.message??'Unknown Error');
      // logout({ isNeedCaptcha: false });
    }
  };
  const logout = async ({
    isNeedCaptcha,
    returnTo
  }: { isNeedCaptcha?: boolean; returnTo?: string } = {}) => {
    localStorage.clear();
    if (!isNeedCaptcha) {
      localSet('haslogin', true);
    }
    supabase.auth.signOut();
    if (returnTo) {
      window.location.href = returnTo;
    } else {
      window.location.href = '/';
    }
  };
  return { updateProfile, getProfile, logout };
};

export default useUser;
