import { IonModal, IonContent } from "@ionic/react"
import style from "../style/Upcoming.module.css";
import { useRef, useState , useLayoutEffect } from "react";
import { validatePinCode } from "../db/schema/account";
import { Haptics } from '@capacitor/haptics';
import { setShowLoading, showToastMessage } from "./GlobalLoading";
import { useStore } from "../store"
import SVGIcon from "./SvgComponent";

interface Props {
    openAuth0Modal: any
    setOpenAuth0Modal: any
    buyFunc?: any
    afterBuyCloseModal?: any
}
const PinCode: React.FC<Props> = ({
    openAuth0Modal,
    setOpenAuth0Modal,
    buyFunc,
    afterBuyCloseModal
}) => {
    const { inFriends } = useStore();
    const refs = useRef<any>([]);
    const [code, setCode] = useState(["", "", "", ""])
    const [inpAct, setInpAct] = useState([false, false, false, false])

    const [shake, setShake] = useState(false);
    const confirmPincode = (newcode: any) => {
        setShowLoading(true)
        validatePinCode(newcode).then(async (res: any) => {
            if (navigator.onLine === false) {
                setShowLoading(false)
                showToastMessage('Please check your network and try later.')
                return
            }
            if (res === true) {

                await buyFunc()
                setShowLoading(false)
            } else {
                if (afterBuyCloseModal) {
                    afterBuyCloseModal(false)
                }

                showToastMessage('Incorrect PIN.')
                setShowLoading(false)
                Haptics.vibrate()
                setShake(true)
                setCode(['', '', '', ''])
                setInpAct([false, false, false, false])
                setTimeout(() => {
                    refs.current[0].focus()
                }, 0);
            }

        })

    }

    const handleInputChange = async (event: any, index: number) => {
        const { value } = event.target;

        if (value.length > 1) return
        const newCode = [...code];
        newCode[index] = value;

        setCode(newCode);
        newCode.map((item: any, i: any) => {
            if (item !== '') {
                inpAct[i] = true
            } else {
                inpAct[i] = false
            }
            return setInpAct(inpAct)
        })
        if (value.length === 1 && index < code.length - 1) {
            refs.current[index + 1].focus();
        } else if (value.length === 0 && index > 0) {
            refs.current[index - 1].focus();
        }

        if (index === newCode.length - 1) {
            confirmPincode(newCode)
        }

    }
    // delete one pin code
    const deleteOne = (event: any) => {
        const key = event.keyCode || event.charCode;
        const newCode = [...code];
        const newInpAct = [...inpAct]
        if (key === 8 || key === 46) {
            if (code[0].length > 0 && code[1].length === 0 && code[2].length === 0 && code[3].length === 0) {
                newCode[0] = ''
                newInpAct[0] = false
                setCode(newCode)
                setInpAct(newInpAct)
                refs.current[0].focus();
            }
            if (code[0].length > 0 && code[1].length > 0 && code[2].length === 0 && code[3].length === 0) {
                newCode[1] = ''
                newInpAct[1] = false
                setCode(newCode)
                setInpAct(newInpAct)
                refs.current[1].focus();
            }
            if (code[0].length > 0 && code[1].length > 0 && code[2].length > 0 && code[3].length === 0) {
                newCode[2] = ''
                newInpAct[2] = false
                setCode(newCode)
                setInpAct(newInpAct)
                refs.current[2].focus();
            }
            if (code[0].length > 0 && code[1].length > 0 && code[2].length > 0 && code[3].length > 0) {
                newCode[3] = ''
                newInpAct[3] = false
                setCode(newCode)
                setInpAct(newInpAct)
                refs.current[3].focus();
            }
        }
    }

    // auto focus
    useLayoutEffect(() => {
        setTimeout(() => {
            refs.current[0]?.focus()
        }, 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {/* pincode modal */}
            < IonModal
                isOpen={openAuth0Modal}
                className={inFriends ? style.pincodeInFriends
                    : style.pincodeNotFriends}
                onDidDismiss={() => {
                    setCode(['', '', '', ''])
                    setInpAct([false, false, false, false])
                    setOpenAuth0Modal(false)
                }}
            >
                <IonContent className="ion-padding">
                    <div className={style.pinsmain}>
                        <div className={style.pinsTop} onClick={() => {
                            setOpenAuth0Modal(false)
                        }}>
                            <SVGIcon name="BackSvg" width="10" height="16" viewBox="0 0 8 12"/>
                        </div>
                        <div className={style.pinDes}>
                            <div>Enter Your PIN Code</div>
                            <p className={style.pinsp}>Please enter the PIN code of the wallet</p>
                        </div>
                        <ul className={style.pinsul}>
                            {code.map((value: any, index: any) => (
                                <li
                                    key={index}
                                    style={shake ? { animation: " shake 0.8s", borderColor: "red" } : {}}
                                    onAnimationEnd={() => {
                                        setShake(false);
                                        refs.current[0].focus()
                                    }}>
                                    <input
                                        type="number"
                                        value={value}
                                        maxLength={1}
                                        autoFocus={index === 0 ? true : false}
                                        data-index={index}
                                        onKeyDown={(event) => deleteOne(event)}
                                        onChange={(event) => handleInputChange(event, index)}
                                        ref={(input) => (refs.current[index] = input)}
                                    />
                                    <div
                                        className={inpAct[index] === true ? style.pinsudiv : style.nonepinsudiv}>
                                        *
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className={style.pinsbtn} onClick={() => confirmPincode(code)}>Confirm</div>
                    </div>
                </IonContent>
            </IonModal >
        </>
    )
}

export default PinCode