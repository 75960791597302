import { FC } from "react"
import style from '../../../style/emoji-components.module.scss'
interface ChatroomEmojiCommentProps {
    emojiList: { emote: string, count: number }[]
}
const ChatroomEmojiComment: FC<ChatroomEmojiCommentProps> = ({ emojiList }) => {

    return (
        <div className={style['emoji-comment']}>
            <ul>
                {
                    emojiList.map(emojiItem => (
                        <li key={emojiItem.emote}>{emojiItem.emote}<span>{emojiItem.count}</span></li>
                    ))
                }
            </ul>
        </div>

    )
}

export default ChatroomEmojiComment