export function clearIndexedDB() {
    const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
    if(!indexedDB.databases) return
    const preserveDatabases = ['face_id', 'setting', 'version', 'share', 'wallet'];
    indexedDB.databases().then(databases => {
        databases.forEach(database => {
            if (!preserveDatabases.some(dbName => database.name.indexOf(dbName) > -1)) {
                indexedDB.deleteDatabase(database.name);
            }
        });
    });
};
