import { FC } from "react";

type SVG = React.SVGProps<SVGSVGElement>

export const DropDownSVG: FC<SVG> = (props) => (
    <svg {...props} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.91 0.450195L6.5 5.0302L11.09 0.450195L12.5 1.8602L6.5 7.8602L0.5 1.8602L1.91 0.450195Z" fill="white" />
    </svg>

)

export const PeopleSVG: FC<SVG> = (props) => (
    <svg {...props} width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.50003 6.81982C3.72003 6.81982 0.170044 7.70982 0.170044 9.48982V11.4898H10.84V9.48982C10.84 7.71982 7.29004 6.81982 5.51004 6.81982H5.50003ZM5.50003 0.819824C4.03003 0.819824 2.83002 2.00982 2.83002 3.48982C2.83002 4.96982 4.02003 6.15982 5.50003 6.15982C6.98003 6.15982 8.17004 4.96982 8.17004 3.48982C8.17004 2.00982 6.98003 0.819824 5.50003 0.819824ZM5.50003 8.08982C7.48003 8.08982 9.57004 9.05982 9.57004 9.48982V10.2198H1.44003V9.48982C1.44003 9.05982 3.53004 8.08982 5.51004 8.08982H5.50003ZM5.50003 2.08982C6.27003 2.08982 6.90002 2.71982 6.90002 3.48982C6.90002 4.25982 6.27003 4.88982 5.50003 4.88982C4.73003 4.88982 4.10004 4.25982 4.10004 3.48982C4.10004 2.71982 4.73003 2.08982 5.50003 2.08982Z" fill="#CCCCCC" />
    </svg>
)


export const SmileSVG: FC<SVG & { isActive?: boolean }> = ({ isActive = false, ...props }) => (
    <svg {...props} width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.09 13.6398C14.9 15.0198 13.16 15.8098 11.3 15.8098C9.43995 15.8098 7.69995 15.0198 6.50995 13.6398C5.93995 12.9798 6.96995 12.1598 7.53995 12.8098C8.46995 13.8898 9.83995 14.5098 11.3 14.5098C12.76 14.5098 14.13 13.8898 15.06 12.8098C15.62 12.1598 16.65 12.9798 16.09 13.6398ZM7.95995 6.77977C8.69995 6.77977 9.29995 7.35977 9.29995 8.06977C9.29995 8.77977 8.69995 9.35977 7.95995 9.35977C7.21995 9.35977 6.61995 8.77977 6.61995 8.06977C6.61995 7.35977 7.21995 6.77977 7.95995 6.77977ZM14.64 6.77977C15.38 6.77977 15.98 7.35977 15.98 8.06977C15.98 8.77977 15.38 9.35977 14.64 9.35977C13.9 9.35977 13.3 8.77977 13.3 8.06977C13.3 7.35977 13.9 6.77977 14.64 6.77977ZM11.3 0.00976562C5.57995 0.00976562 0.949951 4.48977 0.949951 10.0098C0.949951 15.5298 5.57995 20.0098 11.3 20.0098C17.02 20.0098 21.65 15.5298 21.65 10.0098C21.65 4.48977 17.02 0.00976562 11.3 0.00976562Z" fill={isActive ? "#ffb02e" : "#A6ABAF"} />
    </svg>
)

export const SendSVG: FC<SVG> = (props) => (
    <svg {...props} width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 0V7L15.49 9L0.5 11V18L21.5 9L0.5 0ZM10.01 11.75L2.5 14.97V12.75L10.01 11.75ZM2.51 3.03L10.02 6.25L2.5 5.25V3.03H2.51Z" fill="black" />
    </svg>
)

export const AddSVG: FC<SVG> = (props) => (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM8 14H10V10H14V8H10V4H8V8H4V10H8V14Z" fill="#CCCCCC" />
    </svg>
)

export const BlockSVG: FC<SVG> = (props) => (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 0.666992C4.4 0.666992 0.666992 4.39998 0.666992 8.99998C0.666992 13.6 4.4 17.333 9 17.333C13.6 17.333 17.333 13.6 17.333 8.99998C17.333 4.39998 13.6 0.666992 9 0.666992ZM9 15.667C5.317 15.667 2.33301 12.683 2.33301 8.99998C2.33301 7.45798 2.858 6.04198 3.742 4.91698L13.083 14.258C11.958 15.142 10.542 15.667 9 15.667ZM14.258 13.083L4.91699 3.74199C6.04199 2.85799 7.458 2.33298 9 2.33298C12.683 2.33298 15.667 5.31698 15.667 8.99998C15.667 10.542 15.142 11.958 14.258 13.083Z" fill="black" />
    </svg>
)

export const ReturnSVG: FC<SVG> = (props) => (
    <svg {...props} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.70489 1.41L6.29489 0L0.294891 6L6.29489 12L7.70489 10.59L3.12489 6L7.70489 1.41Z" fill="white" />
    </svg>
)

export const SearchWordSVG: FC<SVG> = (props) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1291 9.8791H10.4707L10.2374 9.6541C11.0541 8.7041 11.5457 7.4708 11.5457 6.1291C11.5457 3.1374 9.1207 0.712402 6.1291 0.712402C3.1374 0.712402 0.712402 3.1374 0.712402 6.1291C0.712402 9.1208 3.1374 11.5458 6.1291 11.5458C7.4707 11.5458 8.7041 11.0541 9.6541 10.2374L9.8791 10.4708V11.1291L14.0457 15.2874L15.2874 14.0458L11.1291 9.8791ZM6.1291 9.8791C4.0541 9.8791 2.3791 8.2041 2.3791 6.1291C2.3791 4.0541 4.0541 2.3791 6.1291 2.3791C8.2041 2.3791 9.8791 4.0541 9.8791 6.1291C9.8791 8.2041 8.2041 9.8791 6.1291 9.8791Z" fill="#A6A9B8" />
    </svg>

)


export const ClearWordSVG: FC<SVG> = (props) => (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.72699 4.33325L7 6.05995L5.27301 4.33325L4.33301 5.27325L6.06 6.99995L4.33301 8.72655L5.27301 9.66656L7 7.93996L8.72699 9.66656L9.66699 8.72655L7.94 6.99995L9.66699 5.27325L8.72699 4.33325ZM7 0.333252C3.313 0.333252 0.333008 3.31325 0.333008 6.99995C0.333008 10.6866 3.313 13.6666 7 13.6666C10.687 13.6666 13.667 10.6866 13.667 6.99995C13.667 3.31325 10.687 0.333252 7 0.333252ZM7 12.3333C4.06 12.3333 1.66699 9.93995 1.66699 6.99995C1.66699 4.05995 4.06 1.66656 7 1.66656C9.94 1.66656 12.333 4.05995 12.333 6.99995C12.333 9.93995 9.94 12.3333 7 12.3333Z" fill="#A6A9B8" />
    </svg>


)



