import { IonModal, IonContent, IonRefresher, IonRefresherContent, IonList, IonItem, IonLabel, IonToggle, IonAlert } from "@ionic/react";
import React, { useState } from "react";
import style from "../../../style/Account.module.css";
import { deleteFaceId } from "../../../db/schema/faceId";
import { createCreds } from "../../../utils/Webauthn";
import { getSetting, updateSetting } from "../../../db/schema/settings";
import { useStore } from "../../../store";
import SVGIcon from "components/SvgComponent";


const SettingsModal: React.FC = () => {
    const { isSettingModalOpen,changeModalState } = useStore();


    const [isEnableBiometric, setIsEnableBiometric] = useState(false)
    const [isDisableBio, setIsDisableBio] = useState(false)
    const [deleteBiometrics, setDeleteBiometrics] = useState(false)

    const [reload, setReload] = useState(false)
    const [biometricsNotSupported, setBiometricsNotSupported] = useState(false)
    const [biometricFailedMessage, setBiometricFailedMessage] = useState('')

    const init = async () => {

        const settings = await getSetting();
        console.log('settings', JSON.parse(JSON.stringify(settings)));
        setIsEnableBiometric(settings.biometric)

    }

    return (
        <>
            <IonModal
                isOpen={isSettingModalOpen}
                className={style.settingsModal}
                mode="ios"
                onDidDismiss={() => {
                    changeModalState('isSettingModalOpen', false)
                    if (reload) {
                        window.location.reload()
                    }
                }}
                onIonModalWillPresent={async () => {
                    await init();
                }}
            >
                <IonContent>
                    <div className={style.settingsModal_top}>
                        <div onClick={() => { changeModalState('isSettingModalOpen', false) }}>
                            <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                        </div>
                        <span>Settings</span>
                        <div style={{ paddingRight: '16px' }}></div>
                    </div>
                    <IonRefresher slot="fixed" onIonRefresh={(e) => {
                        setTimeout(() => {
                            e.detail.complete()
                        }, 1000);
                    }}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    <IonList lines="none" mode='ios' className={style.lists} >
                        <IonItem>
                            <IonLabel style={{ color: '#fff' }}>Biometric Authentication</IonLabel>
                            <IonToggle
                                slot="end"
                                checked={isEnableBiometric}
                                color='success'
                                // onClick={async () => {
                                //     setIsEnableBiometric(!isEnableBiometric)
                                // }}

                                onClick={async () => {
                                    if (!isEnableBiometric && isDisableBio === false) {
                                        try {
                                            const result = await createCreds();
                                            if (result === true) {
                                                setIsEnableBiometric(true)
                                                await updateSetting({ biometric: true })
                                            } else if (result === 'not') {
                                                setIsEnableBiometric(false)
                                                setBiometricFailedMessage('Your device does not support biometric authentication')
                                                setBiometricsNotSupported(true)
                                            }
                                        } catch (e) {
                                            setTimeout(() => {
                                                setIsEnableBiometric(false)
                                            }, 0)
                                            console.log('createCreds:', e);
                                            setBiometricFailedMessage('Biometric verification enablement failed, you need to try again later or restart the application')
                                            setBiometricsNotSupported(true)
                                            return
                                        }

                                    } else {
                                        setDeleteBiometrics(true)
                                    }
                                    // setIsEnableBiometric(!isEnableBiometric)

                                }}>

                            </IonToggle>
                        </IonItem>
                        <IonItem onClick={() => changeModalState('isClearDatabaseModalOpen', true)}>
                            <div>
                                Clear Local Cache
                                <SVGIcon name="EnterArrow" width="24" height="24" viewBox="0 0 24 24"/>
                            </div>
                        </IonItem>
                    </IonList>
                    {/* <div className={style.realName} onClick={() => setOpenClearDatabase(true)}>
                </div> */}

                </IonContent>
            </IonModal>

            <IonAlert
                isOpen={biometricsNotSupported}
                onDidDismiss={() => {
                    setBiometricsNotSupported(false)
                }}
                mode="ios"
                message={biometricFailedMessage}
                buttons={[
                    {
                        text: 'OK',
                        role: 'yes',
                        handler: async () => {

                        },
                    }
                ]}
            />

            <IonAlert
                isOpen={deleteBiometrics}
                onDidDismiss={() => {
                    setDeleteBiometrics(false)
                }}
                mode="ios"
                message="Are you sure you want to disable biometric authentication?"
                buttons={[
                    {
                        text: 'Yes',
                        role: 'yes',
                        handler: async () => {
                            await deleteFaceId();
                            await updateSetting({ biometric: false })
                            await setIsEnableBiometric(false)
                            await setReload(true)
                        },
                    },
                    {
                        text: 'No',
                        role: 'no',
                        handler: async () => {
                            await setIsDisableBio(true)
                            await setIsEnableBiometric(true)
                            await setDeleteBiometrics(false)
                        }
                    }
                ]}
            />
        </>
    )
}

export default SettingsModal;
