import { StateCreator } from 'zustand/vanilla';

export type GlobalStateKey = 
  | "isLoading"
  | "isSignInModalOpen"
  | "isSignUpModalOpen"
  | "isForgotPasswordModalOpen"
  | "isPincodeModalOpen"
  | "isClickRing"
  | "isClickDaily"
  | "inFriends";

export interface GlobalState {
  isLoading: boolean;
  isSignInModalOpen: boolean;
  isSignUpModalOpen: boolean;
  isForgotPasswordModalOpen: boolean;
  isPincodeModalOpen: boolean;
  isClickRing: boolean;
  isClickDaily: boolean;
  chatProps: any;
  inFriends: boolean;
  setGlobalState: (key: GlobalStateKey, state: boolean) => void;
  setChatProps: (data: any) => void;
}

export const createGlobalSlice: StateCreator<GlobalState> = (set) => {
  const createSetBooleanState = (key:GlobalStateKey, state: boolean)=>
    set(() => ({[key]: state}));

  const initialState: GlobalState = {
    isLoading: false,
    isSignInModalOpen: false,
    isSignUpModalOpen: false,
    isForgotPasswordModalOpen: false,
    isPincodeModalOpen: false,
    isClickRing: false,
    isClickDaily: false,
    chatProps: null,
    inFriends: false,
    setGlobalState: createSetBooleanState,
    setChatProps: (data: any) => set(() => ({chatProps: data })),
  };

  return initialState;
};
