import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const accountSchema = {
    title: 'accountSchema',
    description: 'account info ',
    version: 0,
    primaryKey: 'email',
    type: 'object',
    properties: {
        walletAddress: {
            type: 'string',
        },
        userName: {
            type: 'string',
        },
        email: {
            type: 'string',
            maxLength: 100,
        },
        dateCreated: {
            type: 'string',
            format: 'date-time',
        },
        walletInfo: {
            type: 'object'
        },
        pin: {
            type: "array"
        }
    },
    required: [
        'walletAddress',
        'userName',
        'dateCreated',
        'pin'
    ],
};

// validate pin code
export const validatePinCode = async (pin) => {
    const db = await Database.get();
    const validate = db.account.findOne({
        selector: { walletAddress: localGet('authAddress') }
    }).exec().then(async (account) => {
        /* if (pin.length === 4 && account.pin.length === 4) {
            if (pin[0] === account.pin[0] && pin[1] === account.pin[1] && pin[2] === account.pin[2] && pin[3] === account.pin[3]) {
                console.log('pin code is correct');
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        } */
        /* derivePinCode(pin).then((pinCode) => {
            if (pinCode === account.pin) {
                return true;
            } else {
                return false;
            }
        }); */
        const pincode = await derivePinCode(pin);
        // console.log(pin);
        // console.log(pincode);
        // console.log(account.pin);
        if (pincode === account.pin) {
            return true;
        }
        return false;
    });
    return validate;
}

// function bufferToHex(buffer) {
//     return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
// }
function bufferToBase64(buffer) {
    return window.btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
}
export const derivePinCode = async (pin) => {
    const enc = new TextEncoder();
    const dataBuffer = enc.encode(pin);
    const saltBuffer = enc.encode('OIAMpL9Bu^3$g6UU');
    const importedKey = await window.crypto.subtle.importKey(
        "raw",
        dataBuffer,
        "PBKDF2",
        false,
        ["deriveBits", "deriveKey"]
    ); 
    const derivedKey = await window.crypto.subtle.deriveKey(
        {
            "name": "PBKDF2",
            salt: saltBuffer,
            "iterations": 1000,
            "hash": "SHA-256"
        },
        importedKey,
        { "name": "AES-GCM", "length": 256 },
        true,
        ["encrypt", "decrypt"]
    );
    const exportedKey = await window.crypto.subtle.exportKey(
        "raw",
        derivedKey
    );
    return bufferToBase64(exportedKey);
};