import { IonModal, IonContent } from "@ionic/react";
import mixstyle from "../../../style/Topdata.module.css";
import { useStore } from "../../../store"
import SVGIcon from "components/SvgComponent";

interface Props {
    setModalContent: any
}
const UploadPhotoIDModal: React.FC<Props> = ({ setModalContent }) => {
    const { isUpLoadImageModalOpen,changeModalState } = useStore()
    return (
        <IonModal mode="ios"
            isOpen={isUpLoadImageModalOpen}
            onDidDismiss={() => changeModalState('isUpLoadImageModalOpen', false)}>
            <IonContent>
                <div className={mixstyle.upLoadImageModal}>
                    <div className={mixstyle.tit} onClick={() => changeModalState('isUpLoadImageModalOpen', false)}>
                        <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                        Upload a Photo ID
                    </div>
                    <div className={mixstyle.des}>
                        We required a photo of a government ID to verify your identity.
                    </div>
                    <div className={mixstyle.choose}>Choose one of the following options:</div>
                    <ul className={mixstyle.list}>
                        <li onClick={() => {
                            setModalContent('Driver License')
                            changeModalState('isDriverLicenseModal', true)
                        }}>
                            <div>
                                <SVGIcon name="DriverIcon" width="24" height="24" viewBox="0 0 24 24" fill="#A6A9B8"/>
                                <p>
                                    Driver License
                                    <SVGIcon name="ReferralsDiamond" width="12" height="12" viewBox="0 -3 12 12"/>
                                </p>
                            </div>
                            <SVGIcon name="EnterArrow" width="12" height="24" viewBox="6 0 12 24"/>
                        </li>
                        <li onClick={() => {
                            setModalContent('Passport')
                            changeModalState('isDriverLicenseModal', true)
                        }}>
                            <div>
                                <SVGIcon name="PassportIcon" width="24" height="24" viewBox="0 0 24 24"/>
                                Passport
                            </div>
                            <SVGIcon name="EnterArrow" width="12" height="24" viewBox="6 0 12 24"/>
                        </li>
                        <li onClick={() => {
                            setModalContent('Permanent Resident Card')
                            changeModalState('isDriverLicenseModal', true)
                        }}>
                            <div>
                                <SVGIcon name="PermanentResidentIcon" width="24" height="24" viewBox="0 0 24 24" fill="none"/>
                                Permanent Resident Card
                            </div>
                            <SVGIcon name="EnterArrow" width="12" height="24" viewBox="6 0 12 24"/>
                        </li>
                        <li onClick={() => {
                            setModalContent('Military Identification Card')
                            changeModalState('isDriverLicenseModal', true)
                        }}>
                            <div>
                                <SVGIcon name="MilitaryIdCardIcon" width="24" height="24" viewBox="0 0 24 24" />
                                <p>
                                    Military Identification Card
                                    <SVGIcon name="ReferralsDiamond" width="12" height="12" viewBox="0 -3 12 12"/>
                                </p>
                            </div>
                            <SVGIcon name="EnterArrow" width="12" height="24" viewBox="6 0 12 24"/>
                        </li>
                    </ul>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default UploadPhotoIDModal