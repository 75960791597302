import { IonModal, IonContent } from "@ionic/react";
import mixstyle from "../../../style/Topdata.module.css";
import { setShowLoading, showToastMessage } from "../../../components/GlobalLoading";
import { useStore } from "../../../store";
import SVGIcon from "components/SvgComponent";

interface Props {
    modalContent: any;
    setImgUrl: any;

}

const DriverLicenseModal: React.FC<Props> = ({
    modalContent,
    setImgUrl,
}) => {
    const { isUpLoadResultModalOpen,changeModalState } = useStore();

    const handleUpload = (e: any) => {
        let file: any = e.target.files
        const reader = new FileReader();
        let imgFile: any
        reader.onload = (e) => {
            imgFile = e.target?.result;
            setImgUrl(imgFile)
            if (navigator.onLine === false) {
                setShowLoading(false)
                showToastMessage('Please check your network and try later.')
                return
            }
            if (imgFile.length > 0) {
                changeModalState('isUpLoadResultModalOpen',true)
            } else {
                showToastMessage('Upload failed. Please try again.')
            }
        };
        reader.readAsDataURL(file[0]);
    }

    return (
        <IonModal isOpen={isUpLoadResultModalOpen}
            mode="ios"
            onDidDismiss={() => changeModalState('isDriverLicenseModal',false)}>
            <IonContent>
                <div className={mixstyle.driverLicenseModal}>
                    <div className={mixstyle.tit} onClick={() => changeModalState('isDriverLicenseModal',false)}>
                        <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                        {modalContent}
                    </div>
                    <div className={mixstyle.logo}>
                        <SVGIcon name="DriverLicenseUploadIcon" width="64" height="62" viewBox="0 0 64 62" fill="none" />
                        <p>Take a clear photo of the front at your {modalContent.toLowerCase()}.</p>
                    </div>
                    <div className={mixstyle.act}>
                        <div className={mixstyle.use}>
                            <label htmlFor="camera"> Upload a Photo</label>
                            <input type="file" accept="image/*" id="camera" onChange={(e) => handleUpload(e)} />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default DriverLicenseModal