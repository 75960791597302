import {
    IonAlert,
    IonButton,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonList,
    IonModal
} from "@ionic/react";
import { tokenApi } from "../api";
import { localGet, localSet } from "../utils/localStorage";

import gamestyle from "../style/Games.module.css";
import Joinstyle from "../style/Joined.module.css";
import style from "../style/Upcoming.module.css";
import ticketStyle from "../style/ticket.module.css";

import { Countup } from 'count-up-react';
import { useContext, useEffect, useRef, useState } from "react";
import CountUp from 'react-countup';
import img3 from '../image//billion/Option=Icon0.png';
import img from '../image//billion/Option=Icon01.png';
import img2 from '../image//billion/Option=Icon04.png';
import img1 from "../image/upcoming/1.png";
import dice from '../image/upcoming/dice.gif';
import back from "../image/upcoming/ill.png";
import { formatPrize } from "../utils/formatPrize";
// import notwinimg from '../image/upcoming/notwin.png'
// import { setEject } from "../db/sync/syncDatabase";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import { filterDraws, setJoinedDraw } from "../db/schema/draws";
// import circleLine from '../image/component/circleLine.png'

import { useLocalStorage } from "hooks";
import { Draw } from "typings/draw";
import { getAssets } from "../db/schema/assets";
import { getAllImages } from "../db/schema/images";
import { selectRatiosByVoucher } from "../db/schema/ratios";
import { useStore } from "../store";
import { validateCreds } from "../utils/Webauthn";
import { setShowLoading, showToastMessage } from "./GlobalLoading";
import PinCode from "./PinCode";
import SplitTicket from "./SplitTicket";
import SVGIcon from "./SvgComponent";
import { MyContext } from "./UseContextComponent";
import BuyTicketSwiper from "./buyTicketSwiper";
import Button from "./common/Button";

const BuyTicket: React.FC = () => {
    const [userInfo] = useLocalStorage('user_info')
    const [drawImages, setDrawImages] = useState<any>()
    useEffect(() => {
        const setimages = async () => {
            setDrawImages(await getAllImages())
        }
        setimages()
        //eslint-disable-next-line
    }, [localGet('images')])
    const { updateContext } = useContext(MyContext);
    const { isWinModalOpen,isFaceIdFailedAlertOpen,isAuth0ModalOpen,isJackpotModalOpen,isClickOpenModal,changeModalState } = useStore();

    const video1Ref = useRef<any>(null)
    const addCommas = (num: any) => num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // data
    const [list, setlist]: any = useState([])
    const [lazyListPageSize, setLazyListPageSize] = useState<number>(10)

    const generateItems = async () => {
        let ress;
        ress = await filterDraws('upcoming', '', 'desc', 10, lazyListPageSize / 10 + 1).then()
        if (ress.length === 0) {
        } else {
            let arr = [...list]
            arr.push(...ress)
            setlist(arr)
            setLazyListPageSize(lazyListPageSize + 10)
        }
    }
    const asyncFunc = async (props: any) => {
        if (props?.winning?.length > 0) {

            if (props.winning[props.winning.length - 1].isWinner === true) {

                changeModalState('isWinModalOpen', true)
                props.winning.map((item: any) => {
                    return localSet('prize', item.splitTicketPrize)

                })
                // setEject(props.winning)
            }
            //  else if (props.winning[props.winning.length - 1].type === "unWinning") {

            //     updateContext('notwin', true)

            //     setEject(props.winning)
            // }
        }
        if (props.getlist !== undefined && props.getlist !== null) {
            setlist(props.getlist)
        }
    }

    async function gets() {
        PubSub.subscribe('syncData', function (msg, searchName) {
            asyncFunc(searchName)
        })
    }

    useEffect(() => {
        gets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // const [isOpenMore, setIsOpenMore] = useState(false);

    // code modal
    const [usenum, setusenum] = useState<any[]>([])
    const [point, setPoint] = useState(true)




    // face id failed alert
    const [biometricFailedMessage, setBiometricFailedMessage] = useState('')

    // buy ticket handle
    const buyTicketHandle = async () => {
        setShowLoading(true)
        // updateContext('showLoading', true)
        let faceIdRes: any;
        try {
            faceIdRes = await validateCreds();
        } catch (e) {
            console.log('verify face id error:', e);
            setBiometricFailedMessage('Biometric verification enablement failed, you need to try again later or restart the application, do you want to switch to PIN code?')
            changeModalState('isFaceIdFailedAlertOpen', true)
        }
        if (faceIdRes === true) {
            await buyTicket()
        } else if (faceIdRes === 'null') {
            changeModalState('isAuth0ModalOpen', true)
        } else if (faceIdRes === 'not') {
            // setBiometricFailedMessage('Biometric verification enablement failed, you need to try again later or restart the application, do you want to switch to PIN code?')
            // setOpenFaceIDFailedAlert(true)
            changeModalState('isAuth0ModalOpen', true)
        }
        setShowLoading(false)
    }

    // encapsulate buyTicket operation
    const buyTicket = async () => {
        /* const userBalance = Number(localStorage.getItem('userBalance'));
        if (userBalance < Number(localGet('click_Item').ticketPrice) * Number(localGet('ticketAmount') || 1)) {
            handClose(false)
            showToastMessage('Player does not have enough money for draw')
            setShowLoading(false)
            setOpenAuth0Modal(false)
            return
        } */
        setShowLoading(true)
        await tokenApi('/buyTicket', localGet('buyData')).then(async (res) => {
            if (res.code === 200) {
                setJoinedDraw(localGet('buyData').drawId)
                localSet('buyTicket', true)
                changeModalState('isClickOpenModal', false)
                setusenum(res.data.ticketNumbers)
                changeModalState('isJackpotModalOpen', true)
                localSet('amount', 546)
                changeModalState('isAuth0ModalOpen', false)
                setShowLoading(false)
            } else {
                changeModalState('isClickOpenModal', false)
                showToastMessage(res.detail)
                setShowLoading(false)
                changeModalState('isAuth0ModalOpen', false)
            }
        }).catch((err) => {
            changeModalState('isClickOpenModal', false)
            showToastMessage(err)
            setShowLoading(false)
        })
    }


    // get video src
    const [videos, setVideos] = useState<any>([])
    useEffect(() => {
        const getVideoSrc = async () => {
            const videos: any = await getAssets('alexblocks_content/win');
            setVideos(videos)
        }
        getVideoSrc()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localGet('assets')])

    // close the lottery window regularly
    let lotteryWindowTimer: any = null
    const setOpenAuth0Modal = (data: any) => {
        changeModalState('isAuth0ModalOpen', data)
    }
    const afterBuy = async (data: any) => {
        changeModalState('isClickOpenModal', data)
    }
    const [ratio, setRatio] = useState(0)

    const onClickSplit = () => {
        changeModalState('isSplitTicketModalOpen', true)
    }
    const ticketInfo: Draw = localGet('click_Item')
    const [swiperIndex, setSwiperIndex] = useState<number>(0)
    return (
        <>

            {/* More Option modal isOpen*/}
            <IonModal
                isOpen={isClickOpenModal}
                className={style.optionmodal}
                onDidDismiss={() => {
                    // setIsOpenMore(false)
                    changeModalState('isClickOpenModal', false)
                    localStorage.removeItem('ticketAmount')
                }}
                onWillPresent={async () => {
                    setPoint(true)
                    const res = await selectRatiosByVoucher('ticket')
                    setRatio(res.ratio);
                }}
            >
                <IonContent className="ion-padding">
                    <div className={Joinstyle.more_box}>
                        <div className={style.moretop}>
                            <div className={style.moresvg} onClick={() => {
                                changeModalState('isClickOpenModal', false)
                            }}>
                                <SVGIcon
                                    name="BackSvg"
                                    width="8"
                                    height="12"
                                    viewBox="0 0 8 12"
                                    fill="none"
                                    style={{ marginRight: "10px" }}
                                 />
                                <span>{formatPrize(localGet('click_Item')?.prize)}</span>

                            </div>
                            <div className={style.moretopspan}>
                                <span>How it works?</span>
                            </div>
                        </div>
                        <div className={style.more_img}>
                            {
                                localGet('click_Item') && isClickOpenModal &&
                                <img
                                    style={{ borderRadius: '28px' }}
                                    src={localGet('click_Item')?.drawImage === '' ? img1 : (drawImages?.[localGet('click_Item')?.drawImage] || img1)} alt="" />
                            }
                            <div>
                                <i>Jackpot</i>
                                <p>${formatPrize(localGet('click_Item')?.prize)}</p>
                            </div>
                        </div>

                        <div className={style.more_imgtex}>
                            <div className={Joinstyle.detail}>
                                <p>Estimated draw takes place in 3:50:13</p>

                                <div className={Joinstyle.progrebox}>

                                    <div
                                        style={{ width: `${localGet('click_Item')?.ticketsSold / localGet('click_Item')?.maxTickets * 100}%` }}
                                        className={Joinstyle.progress}>

                                    </div>
                                </div>

                                <div className={style.count}>
                                    <div className={style.coutimg}>
                                        <img src={img} alt="" />
                                        <img src={img2} alt="" />
                                        <img src={img3} alt="" />
                                    </div>
                                    <span style={{ marginRight: '2px' }}>&</span>
                                    <Countup
                                        number={localGet('click_Item')?.ticketsSold}

                                        scrollTime={2000}
                                        className={Joinstyle.countUp}
                                    // className={style.coutnum}
                                    />
                                    <span style={{ marginLeft: '5px' }}>Joined</span>
                                </div>
                            </div>
                            <div className={point ? Joinstyle.joinbox : Joinstyle.joinboxnone}
                                onClick={() => {
                                    setPoint(false)
                                }}
                            >
                                <span>Tap to use gems to join</span>
                                <div className={Joinstyle.joinConst1}>
                                    <div className={Joinstyle.beting}>
                                        <p className={Joinstyle.fontsize1}>Betting</p>
                                        <p className={Joinstyle.fontsize2}>${addCommas(Number(localGet('click_Item')?.ticketPrice) * Number(localGet('ticketAmount') || 1))}</p>
                                    </div>

                                    <div className={Joinstyle.svg}>
                                        <SVGIcon
                                            name="RightArrowSvg"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                         />
                                    </div>
                                    <div className={Joinstyle.winning}>
                                        <p className={Joinstyle.fontsize1}>Winning</p>
                                        <p className={Joinstyle.fontsize2}>${addCommas(localGet('click_Item')?.prize)}</p>
                                    </div>
                                </div>
                            </div>

                            <div className={point ? Joinstyle.joinboxnone : Joinstyle.joinbox1}
                                onClick={() => {
                                    setPoint(true)
                                }}
                            >
                                <span>Tap to use money to join</span>
                                <div className={Joinstyle.joinConst1}>
                                    <div className={Joinstyle.beting}>

                                        <p className={Joinstyle.fontsize1}>Betting</p>
                                        <p className={Joinstyle.fontsize2}>
                                            <SVGIcon
                                             width="14"
                                             height="12"
                                             viewBox="0 0 14 12"
                                             fill="none"
                                             style={{ margin: '0 5' }}/>
                                            {addCommas(localGet('click_Item')?.ticketPrice * ratio * Number(localGet('ticketAmount') || 1)) || 0}
                                        </p>
                                    </div>

                                    <div className={Joinstyle.svg}>
                                        <SVGIcon
                                            name="RightArrowSvg"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 16 16"
                                            fill="none"/>
                                    </div>
                                    <div className={Joinstyle.winning}>
                                        <p className={Joinstyle.fontsize1}>Winning</p>
                                        <p className={Joinstyle.fontsize2}>${addCommas(localGet('click_Item')?.prize)} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={Joinstyle.confirm} style={{ marginTop: '32px' }}>
                            <IonButton className={Joinstyle.confirmBtn} onClick={() => {
                                const data = {
                                    "drawId": localGet('click_Item').drawId,
                                    "ticketAmount": localGet('ticketAmount') || 1,
                                    "receiver": localGet('authAddress'),
                                    "paymentMethod": point ? "money" : 'point',
                                }
                                localSet('buyData', data)
                                buyTicketHandle()

                            }}> Confirm</IonButton>
                        </div>
                        <div className={Joinstyle.close} style={{ width: '100%', margin: '16px auto 0' }}
                            onClick={() => {
                                changeModalState('isClickOpenModal', false)
                            }}>
                            <p>Not Now</p>
                        </div>



                    </div>
                </IonContent>
            </IonModal>
            <SplitTicket prize={ticketInfo?.prize} drawId={ticketInfo?.drawId} drawImage={(drawImages?.[ticketInfo?.drawImage] || img1) as string} ticketNumber={usenum[swiperIndex]}  />
            {/* pincode modal */}
            {isAuth0ModalOpen && <PinCode
                openAuth0Modal={isAuth0ModalOpen}
                setOpenAuth0Modal={setOpenAuth0Modal}
                buyFunc={buyTicket}
                afterBuyCloseModal={afterBuy} />}

            {/* Jackpot modal  JackpotOpen*/}
            <IonModal
                isOpen={isJackpotModalOpen}
                className={ticketStyle.jackmodal}
                onDidPresent={() => {
                }}
                onDidDismiss={() => {
                    changeModalState('isJackpotModalOpen', false);
                }}
            >
                <IonContent class="ion-padding">
                    <div className={ticketStyle.ticketModal}>
                        <div className={ticketStyle.top} onClick={() => {
                            // changeModalState('isJackpotModalOpen', false);
                            window.location.reload();
                            }}>
                            <SVGIcon name="CloseSvg" width="14" height="14" viewBox="0 0 14 14"/>
                        </div>
                        <div className={ticketStyle.ok}>
                            <SVGIcon
                                name="SuccessSvg"
                                width="64"
                                height="64"
                                viewBox="0 0 64 64"
                                fill="none"
                            />
                        </div>
                        <p>This is your ticket, thank you for joining!</p>
                        <BuyTicketSwiper setSwiperIndex={setSwiperIndex} list={usenum} drawImages={drawImages} />

                        <div className={ticketStyle.done} onClick={() => {
                            // changeModalState('isJackpotModalOpen', false);
                            window.location.reload();
                            }}>Done</div>
                        {(!ticketInfo?.promoted && point) && <Button style={{marginBottom:"90px"}} onClick={onClickSplit}>Split Ticket</Button>}

                    </div>
                </IonContent>
                {/* <div className={style.wrapper} >
                    <span> #{usenum}</span>
                    <p className={style.p}> Jackpot</p>
                    <p className={style.mun}>{addCommas(localGet('click_Item')?.prize)}</p>
                    <p className={style.Tick}>Ticket Number <span>#{usenum}</span></p>
                    <p className={style.Owner}>Owner <span>{localGet('usename') && localGet('usename').user_name}</span></p>

                </div> */}

            </IonModal >

            {
                userInfo &&
                <>
                    {/* winning modal   winModal*/}
                    < IonModal
                        isOpen={isWinModalOpen}
                        className={style.winnmodal}
                        onIonModalDidPresent={() => {
                            lotteryWindowTimer = setTimeout(() => {
                                changeModalState('isWinModalOpen', false)
                                PubSub.publish('closeWinModal', true);
                            }, 300000);
                            const clickEvent = new MouseEvent('click', {
                                view: window,
                                bubbles: true,
                                cancelable: true
                            });
                            video1Ref.current.children[0]?.children[3]?.children[0]?.dispatchEvent(clickEvent)
                        }}
                        onDidDismiss={() => {
                            clearTimeout(lotteryWindowTimer);
                            changeModalState('isWinModalOpen', false)
                            PubSub.publish('closeWinModal', true);
                        }}

                    >
                        <IonContent>
                            <div className={style.movie}>
                                <p className={gamestyle.delBtn} onClick={async () => {
                                    // video1Ref.current.pause()
                                    changeModalState('isWinModalOpen', false)
                                    PubSub.publish('closeWinModal', true);
                                }}>
                                    <SVGIcon name="CloseSvg" width="14" height="14" viewBox="0 0 14 14"/>
                                </p>
                                <div className={style.winnmvideo} ref={video1Ref}>
                                    {isWinModalOpen &&
                                        <Player
                                            // ref={video1Ref}
                                            preload='auto'
                                            autoPlay={false}
                                            playsInline={true}
                                            // src={localGet('assets')?.find((item: any) => (item._id === 'win'))?.data.EN['720']}
                                            src={videos?.find((item: any) => (item._id === 'alexblocks_content/win'))?.data.EN['720']}
                                            // src={winvideo}
                                            loop={true}
                                            controls={false}
                                            muted={true}
                                        >

                                        </Player>
                                    }

                                    <div className={style.winninNum} onClick={() => {
                                        console.log(video1Ref.current.children[0].children[3].children[0]);

                                    }}
                                    >
                                        <span>$</span>
                                        <CountUp
                                            end={localGet('prize') ? localGet('prize') : 0}
                                            separator={','}
                                            duration={10}
                                            start={0}
                                        />

                                    </div>
                                </div>


                            </div>
                            <div className={style.lists}>
                                <div className={style.change}>
                                    <p className={gamestyle.join}>Roll up my winnings:</p>
                                </div>
                                {list?.length > 0 ?
                                    <IonList className={style.cards}>
                                        {list?.map((item: any, index: any) => (
                                            <IonItem
                                                key={index}
                                                className={item.isJoined ? style.lijoined : ''}
                                            >
                                                <div className={style.left}>

                                                    <div className={style.leftimg} onClick={() => {
                                                        if (item.isJoined === true) return
                                                        localSet('click_Item', item)
                                                        updateContext('click_Item', item.drawImage)
                                                        changeModalState('isClickOpenModal', true)
                                                    }}>
                                                        <img src={item.drawImage === '' ? img1 : (drawImages?.[item.drawImage] || img1)} alt="" />
                                                    </div>

                                                    <div className={style.detail}>
                                                        <p>Join with ${addCommas(item.ticketPrice)}</p>
                                                        <p>${window.innerWidth <= 340 && item.prize >= 100000000 ? formatPrize(item.prize) : addCommas(item.prize)}</p>


                                                        <div className={style.progrebox}>

                                                            <div style={{
                                                                width: `${item.ticketsSold / item.maxTickets * 100}%`
                                                            }}
                                                                className={item.isJoined ? style.joined : style.progress}>

                                                            </div>
                                                        </div>
                                                        {item.winnerWallet !== "" && item.winnerNickname !== "" ?
                                                            <div className={style.count}>
                                                                <span>{item.winnerNickname} won this draw!</span>
                                                            </div>
                                                            :
                                                            <div className={style.count}>
                                                                <p className={style.justJoin}>
                                                                    <SVGIcon name="AccountSvg" width="12" height="12" viewBox="0 0 12 12"/>
                                                                </p>
                                                                <Countup
                                                                    number={item?.ticketsSold}

                                                                    scrollTime={2000}
                                                                    className={style.countUp}
                                                                />
                                                                <span style={{ marginLeft: '5px' }}>Joined</span>
                                                            </div>}

                                                    </div>
                                                </div>
                                                {item.winnerWallet !== "" ?
                                                    <div className={style.dice}>
                                                        <img src={item.winnerAvatarURL} alt="" />
                                                    </div>
                                                    : item.winnerWallet === "" && item.ticketsSold === item.maxTickets ?
                                                        <div className={style.dice}>
                                                            <img src={dice} alt="" />
                                                        </div>
                                                        : item.isJoined === true ? <div className={style.joinedicon}>
                                                            <SVGIcon
                                                                name="JoinedIconSvg"
                                                                width="21"
                                                                height="20"
                                                                viewBox="0 0 21 20"
                                                                fill="#42E05C"
                                                            />
                                                            Joined
                                                        </div> :
                                                            <div className={style.join} onClick={async () => {
                                                                localSet('click_Item', item)
                                                                updateContext('click_Item', item.drawImage)
                                                                changeModalState('isClickOpenModal', true)
                                                            }}>
                                                                <SVGIcon
                                                                    name="AddIconSvg"
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 14 14"
                                                                    fill="black"
                                                                 />
                                                            </div>}

                                            </IonItem>

                                        ))}
                                        <div className={style.winmodalbtn} onClick={() => {

                                            changeModalState('isWinModalOpen', false)
                                        }}>
                                            <p>Bank my winnings</p>
                                        </div>
                                    </IonList> :
                                    <div className={style.cartex}>
                                        <img src={back} alt="" />

                                        <p>No Draws, please wait</p>
                                    </div>
                                }
                                <IonInfiniteScroll
                                    onIonInfinite={(ev) => {
                                        generateItems();
                                        setTimeout(() => ev.target.complete(), 500);
                                    }}
                                >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </div>


                        </IonContent>
                    </IonModal>

                    {/* not winning modal   notwin*/}
                    {/* <IonModal isOpen={context.buyTicket.notwin}
                        className={style.winnmodal}
                        onIonModalDidPresent={() => {
                            lotteryWindowTimer = setTimeout(() => {
                                updateContext('notwin', false)
                                PubSub.publish('closeWinModal', true);
                            }, 300000);
                        }}
                        onDidDismiss={() => {
                            clearTimeout(lotteryWindowTimer);
                            updateContext('notwin', false)
                            PubSub.publish('closeWinModal', true);
                        }}>
                        <IonContent>
                            <div className={style.movie}>
                                <p className={gamestyle.delBtn} onClick={async () => {
                                    updateContext('notwin', false)
                                    PubSub.publish('closeWinModal', true);
                                }}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                                            fill="white" />
                                    </svg>
                                </p>
                                <img src={notwinimg} alt="" />
                            </div>
                            <div className={style.lists}>
                                <div className={style.change}>
                                    <p className={gamestyle.join}>Roll up my winnings:</p>
                                </div>
                                {list?.length > 0 ?
                                    <IonList className={style.cards}>
                                        {list?.map((item: any, index: any) => (
                                            <IonItem
                                                key={index}
                                                className={item.isJoined ? style.lijoined : ''}
                                            >
                                                <div className={style.left}>

                                                    <div className={style.leftimg} onClick={() => {
                                                        if (item.isJoined === true) return
                                                        localSet('click_Item', item)
                                                        updateContext('click_Item', item.drawImage)
                                                        updateContext('clickOpenModal', true)
                                                    }}>
                                                        <img src={item.drawImage === '' ? img1 : `${drawImages?.[item.drawImage] || undefined}`} alt="" />
                                                    </div>

                                                    <div className={style.detail}>
                                                        <p>Join with ${addCommas(item.ticketPrice)}</p>
                                                        <p>${window.innerWidth <= 340 && item.prize >= 100000000 ? formatPrize(item.prize) : addCommas(item.prize)}</p>


                                                        <div className={style.progrebox}>

                                                            <div style={{
                                                                width: `${item.ticketsSold / item.maxTickets * 100}%`
                                                            }}
                                                                className={item.isJoined ? style.joined : style.progress}>

                                                            </div>
                                                        </div>
                                                        {item.winnerWallet !== "" && item.winnerNickname !== "" ?
                                                            <div className={style.count}>
                                                                <span>{item.winnerNickname} won this draw!</span>
                                                            </div>
                                                            :
                                                            <div className={style.count}>
                                                                <p className={style.justJoin}>
                                                                    <svg
                                                                        width="12"
                                                                        height="12"
                                                                        viewBox="0 0 12 12"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M6.00008 1.93335C6.77341 1.93335 7.40008 2.56002 7.40008 3.33335C7.40008 4.10669 6.77341 4.73335 6.00008 4.73335C5.22675 4.73335 4.60008 4.10669 4.60008 3.33335C4.60008 2.56002 5.22675 1.93335 6.00008 1.93335ZM6.00008 7.93335C7.98008 7.93335 10.0667 8.90669 10.0667 9.33335V10.0667H1.93341V9.33335C1.93341 8.90669 4.02008 7.93335 6.00008 7.93335ZM6.00008 0.666687C4.52675 0.666687 3.33341 1.86002 3.33341 3.33335C3.33341 4.80669 4.52675 6.00002 6.00008 6.00002C7.47341 6.00002 8.66675 4.80669 8.66675 3.33335C8.66675 1.86002 7.47341 0.666687 6.00008 0.666687ZM6.00008 6.66669C4.22008 6.66669 0.666748 7.56002 0.666748 9.33335V11.3334H11.3334V9.33335C11.3334 7.56002 7.78008 6.66669 6.00008 6.66669Z"
                                                                            fill="#CCCCCC"
                                                                        />
                                                                    </svg>

                                                                </p>
                                                                <Countup
                                                                    number={item?.ticketsSold}

                                                                    scrollTime={2000}
                                                                    className={style.countUp}
                                                                />
                                                                <span style={{ marginLeft: '5px' }}>Joined</span>
                                                            </div>}

                                                    </div>
                                                </div>
                                                {item.winnerWallet !== "" ?
                                                    <div className={style.dice}>
                                                        <img src={item.winnerAvatarURL} alt="" />
                                                    </div>
                                                    : item.winnerWallet === "" && item.ticketsSold === item.maxTickets ?
                                                        <div className={style.dice}>
                                                            <img src={dice} alt="" />
                                                        </div>
                                                        : item.isJoined === true ? <div className={style.joinedicon}>
                                                            <svg
                                                                width="21"
                                                                height="20"
                                                                viewBox="0 0 21 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM10.5 18C6.09 18 2.5 14.41 2.5 10C2.5 5.59 6.09 2 10.5 2C14.91 2 18.5 5.59 18.5 10C18.5 14.41 14.91 18 10.5 18ZM15.09 5.58L8.5 12.17L5.91 9.59L4.5 11L8.5 15L16.5 7L15.09 5.58Z"
                                                                    fill="#42E05C"
                                                                />
                                                            </svg>
                                                            Joined
                                                        </div> :
                                                            <div className={style.join} onClick={async () => {
                                                                localSet('click_Item', item)
                                                                updateContext('click_Item', item.drawImage)
                                                                updateContext('clickOpenModal', true)

                                                            }}>
                                                                <svg
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 14 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
                                                                        fill="black"
                                                                    />
                                                                </svg>
                                                            </div>}

                                            </IonItem>

                                        ))}
                                        <div className={style.winmodalbtn} onClick={() => {

                                            updateContext('notwin', false)
                                        }}>
                                            <p>Bank my winnings</p>
                                        </div>
                                    </IonList> :
                                    <div className={style.cartex}>
                                        <img src={back} alt="" />

                                        <p>No Draws, please wait</p>
                                    </div>
                                }
                                <IonInfiniteScroll
                                    onIonInfinite={(ev) => {
                                        generateItems();
                                        setTimeout(() => ev.target.complete(), 500);
                                    }}
                                >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </div>


                        </IonContent>
                    </IonModal> */}
                </>
            }

            {/* <IonLoading
                cssClass="my-custom-class"
                isOpen={context.buyTicket.showLoading}
                onDidDismiss={() => updateContext('showLoading', false)}
                message={'Please wait...'}
                duration={10000000}
                mode="ios"
                spinner="crescent"
            /> */}

            <IonAlert
                isOpen={isFaceIdFailedAlertOpen}
                onDidDismiss={() => {
                    changeModalState('isFaceIdFailedAlertOpen', false)
                }}
                mode="ios"
                message={biometricFailedMessage}
                buttons={[
                    {
                        text: 'Yes',
                        role: 'yes',
                        handler: async () => {
                            changeModalState('isAuth0ModalOpen',true)
                        },
                    },
                    {
                        text: 'No',
                        role: 'no',
                        handler: async () => {
                        }
                    }
                ]}
            />

        </>
    );
};
export default BuyTicket;
