import { emojiAction, getSelfProfile, getUserCommentEmoji } from "api/chat";
import { showToastMessage } from "components/GlobalLoading";
import { useUser } from "hooks";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import style from '../../../style/emoji-components.module.scss';
interface ChatroomEmojiPickerProps {
    isOpen: boolean
    messageID: string
    emojiIconList: string[]
    updateEmojiList: () => void
}
const ChatroomEmojiPicker = forwardRef(({ isOpen, messageID, updateEmojiList, emojiIconList }: ChatroomEmojiPickerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { getProfile } = useUser()
    const [selectedEmojiList, setSelectedEmojiList] = useState<string[]>([])
    const onClickEmoji = (emoji: string) => {
        return async () => {
            try {
                const data = await getProfile()
                if (!data) return
                await emojiAction(messageID, data.id, emoji)
                getUserEmoji()
                updateEmojiList()
            }
            catch (error: any) {
                showToastMessage(error.message)
            }
        }
    }
    const getUserEmoji = async () => {
        try {
            const data = await getUserCommentEmoji(messageID)
            const user = await getSelfProfile()
            console.log(data)
            if (user.id === data?.[0]?.user_id) {
                setSelectedEmojiList(data?.[0]?.emotes ?? [])
            }
        }
        catch (error: any) {
            showToastMessage(error.message)
        }
    }

    useEffect(() => {
        if (isOpen) {
            getUserEmoji()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])
    return (
        <div className={style['emoji-picker']} ref={ref}>
            {isOpen && <ul >
                {emojiIconList.map(emoji =>
                    <li className={selectedEmojiList.includes(emoji) ? 'active' : ''} onClick={onClickEmoji(emoji)} key={emoji}>{emoji}</li>
                )}
            </ul>}
        </div>

    )
})

export default ChatroomEmojiPicker
