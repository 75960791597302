import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { filterDraws, getMaxPrizeDraw } from '../db/schema/draws';
import winner1 from "../image/games/Ellipse 7.png";
import winner2 from "../image/games/Ellipse1.png";
import winner3 from "../image/games/Ellipse2.png";
import winner4 from "../image/games/Ellipse3.png";
import img1 from "../image/upcoming/1.png";
import back from "../image/upcoming/ill.png";
import pastimg from "../image/upcoming/past.png";
import gamestyle from "../style/Games.module.css";
import style from "../style/Upcoming.module.css";
import { formatDate } from "../utils/formatDate";
import { localGet, localSet } from "../utils/localStorage";
// import { Player } from 'video-react';
import { User } from "@supabase/supabase-js";
import { useLocalStorage } from "hooks";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "video-react/dist/video-react.css";
import { getAllImages } from "../db/schema/images";
import crown from '../image/upcoming/crown.png';
import pastBack from '../image/upcoming/pastBack.jpg';
import streamer from '../image/upcoming/streamer.png';
import { formatPrize } from "../utils/formatPrize";
import SVGIcon from "components/SvgComponent";

const PastGames: React.FC = () => {
  const [drawImages, setDrawImages] = useState<any>()
  useEffect(() => {
    const setimages = async () => {
      setDrawImages(await getAllImages())
    }
    setimages()
    //eslint-disable-next-line
  }, [localGet('images')])
  const [userInfo] = useLocalStorage<User>('user_info')
  // const { user, isAuthenticated } = useAuth0();
  const [confirmlogin, setConfirmLogin] = useState(false)
  const addCommas = (num: any) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  const history = useHistory();
  const sculptures = [
    winner1,
    winner2,
    winner3,
    winner4
  ]

  const [jumppath, setJumpPath] = useState('pastgames')
  const [list, setlist]: any = useState([])
  const video1Ref = useRef<any>(null)
  // const videoRef = useRef<any>(null)
  const [winners, setWinners] = useState([])
  const getWin = async () => {
    const winners = await getMaxPrizeDraw()
    setWinners(winners)
    // console.log('winnerswinnerswinners', winners);
  }
  async function gets() {
    const ress = await filterDraws('past', 'desc', '', 10, 1).then()
    const getlist = ress?.map((item: any) => {
      const newData = {
        ...item._data,
        winnerAvatarURL: item._data.winnerAvatarURL === '' ? sculptures[Math.floor(Math.random() * 4)] : item._data.winnerAvatarURL
      }
      return newData
    })
    PubSub.subscribe('syncData', async (msg, searchName) => {
      if (searchName.scrollWin !== null && searchName.scrollWin !== undefined) {
        getWin()
        const ress = await filterDraws('past', 'desc', '', 10, 1).then()
        const getlist = ress.map((item: any) => {
          const newData = {
            ...item._data,
            winnerAvatarURL: item._data.winnerAvatarURL === '' ? sculptures[Math.floor(Math.random() * 4)] : item._data.winnerAvatarURL
          }
          return newData
        })
        setlist(getlist)
        setLazyListPageSize(10)
      }
    })
    setlist(getlist)
    setLazyListPageSize(10)
  }

  const getUrl = () => {
    window.localStorage.setItem('link', history.location.pathname)
  }
  async function listSort(type: string, sort: string) {
    let ress;
    if (type === "time") {
      ress = await filterDraws('past', sort, '', 10, 1).then()
    } else if (type === "prize") {
      ress = await filterDraws('past', '', sort, 10, 1).then()
    }
    const getlist = ress.map((item: any) => {
      const newData = {
        ...item._data,
        winnerAvatarURL: item._data.winnerAvatarURL === '' ? sculptures[Math.floor(Math.random() * 4)] : item._data.winnerAvatarURL
      }
      return newData
    })
    setLazyListPageSize(10)
    setlist(getlist)
  }
  const [lazyListPageSize, setLazyListPageSize] = useState<number>(10)

  const generateItems = async () => {
    let ress
    if (isClickSort === '') {
      ress = await filterDraws('past', '', 'desc', 10, lazyListPageSize / 10 + 1).then();
    } else if (isClickSort === 'time') {
      ress = await filterDraws('past', timeSortParame, '', 10, lazyListPageSize / 10 + 1).then()
    } else if (isClickSort === "prize") {
      ress = await filterDraws('past', '', prizeSortParame, 10, lazyListPageSize / 10 + 1).then()
    }
    if (ress.length === 0) {
    } else {
      let arr = [...list]
      arr.push(...ress)
      setlist(arr)
      setLazyListPageSize(lazyListPageSize + 10)
    }
  };
  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        if (localGet('reflesh') === true) {
          if (localGet('login') === true) {
          } else {
            // video1Ref.current.play()
            setConfirmLogin(true)
            localSet('login', true)
          }
        }
      }, 2000);


    }
    setJumpPath('pastgames')
    getUrl()
    getWin()
    gets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jumppath])
  // console.log(list);
  useEffect(() => {
    if (confirmlogin && userInfo) {
      if (video1Ref.current) {
        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        });
        video1Ref.current.children[0]?.children[3]?.children[0]?.dispatchEvent(clickEvent)
      }

    }
  }, [userInfo, confirmlogin])



  const [timeSortParame, setTimeSortParame] = useState('desc')
  const [prizeSortParame, setPrizeSortParame] = useState('')
  const [isClickSort, setIsClickSort] = useState('time')

  const [sort, setSort] = useState(true)
  const [przie, setPrzie] = useState(false)

  interface componentType {
    item: any
    swiper?: boolean
  }
  const ImgComponent: React.FC<componentType> = ({ item, swiper = false }) => {
    return (
      <>
        {
          item?.winnerMembers?.length === 0 &&
          <div className="ImgComponent">
            <img src={item?.winnerAvatarURL} alt="" />
          </div>
        }
        {
          item?.winnerMembers?.length === 1 &&
          <div className="ImgComponent">
            <div className="ImgComponentIMG2">
              <img src={item?.winnerAvatarURL} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
              <img src={item?.winnerMembers[0]?.avatar} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
            </div>
          </div>
        }
        {
          item?.winnerMembers?.length === 2 &&
          <div className="ImgComponent">
            <img className={swiper ? "ImgComponentIMG3Img" : 'ImgComponentIMG3ImgList ImgComponentIMG3Img'} src={item?.winnerAvatarURL} alt="" style={swiper ?
              item?.isJoined ? { borderColor: '#3f3f3f' } :
                {} : { borderColor: '#101a25' }} />
            <div className="ImgComponentIMG3">
              <img src={item?.winnerMembers[1]?.avatar} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
              <img src={item?.winnerMembers[0]?.avatar} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
            </div>
          </div>
        }
        {
          item?.winnerMembers?.length === 3 &&
          <div className="ImgComponent">
            <div className={swiper ? "ImgComponentIMG4Top" : 'ImgComponentIMG4Top ImgComponentIMG4ListTop ImgComponentIMGList'}>
              <img src={item?.winnerAvatarURL} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
              <img src={item?.winnerMembers[0]?.avatar} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
            </div>
            <div className={swiper ? "ImgComponentIMG4Bottom" : 'ImgComponentIMG4Bottom ImgComponentIMGList'}>
              <img src={item?.winnerMembers[1]?.avatar} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
              <img src={item?.winnerMembers[2]?.avatar} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
            </div>
          </div>
        }
        {
          item?.winnerMembers?.length > 3 &&
          <div className="ImgComponent">
            <div className={swiper ? "ImgComponentIMG4Top" : 'ImgComponentIMG4Top ImgComponentIMG4ListTop ImgComponentIMGList'}>
              <img src={item?.winnerAvatarURL} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
              <img src={item?.winnerMembers[0]?.avatar} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
            </div>
            <div className={swiper ? "ImgComponentIMG4Bottom" : 'ImgComponentIMG4Bottom ImgComponentIMGList'}>
              <img src={item?.winnerMembers[1]?.avatar} alt="" style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }} />
              <div style={swiper ? {} :
                item?.isJoined ? { borderColor: '#3f3f3f' } :
                  { borderColor: '#101a25' }}
                className={swiper ? "ImgComponentIMG4BottomText" : 'ImgComponentIMG4BottomListText'}>
                {item?.winnerMembers?.length - 2}
              </div>
            </div>
          </div>
        }
      </>
    )
  }
  return (
    <IonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => window.location.reload()}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className={style.upcoming}>
          <div className={style.movie}>
            {winners?.length <= 0 && <img src={pastimg} alt="" />}
            {winners?.length > 0 && <Swiper
              className="upComingSwiper"
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              {
                winners?.map((item: any, index: any) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="upComingSwiperDiv">
                        <img src={pastBack} alt="" />
                        <div className="upComingSwiperMain">
                          <div className="upComingSwiperMainleft">
                            <span>Winner</span>
                            <p>{item?.winnerMembers?.length > 0 ? `${item.winnerNickname}'s group` : item.winnerNickname}</p>
                            <div>
                              <img src={streamer} alt="" />
                              <p>$ {formatPrize(item.prize)}</p>
                            </div>
                          </div>
                          <div className="upComingSwiperMainRight">
                            <ImgComponent item={item} swiper={true} />
                            <div className="upComingSwiperCrown">
                              <img src={crown} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                }
                )
              }
            </Swiper>}


          </div>
          <div className={style.lists}>
            <div className={style.change}  >

              <div className={style.changeleft}>
                <p
                  className={jumppath === 'upcoming' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('upcoming')
                    history.push('/upcoming')
                  }}
                >
                  Upcoming Draws
                </p>
                <p
                  className={jumppath === 'joinedgames' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('joinedgames')
                    history.push('/joinedgames')
                  }}
                  style={{ display: localGet('auth0_user') ? 'block' : 'none' }}
                >
                  Joined Draws
                </p>
                <p
                  className={jumppath === 'pastgames' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('pastgames')
                    history.push('/pastgames')
                  }}
                >
                  Past Draws
                </p>
                <p style={{ width: '90px', display: localGet('auth0_user') ? 'none' : 'block' }}></p>

              </div>


            </div>
          </div>
          <div className={style.line}></div>
          <div className={style.changerigth}>
            <p>Order by</p>
            <div className={style.changesvg} onClick={() => {
              setTimeSortParame(timeSortParame === "asc" ? 'desc' : 'asc')
              listSort('time', timeSortParame === "asc" ? 'desc' : 'asc')
              setIsClickSort('time')
              setSort(true)
              setPrzie(false)
            }}>
              <SVGIcon name="ClockIcon" width="18" height="18" viewBox="0 0 18 18"  fill="white"/>
              <div className={
                sort === true ? (timeSortParame === "asc" ? style.nonechangesvgtop : style.changesvgtop1) : style.node
              } >
              <SVGIcon name="SortDownArrow" width="14" height="14" viewBox="0 0 14 14" fill="#9C94FF"/>
              </div>
              <span>Time</span>

            </div>



            <div className={style.changepath} onClick={() => {
              setPrizeSortParame(prizeSortParame === 'asc' ? "desc" : "asc")
              listSort('prize', prizeSortParame === 'asc' ? "desc" : "asc")
              setIsClickSort('prize')
              setSort(false)
              setPrzie(true)
            }}>
              <SVGIcon name="SortPriceIcon" width="18" height="18" viewBox="0 0 18 18" fill="white"/>
              <div className={
                przie === true ? (prizeSortParame === "asc" ? style.nonechangesvgtop : style.changesvgtop1) : style.node
              }
              >
              <SVGIcon name="SortDownArrow" width="14" height="14" viewBox="0 0 14 14" fill="#9C94FF"/>
              </div>
              <span>Prize</span>
            </div>


          </div>
          {list.length > 0 ?
            <IonList className={style.cards} >
              {list.map((item: any, index: any) => (
                <IonItem
                  key={index}
                  className={item.isJoined === true ? style.lijoined : ""}
                  style={item.isJoined === true ? { marginBottom: '4px' } : {}}
                >
                  <div className={style.left}>
                    <div className={style.leftimg}>
                      <img src={item?.drawImage === '' ? img1 : `${drawImages?.[item.drawImage] || img1}`} alt="" />
                    </div>

                    <div className={style.detail}>
                      <p>Join with ${addCommas(item.ticketPrice)}</p>
                      <p>${window.innerWidth <= 340 && item.prize >= 100000000 ? formatPrize(item.prize) : addCommas(item.prize)}</p>

                      <p className={gamestyle.winner}>{item?.winnerMembers?.length > 0 ? `${item.winnerNickname}'s group` : item.winnerNickname} won this draw at {formatDate(new Date(item.endTime))}!</p>
                    </div>
                  </div>
                  <div className={gamestyle.winnerimg}>
                    {/* <img style={{ borderRadius: '50%' }} src={`${item.winnerAvatarURL}?${new Date().getTime()}`} alt=""></img> */}
                    <ImgComponent item={item} />
                  </div>

                </IonItem>
              ))}
            </IonList> :
            <div className={style.cartex}>
              <img src={back} alt="" />
              <p>No Draws, Please Wait</p>
            </div>
          }
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              generateItems();
              setTimeout(() => ev.target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default PastGames;
