import { FC, useEffect, useState } from "react";
import { useChatroom,Chatroom } from "@DataAssetStore/chatcomponent";
import { useStore } from 'store'


interface RecentContactsResultsProps {
    loading?: boolean
    query: string
};



const RecentContactsResults: FC<RecentContactsResultsProps> = ({ query, loading = false }) => {
    const { chatroomList,setCurrentChatroom } = useChatroom()
    const {  changeModalState } = useStore()
    // const { chatRooms } = useStore()
    const [filterContacts, setFilterContacts] = useState<Chatroom[]>([])
    const isFilterNull = filterContacts.length === 0
    const isRecentContactNull = chatroomList.length === 0

    const handleClick = (item: Chatroom) => {
        changeModalState(item.isGroup ? 'isChatModalOpen' : 'isChatModalOpen', true)
        setCurrentChatroom(item)
        // return (event: MouseEvent<HTMLLIElement>) => {
        //     openChatModal(item)
        // }
    }
    useEffect(() => {
        setFilterContacts(chatroomList.filter((item) => {
            if(!item.name) return false
            const regex = new RegExp(query.trim(), 'i');
            return regex.test(item.name);
          }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return (
        <div style={{ width: '92%', height: 'fit-content', backgroundColor: '#000', position: 'absolute', zIndex: '9999', top: 105, maxWidth: '96%', display: 'inline-block', overflow: 'hidden' }}>
            {
                loading ? (
                    <p style={{ height: 50, lineHeight: '50px', textAlign: 'center' }}>
                        <span>Loading...</span>
                    </p>
                ) : (
                    <>
                        {
                            isFilterNull || isRecentContactNull ? (
                                <p style={{ height: 50, lineHeight: '50px', textAlign: 'center' }}>
                                    <span>No friends or group chats</span>
                                </p>
                            ) : (
                                <>
                                    <ul>
                                        {
                                            filterContacts.map((item) => {
                                                return (
                                                    <li onClick={()=>handleClick(item)} style={{ height: 50, lineHeight: '50px', paddingLeft: '16px' }} key={item.name}>
                                                        <span>{item.name}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            )
                        }

                    </>
                )
            }
        </div>
    )
}

export default RecentContactsResults;