import * as Database from '../';
// import { localGet } from "../../utils/localStorage";

/*

{
    "_id": "64376c24da734312675c2b7c",
    "draw": 18,
    "owner": "0xc1dEcad3cc30d06539eA4480C6151390c8Bf11Bc",
    "purchaser": "0xc1dEcad3cc30d06539eA4480C6151390c8Bf11Bc",
    "purchaseTime": "2023-04-13T02:42:44.095000"
}

*/
export const ticketsSchema = {
    title: 'ticketsSchema',
    description: 'tickets, buy tickets history',
    version: 4,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        draw: {
            type: 'string',
            maxLength: 100,
        },
        owner: {
            type: 'string',
            maxLength: 100,
        },
        ownerDetail: {
            type: 'object',
        },
        purchaser: {
            type: 'string',
        },
        purchaserDetail: {
            type: 'object',
        },
        state: {
            type: 'string',
        },
        ticketNumber: {
            type: 'number',
        },
        ticketShare: {
            type: 'number',
        },
        splitTicketPrice: {
            type: 'number',
        },
        ticketPrice: {
            type: 'number',
        },
        prize: {
            type: 'number',
        },
        organizer: {
            type: 'object',
        },
        inviters: {
            type: 'array',
        },
        paymentMethod: {
            type: 'string',
        },
        isWinner: {
            type: 'boolean',
        },
        purchaseTime: {
            type: 'string',
            format: 'date-time',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        },
        drawData: {
            ref: 'draw',
            type: 'string',
        },
        ticketPercentage: {
            type: 'string',
        }
    },
    required: [
        'draw',
        'owner',
        'purchaser',
        'purchaseTime'
    ],
    indexes: [
        'draw',
        'owner',
        ['draw', 'owner']
    ]
};

// select tickets by draw
export const selectTicketsByOwner = async (pageSize, pageNumber, selector) => {
    const db = await Database.get();
    const tickets = await db.tickets.find({
        selector: selector,
        sort: [{ purchaseTime: 'desc' }],
    }).skip((pageNumber - 1) * pageSize).limit(pageSize).exec();

    return await Promise.all(tickets.map(async ticket => {
        const drawData = await ticket.populate('drawData');
        return {
            ...ticket.toJSON(),
            drawData: drawData.toJSON(),
        };
    }));

};

/**
 * select ticket by ticket number
 * @param {number} ticketNumber
 * @returns
 */
export const selectTicketByTicketNumber = async (ticketNumber, owner, draw) => {
    const db = await Database.get();
    const ticket = await db.tickets.findOne({
        selector: {
            ticketNumber: ticketNumber,
            owner: owner,
            draw: draw
        },
    }).exec();

    return JSON.parse(JSON.stringify(ticket));
};
/**
 * select ticket by draw id
 * @param {number} draw
 * @returns
 */
export const selectTicketByDrawId = async (draw) => {
    const db = await Database.get();
    const ticket = await db.tickets.findOne({
        selector: {
            draw
        },
    }).exec();

    return JSON.parse(JSON.stringify(ticket));
};