import { Haptics } from '@capacitor/haptics';
import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { localSet } from "utils/localStorage";
import { showToastMessage } from "../components/GlobalLoading";
import imgs from '../image/login/Group 2152.png';
import style from '../style/Login.module.css';
const Login = () => {
    const history = useHistory();
    const [isHide, setIsHide] = useState(true)
    const [loginval, setloginval] = useState('')
    const [shake, setShake] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <IonPage>
            <IonContent>

                <div className={style.Login}>
                    <img src={imgs} alt="" />

                    <div className={style.inp}
                        style={shake ? { animation: " shake 0.8s" } : {}}
                        onAnimationEnd={() => {
                            setShake(false);
                        }}
                    >
                        <input placeholder='Enter encrypted password.' ref={inputRef} type={isHide ? "password" : 'text'} value={loginval} onChange={(e) => setloginval(e.target.value)} />
                        {
                            !isHide ? <svg onClick={() => setIsHide(!isHide)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.2148 3.47656L3.42723 20.2638" stroke="#919097" strokeWidth="2" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.0039 11.8919C21.0039 12.7326 20.2724 14.3276 18.4909 15.8313C16.7951 17.2626 14.4875 18.3001 12 18.3001C10.9647 18.3001 9.96061 18.1204 9.0188 17.8074L7.46826 19.3579C8.85023 19.944 10.3844 20.3001 12 20.3001C18.0773 20.3001 23.0039 15.2617 23.0039 11.8919C23.0039 10.3432 21.9631 8.31625 20.2483 6.5779L18.834 7.99215C19.4939 8.66274 20.0203 9.36826 20.3909 10.0352C20.8477 10.8571 21.0039 11.5022 21.0039 11.8919ZM16.0178 10.8084C16.0673 11.0628 16.0933 11.3257 16.0933 11.5946C16.0933 13.8551 14.2608 15.6875 12.0004 15.6875C11.7314 15.6875 11.4686 15.6616 11.2141 15.6121L16.0178 10.8084ZM15.0248 5.46641C14.0643 5.09982 13.0443 4.88916 12 4.88916C9.58109 4.88916 7.29218 6.01946 5.57216 7.5997C4.71989 8.38271 4.0528 9.23662 3.60902 10.0352C3.15222 10.8571 2.99609 11.5022 2.99609 11.8919C2.99609 12.6582 3.60385 14.0512 5.06063 15.4306L3.6463 16.8449C1.99428 15.2636 0.996094 13.4087 0.996094 11.8919C0.996094 8.52222 5.9227 2.88916 12 2.88916C13.622 2.88916 15.1621 3.29043 16.5484 3.94285L15.0248 5.46641ZM12.8921 7.59915L8.00491 12.4863C7.94113 12.1993 7.90749 11.9009 7.90749 11.5946C7.90749 9.33418 9.73994 7.50173 12.0004 7.50173C12.3066 7.50173 12.605 7.53537 12.8921 7.59915Z" fill="#919097" />
                            </svg>
                                :
                                <svg onClick={() => setIsHide(!isHide)} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M22.0039 11.8919C22.0039 13.1547 21.0223 15.0032 19.1359 16.5955C17.2924 18.1515 14.7631 19.3001 12 19.3001C9.2369 19.3001 6.7076 18.1515 4.86407 16.5955C2.97766 15.0032 1.99609 13.1547 1.99609 11.8919C1.99609 11.2759 2.22811 10.4614 2.73492 9.54942C3.23523 8.64916 3.97164 7.71218 4.89561 6.8633C6.75128 5.15844 9.27121 3.88916 12 3.88916C14.7288 3.88916 17.2487 5.15844 19.1044 6.8633C20.0283 7.71218 20.7647 8.64916 21.265 9.54942C21.7719 10.4614 22.0039 11.2759 22.0039 11.8919Z" stroke="#919097" strokeWidth="2" />
                                    <circle cx="12.0004" cy="11.5946" r="2.9955" stroke="#919097" strokeWidth="2" />
                                </svg>
                        }
                    </div>
                    <div className={style.sub} onClick={async () => {
                        if (loginval === '5c%7GjK*3h@8#NfD6$9L2vQzPm^RtUoEwSx!4bY1aIx0yT') {
                            setloginval('')
                            localSet('haslogin', true)
                            history.push('/upcoming')
                            window.location.reload();
                        } else {
                            setloginval('')
                            showToastMessage('Password error, please try again.')
                            setShake(true);
                            Haptics.vibrate()
                            return
                        }
                    }}>
                        Login
                    </div>
                </div>
            </IonContent>
        </IonPage >
    )
}
export default Login