import md5 from 'md5';
import { supabase_public, http, supabase } from './initSupabase';
import { showToastMessage } from 'components/GlobalLoading';


interface SignUp {
  userName: string;
  password: string;
}

export interface signUpWithEmailSchema extends SignUp {
  email: string;
}

type AuthProvider = 'google' | 'facebook' | 'twitch';

/**
 * sign up with email
 * @param params signUpWithEmailSchema
 * @returns {status, msg, data}
 */
export const signUpWithEmail = async (params: signUpWithEmailSchema) => {
  // check params
  if (!params.userName) {
    return { status: 400, msg: 'Missing username.', data: null };
  } else if (!params.password) {
    return { status: 400, msg: 'Missing password.', data: null };
  } else if (!params.email) {
    return { status: 400, msg: 'Missing email.', data: null };
  }

  // check duplicate username
  const response = await http.instance.get(`/checkUserNameExist?userName=${params.userName.trim()}`)
    .catch(function (error: { message: any; }) {
      return { status: 400, msg: error.message, data: null }
    })
  if (response.data.data.isExist === true) {
    return { status: 400, msg: 'Username already exists.', data: null }
  }

  // registered user
  const avatarURL = 'https://www.gravatar.com/';

  const { data, error } = await supabase_public.auth.signUp({
    email: params.email.trim(),
    password: params.password.trim(),
    options: {
      data: {
        user_name: params.userName,
        avatar:
          `${avatarURL}avatar/` + md5(params.email.trim()) + '?d=identicon',
        role: 'user'
      },
      emailRedirectTo: `${window.location.origin}/auth/callback`
    }
  });
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }
  return { status: 200, msg: 'Successfully registered.', data: data.user };
};


/**
 * social login
 * @param oauthType AuthProvider
 * @returns
 */
export const signInWithOAuth = async (oauthType: AuthProvider) => {
  let resultData = null
  if (oauthType === 'google') {
    const { data, error } = await supabase_public.auth.signInWithOAuth({
      provider: oauthType,
      options: {
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    })
    if (error) {
      return { status: 400, msg: error.message, data: null }
    }
    resultData = data
  } else {
    const { data, error } = await supabase_public.auth.signInWithOAuth({
      provider: oauthType,
    })
    if (error) {
      return { status: 400, msg: error.message, data: null }
    }
    resultData = data
  }
  return { stauts: 200, msg: 'Account successfully sign in.', data: resultData }
}

/**
 * social sign out
 * @returns {status, msg}
 */
export const signOutWithOAuth = async () => {
  const { error } = await supabase_public.auth.signOut();
  return { status: 200, msg: error};
}

/**
 * sign in
 * @param email
 * @param pwd
 * @returns {status, msg, data}
 */
export const signIn = async (email: string, pwd: string) => {
  // check params
  if (!email) {
    throw new Error('Missing email.');
    // return { status: 400, msg: 'Missing email.', data: null };
  } else if (!pwd) {
    throw new Error('Missing password.');
    // return { status: 400, msg: 'Missing password', data: null };
  }

  const { error } = await supabase_public.auth.signInWithPassword({
    email: email,
    password: pwd
  });
  if (error) {
    throw new Error(error.message);
    // return { status: 400, msg: error.message, data: null };
  }
  await http.getInstance()
  showToastMessage('Account successfully sign in.')
  // return { status: 200, msg: 'Account successfully sign in.', data: data };
};


/**
 * sign out
 * @returns {status, msg, data}
 */
export const signOut = async () => {
  const { error } = await supabase_public.auth.signOut();
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }

  const result = await supabase_public.auth.signOut();
  if (result.error) {
    return { status: 400, msg: result.error.message, data: null };
  }
  supabase.removeAllChannels()
};


/**
 * invoke the supabase edge function, get account jwt
 * @returns edge function return jwt
 */
export const externalJWT = async () => {
  const { data, error } = await supabase_public.functions.invoke(
    'external_jwt',
//     { body: { external_url: window.location.origin } }
       { body: { external_url: process.env.REACT_APP_FRONTEND_BASE_URL } }
  );
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }
  return { status: 200, msg: null, data: data };
};
