import imageCompression from 'browser-image-compression';
import { supabase } from './initSupabase';

/**
 * compress avatar image
 * @param file
 * @returns
 */
export const compressAvatar = async (file: File) => {
  if (!file) {
    throw new Error('Missing avatar image.')
  }
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 80,
    useWebWorker: true
  };
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile ;
  } catch (error:any) {
    throw new Error(error.message)
  }
};

/**
 * supabse upload file and return file url
 * @param bucketName string
 * @param filePath string
 * @param file File
 * @returns file url
 */
export const uploadFile = async (
  bucketName: string,
  filePath: string,
  file: File
) => {
  const { data, error } = await supabase.storage
    .from(bucketName)
    .upload(filePath, file, {
      cacheControl: '3600',
      upsert: true
    });
  if (error) {
    throw new Error(error.message);
  }

  // get avatar url
  const result = await supabase.storage
    .from(bucketName)
    .getPublicUrl(data.path);
  if (!result.data.publicUrl) {
    throw new Error('File does not exist.');
  } else {
    return result.data.publicUrl;
  }
};
