import { setupIonicReact } from "@ionic/react";

import { Provider } from "@DataAssetStore/chatcomponent";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./theme/variables.css";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./style/global.css";



import MainIonApp from "MainIonApp";
import { supabase, supabase_public } from "api/initSupabase";
import './style/main.scss';
setupIonicReact();
const App: React.FC = () => {
  return (
    <div className="alexBlocks">
      <Provider supabase_public={supabase_public} supabase_public_chat={supabase} app_name={'OIAM'} isChatWithAi={false} app_version={'dev'}>
        <MainIonApp />
      </Provider>
    </div >
  )
}
export default App;


/* const RouteGuard = ({ path, children }: { path: string, children: ReactNode }) => {
  const haslogin = localGet('haslogin')
  const userInfo = localGet('user_info')
  return <Route path={path} render={() => (haslogin ? userInfo ? children : <Redirect to="/" /> : < Redirect to="/login" />
  )}>
  </Route >
} */