import { IonLoading, IonToast } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import { useEffect } from "react";
import { useStore } from '../store';
import useToast from "./useToast";

export const GLobalLoading = () => {
    const { showToast, showToastMessage, message } = useToast()
    const { isLoading, setGlobalState } = useStore();
    useEffect(() => {
        PubSub.subscribe('loading', function (msg: any, data: any) {
            setGlobalState("isLoading",data)
            // updateContext('isLoading',data)
        })

        PubSub.subscribe('toast', function (msg: any, data: any) {
            showToastMessage(data)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <>
            <IonLoading
                cssClass="my-custom-class"
                isOpen={isLoading}
                onDidDismiss={() => setGlobalState("isLoading",false)}
                message={'Please wait...'}
                duration={10000000}
                mode="ios"
                spinner="crescent"
            />
            <IonToast isOpen={showToast} message={message} duration={2000} position={'top'} icon={alertCircleOutline} />

        </>
    )
}
export const setShowLoading = (data: boolean) => {
    PubSub.publish('loading', data)
}
export const showToastMessage = (data: string) => {
    PubSub.publish('toast', data)
}