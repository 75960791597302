import { IonContent, IonModal } from "@ionic/react";
import { User } from "@supabase/supabase-js";
import { AccountParams } from "api/account";
import { SVG } from "components/auth/svg";
import { usePreview, useUser } from "hooks";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { tokenApi } from "../../../api";
import { http } from "../../../api/initSupabase";
import { setShowLoading, showToastMessage } from "../../../components/GlobalLoading";
import * as Database from '../../../db';
import { useStore } from "../../../store";
import style from "../../../style/Account.module.css";
import { localGet } from "../../../utils/localStorage";

interface Props {
    getdatas: any;
    walletInfo: any;
    userInfo: User
    user?: { email: string, nickname: string, avatar?: string }
}


const UserProfileModal: React.FC<Props> = ({
    user,
    getdatas,
    walletInfo,
    userInfo
}) => {
    // edit profile
    const { updateProfile, getProfile } = useUser()
    const [avatarFile, setAvatarFile] = useState<File>()
    const [previewAvatarUrl, getPreviewAvatarUrl] = usePreview()
    const { isEditModalOpen, changeModalState, closeAllModalStates } = useStore();
    const [toEditUserName, setToEditUserName] = useState<string>('')
    // const { user, loginWithRedirect } = useAuth0()
    const inputRef = useRef<HTMLInputElement>(null);
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        const type = ['image/jpeg', 'image/png', 'image/jpg']
        try {
            const file = event.target.files[0];
            if (!type.includes(file?.type)) {
                setShowLoading(false)
                showToastMessage('The type of file uploaded is not an image.')
                return
            }
            if (file?.size > 100000) {
                setShowLoading(false)
                showToastMessage('The uploaded file exceeds the size limit.')
                return
            }
            setAvatarFile(file)
            getPreviewAvatarUrl(file)

        } catch (err) {
            setShowLoading(false)
            showToastMessage('Upload failed. Please try again.')
        }
    }
    const [nameExist, setNameExist] = useState(false)
    const fetchData = async () => {
        if (toEditUserName === '' || toEditUserName?.trim() === user?.nickname) {
            setNameExist(false)
            return
        }
        const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
        let res = false
        try {
            const response = await http.instance.get(`${baseUrl}/checkUserNameExist?userName=${toEditUserName.trim()}`);
            const responseJson = await response.data;
            // console.log('responseJsonresponseJson', responseJson.data.isExist);
            setNameExist(responseJson.data.isExist)
            res = responseJson.data.isExist
        } catch (error) {
            // throw new Error(error)
        }
        return res
    };
    useEffect(() => {
        const delayTimer = setTimeout(() => {

            fetchData();
        }, 500);

        return () => clearTimeout(delayTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toEditUserName]);

    const onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.trim().length > 16) {
            showToastMessage('The username must not exceed sixteen strings.')
            setToEditUserName(e.target.value.trim().slice(0, 16))
            return
        }
        setToEditUserName(e.target.value)
    }
    const onUserKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter') {
            inputElement?.blur();
        }
    }
    const onSaveUserInfo = async () => {
        setShowLoading(true)
        const user_info = await getProfile();
        if (await fetchData()) {
            showToastMessage('The name already exists.')
            setShowLoading(false)
            return;
        }
        if (toEditUserName === undefined || toEditUserName === '') {
            showToastMessage('Please enter your nickname.')
            setShowLoading(false)
            return;
        }
        if (toEditUserName.length > 16) {
            showToastMessage('The name cannot be longer than 16 characters.')
            setShowLoading(false)
            return;
        }
        if (navigator.onLine === false) {
            showToastMessage('Please check your network and try later.')
            setShowLoading(false)
            return
        }
        // if (toEditUserName.trim() === user_info?.user_metadata.user_name) {
        //     changeModalState('isEditModalOpen', false)
        //     setShowLoading(false)
        //     return
        // }
        try {
            const userData: { nickname?: string, avatarURL?: string } = { avatarURL: '' }
            const updateProfileForm: Omit<AccountParams, 'id'> = {}
            if (toEditUserName.trim() !== user_info?.user_metadata.user_name) {
                updateProfileForm.userName = toEditUserName.trim()
            }
            if (avatarFile) {
                updateProfileForm.avatar = avatarFile
            }
            const user = await updateProfile(updateProfileForm)
            userData.avatarURL = user?.user_metadata.avatar
            userData.nickname = toEditUserName.trim()

            await tokenApi('/updateUser', userData)
            // await updateAccount({ id: localGet('user_info')?.id, userName: userData?.nickname, avatar: avatarFile })
            // await getProfile()
            const db = await Database.get();
            await db.account.findOne(
                {
                    selector: {
                        walletAddress: localGet('authAddress')
                    }
                }
            ).exec().then(async (res: any) => {
                await res.patch({
                    userName: toEditUserName.trim()
                })
            })
            closeAllModalStates(false)
            showToastMessage('Successfully updated account information.')
        }
        catch (error: any) {
            showToastMessage(error.message)
        }
        finally {
            setShowLoading(false)
        }



        // await updateAccount()

        // await updateUser(user?.sub, {``
        //     "user_name": toEditUserName.trim(),
        // })
        /*         localSet('usename', {
                    ...localGet('usename'), user_name: toEditUserName.trim()
                }) */
        // await updateChatUser(localGet('chatProps')[0], localGet('chatProps')[1], 'name', toEditUserName)

        // await getdatas()
        // await disconnect()
        // setShowLoading(false)
        // updateContext('copyKeyModal', false)

    }

    const handleCopyKey = () => {
        console.log(walletInfo.privateKey)
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(walletInfo.privateKey)
        } else {
            let copyDom = document.createElement('input')
            copyDom.setAttribute(
                'value',
                (walletInfo.privateKey)
            )
            document.body.appendChild(copyDom)
            copyDom.select()
            document.execCommand('Copy')
            document.body.removeChild(copyDom)
        }
        showToastMessage(`Copy:${walletInfo?.privateKey.slice(0, 15)}...${walletInfo?.privateKey.slice(-15)}`)
    }
    const inputElement = inputRef.current;
    useEffect(() => {
        const handleFocus = () => {
            setIsKeyboardOpen(true);
        };

        const handleBlur = () => {
            setIsKeyboardOpen(false);
        };
        if (inputElement) {
            inputElement?.addEventListener('focus', handleFocus);
            inputElement?.addEventListener('blur', handleBlur);
        }

        return () => {
            if (inputElement) {
                inputElement?.addEventListener('focus', handleFocus);
                inputElement?.addEventListener('blur', handleBlur);
            }
        };
    }, [inputElement]);
    const handleScroll = () => {
        if (isKeyboardOpen) {
            inputElement?.blur();
        }
    }

    return (
        <IonModal isOpen={isEditModalOpen}
            className={style.copyKeyModal}
            mode="ios"
            onDidPresent={() => {
                setToEditUserName(user?.nickname ?? '')
            }}
            onDidDismiss={() => changeModalState('isEditModalOpen', false)}>
            <IonContent scrollEvents={true} onIonScrollStart={handleScroll}>
                <div className={style.copyKeyModalMain}>
                    <div className={style.top} >
                        <div onClick={() => changeModalState('isEditModalOpen', false)}>
                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 14L2 8L8 2" stroke="white" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                        </div>
                        <span>Edit</span>
                        <div
                            style={{ paddingRight: '16px', justifyContent: 'flex-end' }}
                            onClick={onSaveUserInfo}>
                            <div className={style.save}>
                                <span>Save</span>
                            </div>
                        </div>
                    </div>
                    <div className={style.main}>
                        <label className={style.avatar} htmlFor='avatarUpload'>
                            <img src={
                                // `${userInfo?.auth0AvatarURL}?${new Date().getTime()}`
                                `${previewAvatarUrl ?? `${user?.avatar}`}`
                            } alt="" />
                            <EditSVG />
                        </label>
                        <input style={{ display: 'none' }} type="file" accept="image/*" id="avatarUpload"
                            onChange={(e) => { handleFileUpload(e) }}
                        />
                        <div className={style.name}>{userInfo?.user_metadata.username}</div>
                        <div className={style.email}>
                            <p>Email Address</p>
                            <input type="text" value={userInfo?.email} disabled={true} />
                        </div>
                        <div className={nameExist ? `${style.email} ${style.nameExist}` : style.email}>
                            <p>User Name</p>
                            <input type="text" ref={inputRef} value={toEditUserName} onChange={onUserNameChange} onKeyDown={onUserKeyDown} />
                            <div style={{ position: 'absolute', bottom: '-16px', fontSize: '12px', color: 'red', display: nameExist ? 'block' : 'none' }}>The name already exists.</div>
                        </div>
                        <div className={style.realName} onClick={() => changeModalState('isVerifyModalOpen', true)}>
                            Personal Identification Verification
                            <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.41455 0.828247L10.0685 9.48207L1.41455 18.136" stroke="#939393" strokeWidth="2" />
                            </svg>
                        </div>

                        <div className={style.privatekey}>
                            <p>Private Key</p>
                            <div className={style.btn} style={walletInfo === null ? {} : { display: 'none' }}
                                onClick={() => {
                                    changeModalState('isAddKeyModalOpen', true)
                                    // setAddKeyModal(true)
                                }}>
                                <KeySvg />
                                Add Key
                            </div>
                            <div className={style.hasKey} style={walletInfo === null ? { display: 'none' } : {}}>
                                <div className={style.left}>
                                    <div>
                                        <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="21.3357" cy="21" r="21" fill="white" fillOpacity="0.1" />
                                            <path d="M18.5857 20.625C17.9607 19.625 17.7107 18.5 17.7107 17.25C17.7107 13.75 20.4607 11 23.9607 11C27.4607 11 30.2107 13.75 30.2107 17.25C30.2107 20.75 27.4607 23.5 23.9607 23.5C22.5857 23.5 21.4607 23.125 20.3357 22.375L17.4607 25.25L18.8357 26.625L17.0857 28.375L15.7107 27L14.8357 27.875L16.2107 29.25L14.4607 31L11.3357 27.875L18.5857 20.625ZM23.9607 13.5C21.8357 13.5 20.2107 15.125 20.2107 17.25C20.2107 19.375 21.8357 21 23.9607 21C26.0857 21 27.7107 19.375 27.7107 17.25C27.7107 15.125 25.9607 13.5 23.9607 13.5Z" fill="#9C94FF" />
                                        </svg>
                                    </div>
                                    <p>{walletInfo?.privateKey.slice(0, 8)}...{walletInfo?.privateKey.slice(-8)}</p>
                                </div>
                                <CopySvg onClick={handleCopyKey} width="25" height="24" viewBox="0 0 25 24" fill="none" />
                            </div>
                        </div>

                    </div>

                </div>
            </IonContent>
        </IonModal >
    )
}
export default UserProfileModal


const EditSVG: FC<SVG> = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="24" height="24" rx="12" fill="#9C94FF" />
        <mask id="path-2-outside-1_337_858" maskUnits="userSpaceOnUse" x="6.51043" y="4.69643" width="13.2201" height="14.117" fill="black">
            <rect fill="white" x="6.51043" y="4.69643" width="13.2201" height="14.117" />
            <path d="M11.7478 16.6416L18.2534 10.3109C18.671 9.90554 18.6791 9.23115 18.2736 8.81537L16.3079 6.79223C15.9026 6.3747 15.2282 6.36656 14.8124 6.77206L8.30496 13.1026L7.60417 16.7515C7.57852 16.8854 7.61976 17.0242 7.71464 17.1221C7.80953 17.2201 7.9468 17.2656 8.0819 17.2435L11.7478 16.6416L11.7478 16.6416ZM14.2129 8.53462L16.4766 10.8601L11.765 15.4453L9.50146 13.1181L14.2129 8.53462ZM15.7002 7.38259L17.6678 9.40414C17.7483 9.48653 17.7455 9.6227 17.6631 9.70317L17.0787 10.2728L14.8168 7.94745L15.4012 7.37784C15.4836 7.29737 15.6215 7.30038 15.7002 7.38259ZM8.54023 16.3176L9.01762 13.8358L11.0352 15.9084L8.54024 16.3176L8.54023 16.3176Z" />
        </mask>
        <path d="M11.7478 16.6416L18.2534 10.3109C18.671 9.90554 18.6791 9.23115 18.2736 8.81537L16.3079 6.79223C15.9026 6.3747 15.2282 6.36656 14.8124 6.77206L8.30496 13.1026L7.60417 16.7515C7.57852 16.8854 7.61976 17.0242 7.71464 17.1221C7.80953 17.2201 7.9468 17.2656 8.0819 17.2435L11.7478 16.6416L11.7478 16.6416ZM14.2129 8.53462L16.4766 10.8601L11.765 15.4453L9.50146 13.1181L14.2129 8.53462ZM15.7002 7.38259L17.6678 9.40414C17.7483 9.48653 17.7455 9.6227 17.6631 9.70317L17.0787 10.2728L14.8168 7.94745L15.4012 7.37784C15.4836 7.29737 15.6215 7.30038 15.7002 7.38259ZM8.54023 16.3176L9.01762 13.8358L11.0352 15.9084L8.54024 16.3176L8.54023 16.3176Z" fill="#101A25" />
        <path d="M11.7478 16.6416L18.2534 10.3109C18.671 9.90554 18.6791 9.23115 18.2736 8.81537L16.3079 6.79223C15.9026 6.3747 15.2282 6.36656 14.8124 6.77206L8.30496 13.1026L7.60417 16.7515C7.57852 16.8854 7.61976 17.0242 7.71464 17.1221C7.80953 17.2201 7.9468 17.2656 8.0819 17.2435L11.7478 16.6416L11.7478 16.6416ZM14.2129 8.53462L16.4766 10.8601L11.765 15.4453L9.50146 13.1181L14.2129 8.53462ZM15.7002 7.38259L17.6678 9.40414C17.7483 9.48653 17.7455 9.6227 17.6631 9.70317L17.0787 10.2728L14.8168 7.94745L15.4012 7.37784C15.4836 7.29737 15.6215 7.30038 15.7002 7.38259ZM8.54023 16.3176L9.01762 13.8358L11.0352 15.9084L8.54024 16.3176L8.54023 16.3176Z" stroke="#101A25" strokeWidth="0.4" strokeLinejoin="round" mask="url(#path-2-outside-1_337_858)" />
    </svg>
)

const KeySvg: FC<SVG> = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 5V19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.5 12H19.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const CopySvg: FC<SVG> = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1736 16.7653C17.933 16.7653 17.7023 16.6697 17.5321 16.4995C17.362 16.3294 17.2664 16.0987 17.2664 15.8581C17.2664 15.6174 17.362 15.3867 17.5321 15.2166C17.7023 15.0464 17.933 14.9509 18.1736 14.9509C18.4063 14.9509 18.6294 14.8584 18.7939 14.6939C18.9584 14.5294 19.0508 14.3063 19.0508 14.0737V6.21125C19.0508 5.97861 18.9584 5.75549 18.7939 5.59098C18.6294 5.42647 18.4063 5.33405 18.1736 5.33405H10.3112C10.0786 5.33405 9.85546 5.42647 9.69096 5.59098C9.52645 5.75549 9.43403 5.97861 9.43403 6.21125C9.43403 6.45186 9.33845 6.68261 9.16832 6.85274C8.99818 7.02287 8.76743 7.11845 8.52683 7.11845C8.28622 7.11845 8.05547 7.02287 7.88534 6.85274C7.71521 6.68261 7.61963 6.45186 7.61963 6.21125C7.62121 5.49788 7.9053 4.81419 8.40973 4.30976C8.91416 3.80533 9.59786 3.52124 10.3112 3.51965H18.1736C18.887 3.52124 19.5707 3.80533 20.0751 4.30976C20.5796 4.81419 20.8636 5.49788 20.8652 6.21125V14.0737C20.8636 14.787 20.5796 15.4707 20.0751 15.9752C19.5707 16.4796 18.887 16.7637 18.1736 16.7653Z" fill="#939393" />
        <path d="M14.1514 20.6976H6.62137C6.24791 20.6981 5.87803 20.6249 5.53292 20.4822C5.1878 20.3395 4.87422 20.1301 4.61015 19.866C4.34608 19.602 4.13669 19.2884 3.994 18.9433C3.8513 18.5981 3.77809 18.2283 3.77857 17.8548V10.2948C3.77794 9.92125 3.85103 9.55124 3.99365 9.20599C4.13628 8.86073 4.34563 8.54701 4.60972 8.28281C4.87381 8.01861 5.18744 7.80912 5.53264 7.66635C5.87783 7.52358 6.24781 7.45033 6.62137 7.45081H14.1514C14.5248 7.45033 14.8947 7.52354 15.2398 7.66624C15.5849 7.80893 15.8985 8.01832 16.1626 8.28239C16.4267 8.54646 16.636 8.86004 16.7787 9.20516C16.9214 9.55027 16.9946 9.92015 16.9942 10.2936V17.8536C17.0242 19.4268 15.7246 20.6976 14.1514 20.6976ZM6.62137 9.26521C6.48601 9.26409 6.35177 9.28992 6.2265 9.34121C6.10122 9.3925 5.9874 9.4682 5.89168 9.56392C5.79597 9.65964 5.72026 9.77346 5.66897 9.89874C5.61768 10.024 5.59185 10.1582 5.59297 10.2936V17.8536C5.59185 17.989 5.61768 18.1232 5.66897 18.2485C5.72026 18.3738 5.79597 18.4876 5.89168 18.5833C5.9874 18.679 6.10122 18.7547 6.2265 18.806C6.35177 18.8573 6.48601 18.8831 6.62137 18.882H14.1514C14.2867 18.8831 14.421 18.8573 14.5462 18.806C14.6715 18.7547 14.7853 18.679 14.8811 18.5833C14.9768 18.4876 15.0525 18.3738 15.1038 18.2485C15.1551 18.1232 15.1809 17.989 15.1798 17.8536V10.2936C15.1809 10.1582 15.1551 10.024 15.1038 9.89874C15.0525 9.77346 14.9768 9.65964 14.8811 9.56392C14.7853 9.4682 14.6715 9.3925 14.5462 9.34121C14.421 9.28992 14.2867 9.26409 14.1514 9.26521H6.62137Z" fill="#939393" />
    </svg>
)

