import { useSupabase } from '@DataAssetStore/chatcomponent';
import { RealtimeChannel } from '@supabase/supabase-js';
import {
  createPublicSubscription,
  createSubscription,
  getIsInvitee,
  getPublicChannels,
  getUserInfo
} from 'api/chat';
import { setShowLoading, showToastMessage } from 'components/GlobalLoading';
import { useEffect, useRef, useState } from 'react';
import { useStore } from 'store';
import { ChatTables } from 'typings/supabase';
import useChannelSubscribe from './common/useChannelSubscribe';
import { ChatMessage } from './useCommonChannels';
import useUser from './useUser';
interface RoomInvite {
  accept_reject_time: string | null;
  id: string;
  invite_status: string;
  invite_time: string;
  invitee_id: string;
  inviter_id: string;
  room_id: string;
}
interface InviteInfo {
  id: string;
  avatar: string;
  name: string;
  isGroup: boolean;
}

const useGlobalInvite = () => {
  const [inviteInfo, setInviteInfo] = useState<InviteInfo | null>(null);
  const supabaseId = useRef<string>('');
  const { setNewMessage, updateChatRoomsMessage } =
    useStore();
  const { getChatroom } = useSupabase();
  const [inviteeSubscribable, setInviteeSubscribable] =
    useState<RealtimeChannel>();
  const [inviterSubscribable, setInviterSubscribable] =
    useState<RealtimeChannel>();
  const [roomMessagesSubscribable, setRoomMessagesSubscribable] =
    useState<RealtimeChannel>();
  const [roomMessagesPublicSubscribable, setRoomMessagesPublicSubscribable] =
    useState<RealtimeChannel>();

  const { getProfile } = useUser();
  useChannelSubscribe({ realtimeChannel: inviteeSubscribable });
  useChannelSubscribe({ realtimeChannel: inviterSubscribable });
  useChannelSubscribe({ realtimeChannel: roomMessagesSubscribable });
  useChannelSubscribe({ realtimeChannel: roomMessagesPublicSubscribable });
  const onCreateInviteeSubscribable = async (name?: string) => {
    const user = await getProfile();
    if (!user) return;
    const id = `invitee-${user.id}`;
    const _Invite = createSubscription(name ?? id);
    setInviteeSubscribable(_Invite);
    _Invite?.on<RoomInvite>(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public_chat',
        table: 'room_invites',
        filter: `invitee_id=eq.${user.id}`
      },
      async ({ new: _new }) => {
        try {
          /*  console.log(localGet('invitesCount'))
          const { data } = await getUserInfo(_new.inviter_id);
          data &&
            setInviteInfo({
              ...data,
              name: data.user_name,
              id: _new.id,
              isGroup: localGet('invitesCount')>1
            }); */
        } catch (error) {
          console.log(error);
        }
      }
    );
  };
  const onCreateInviterSubscribable = async (name?: string) => {
    const user = await getProfile();
    if (!user) return;
    const id = `inviter-${user.id}`;
    const _Invite = createSubscription(id ?? name);
    setInviterSubscribable(_Invite);
    _Invite?.on<RoomInvite>(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public_chat',
        table: 'room_invites',
        filter: `inviter_id=eq.${user.id}`
      },
      async ({ new: _new }) => {
        // console.log({_new});
        const { data } = await getUserInfo(_new.invitee_id);
        showToastMessage(
          `${data?.user_name} ${_new.invite_status} your invitation.`
        );
        getChatroom();
        try {
        } catch (error) {
          console.log(error);
        }
      }
    );
  };
  const getCommonChannelsIds = async () => {
    try {
      setShowLoading(true);
      const { data } = await getPublicChannels();
      return (data && data.map((_) => _.room_id)) ?? [];
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };
  const onCreateRoomMessageSubscribable = async (name?: string) => {
    const user = await getProfile();
    const channelIds = await getCommonChannelsIds();
    if (!channelIds) return;
    if (!user) return;
    const id = user.id;
    const _Invite = createSubscription(id ?? name);
    setRoomMessagesSubscribable(_Invite);
    _Invite?.on<ChatTables<'room_messages'>>(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public_chat',
        table: 'room_messages'
      },
      async ({ new: message }) => {
        if (!channelIds.includes(message.message_id)) {
          if (!message.user_id) return;
          const { data } = await getUserInfo(message.user_id);
          if (!data) return;
          setNewMessage({
            ...message,
            user_name: data.user_name,
            avatar: data.avatar,
            user_emotes: []
          });
          updateChatRoomsMessage({
            roomID: message.room_id,
            message_time: message.message_time,
            latest_message: message.message
          });
        }
        try {
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  const onCreateRoomSubscribable = async (name?: string) => {
    const user = await getProfile();
    if (!user) return;
    const id = user.id;
    const _Invite = createPublicSubscription(id ?? name);
    setRoomMessagesPublicSubscribable(_Invite);
    _Invite?.on<ChatMessage>(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'profiles'
      },
      async () => {
        getChatroom();
      }
    );
  };
  useEffect(() => {
    PubSub.subscribe('invites', async (key: any, _data: any) => {
      try {
        const isInvitee = await getIsInvitee(_data.supabaseId);
        if (isInvitee && _data.supabaseId !== supabaseId.current) {
          supabaseId.current = _data.supabaseId;
          setInviteInfo({
            id: _data.supabaseId,
            avatar: _data.groupAvatar ?? _data.initiator.avatarURL,
            name: _data.groupName ?? _data.initiator.nickname,
            isGroup: !!_data.groupName
          });
        } else {
          setInviteInfo(null);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }, []);
  return {
    inviteInfo,
    onCreateInviteeSubscribable,
    onCreateInviterSubscribable,
    onCreateRoomMessageSubscribable,
    onCreateRoomSubscribable
  };
};

export default useGlobalInvite;
