export const checkpointSchema = {
    title: 'checkpointSchema',
    description: 'checkpoint',
    version: 3,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        checkpoint: {
            type: 'object',
        }
    },

};