export const hoursUntilTomorrow = () => {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(now.getDate() + 1);

  tomorrow.setHours(0, 0, 0, 0);

  const msUntilTomorrow = tomorrow.getTime() - now.getTime();

  const hoursUntilTomorrow = msUntilTomorrow / (3600 * 1000);

  return Math.floor(hoursUntilTomorrow);
};
