import { IonContent, IonModal } from "@ionic/react"
import { FC } from "react"
import { CloseModalSVG, LockSVG } from "./svg"
import { useStore } from '../../store'
const ForgotPasswordModal: FC = () => {
  const { isForgotPasswordModalOpen , changeModalState } = useStore()
    return (
      <IonModal className='forgot-password' isOpen={isForgotPasswordModalOpen}>
        <IonContent>
          <div className="forgot-password-modal">
            <div className='forgot-password-modal__header'>
              <span>Forgot Password</span>
              <CloseModalSVG onClick={()=>changeModalState('isForgotPasswordModalOpen',false)} />
            </div>
            <div className="forgot-password-modal__content">
              <div className="forgot-password-modal__content--icon">
                <LockSVG />
              </div>
              <p>Enter the email address and we'll send you a reset link.</p>
              <form className='auth-form'>
                <div className="auth-form-group">
                  <div className="auth-form-group-control">
                    <label>Email Address</label>
                    <input name='email' />
                  </div>
                  <p></p>
                </div>
              </form>
            </div>
          </div>
        </IonContent>
      </IonModal>
    )
  }

export default ForgotPasswordModal
