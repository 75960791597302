
import { IonModal, IonContent, IonSelect, IonSelectOption } from "@ionic/react";
import style from "../../../style/Account.module.css";
import { useStore } from "../../../store";
import SVGIcon from "components/SvgComponent";

const AddBankAccountModal: React.FC = () => {
    const { isAddBankModalOpen,changeModalState } = useStore();
    return (
        <IonModal isOpen={isAddBankModalOpen} mode="ios" className={style.addbankmodal} onDidDismiss={() => changeModalState('isAddBankModalOpen', false)}>
            <IonContent>
                <div className={style.addbankbox}>
                    <div className={style.withtop} onClick={() => changeModalState('isAddBankModalOpen', false)}>
                        <div>
                            <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                        </div>
                        <p>Add a Bank Account</p>
                        <div className={style.managesvg}></div>
                    </div>

                    <ul className={style.addbankul}>
                        <li className={style.holder}>
                            <input type="text" />
                            <span>Account Holder</span>
                            <p>Should be your name on the account</p>
                        </li>

                        <li className={style.holder}>
                            <IonSelect className={style.select} mode="ios">
                                <IonSelectOption value="America">America</IonSelectOption>
                                <IonSelectOption value="Canada">Canada</IonSelectOption>
                                <IonSelectOption value="Malaysia">Malaysia</IonSelectOption>
                            </IonSelect>
                            <span>Country</span>
                        </li>

                        <li className={style.holder}>
                            <input type="text" />
                            <span>Institution Number</span>
                        </li>

                        <li className={style.holder}>
                            <input type="text" />
                            <span>Transit Number</span>
                        </li>

                        <li className={style.holder}>
                            <input type="text" />
                            <span>Account Number</span>
                        </li>

                        <li className={style.holder}>
                            <IonSelect className={style.select} mode="ios">
                                <IonSelectOption value="apples">Saving</IonSelectOption>
                                <IonSelectOption value="oranges">Saving</IonSelectOption>
                                <IonSelectOption value="bananas">Saving</IonSelectOption>
                            </IonSelect>
                            <span>Account Type</span>
                        </li>
                    </ul>
                    <div className={style.withbtn}>
                        <p>Save</p>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default AddBankAccountModal