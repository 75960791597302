import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { Countup } from 'count-up-react';
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { filterDraws } from '../db/schema/draws';
import img3 from '../image//billion/Option=Icon0.png';
import img from '../image//billion/Option=Icon01.png';
import img2 from '../image//billion/Option=Icon04.png';
import img1 from "../image/upcoming/1.png";
import dice from '../image/upcoming/dice.gif';
import back from "../image/upcoming/ill.png";
import { useStore } from '../store';
import "../style/Countup.css";
import style from "../style/Upcoming.module.css";
import { localGet, localSet } from "../utils/localStorage";



// import { useAuth0 } from "@auth0/auth0-react";

// import { Carousel } from 'antd'
import SVGIcon from "components/SvgComponent";
import { useLocalStorage } from "hooks";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import { showToastMessage } from "../components/GlobalLoading";
import { getAssets } from "../db/schema/assets";
import { getAllImages } from "../db/schema/images";
import { formatPrize } from "../utils/formatPrize";

const Upcoming: React.FC = () => {
  const [drawImages, setDrawImages] = useState<any>()
  useEffect(() => {
    const setimages = async () => {
      setDrawImages(await getAllImages())
    }
    setimages()
    //eslint-disable-next-line
  }, [localGet('images')])
  const { changeModalState } = useStore();




  const addCommas = (num: any) => num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  // const { user, isAuthenticated } = useAuth0();
  const [userInfo] = useLocalStorage('user_info')
  const history = useHistory()



  const back1 = require('../image/upcoming/back1.png')
  const back2 = require('../image/upcoming/back2.png')
  const back3 = require('../image/upcoming/back3.png')
  // const car = require('../image/upcoming/car.png')
  // const loginvideo = require('../image/upcoming/mp4/login.mp4')
  const [jumppath, setJumpPath] = useState('upcoming')

  const [list, setlist]: any = useState([])

  const [timeSortParame, setTimeSortParame] = useState('')
  const [prizeSortParame, setPrizeSortParame] = useState('')
  const [confirmlogin, setConfirmLogin] = useState(false)
  const video1Ref = useRef<any>(null)
  const videoRef = useRef<any>(null)

  async function gets() {
    let ress = await filterDraws('upcoming', '', 'desc', 10, 1).then()
    localStorage.removeItem('pageSize')
    localStorage.removeItem('upcomingSort')
    localStorage.removeItem('upcomingSortParame')
    setlist(ress)
    setLazyListPageSize(10)
    PubSub.subscribe('syncData', function (msg, searchName) {
      if (searchName.getlist !== undefined && searchName.getlist !== null) {
        const getlist = searchName.getlist;
        const win = getlist.filter((item: any) => item.winnerWallet !== '' && item.winnerWallet !== localGet('authAddress'))
        if (win.length > 0) {
          updateList()
        }
        setlist(getlist)
      }
    })
  }

  function updateList() {
    setTimeout(async () => {
      // let ress;
      // ress = await filterDraws('upcoming', '', 'desc', localGet('pageSize'), 1).then()
      let ress;
      const upcomingSort = localGet('upcomingSort');
      const upcomingSortParame = localGet('upcomingSortParame');
      if (upcomingSort === null) {
        ress = await filterDraws('upcoming', '', 'desc', localGet('pageSize'), 1).then()
      } else if (upcomingSort === 'time') {
        ress = await filterDraws('upcoming', upcomingSortParame, '', localGet('pageSize'), 1).then()
      } else if (upcomingSort === "prize") {
        ress = await filterDraws('upcoming', '', upcomingSortParame, localGet('pageSize'), 1).then()
      }
      setlist(ress)
    }, 5000);
  }

  useEffect(() => {
    PubSub.subscribe('closeWinModal', function (msg, searchName) {
      if (searchName === true) {
        updateList()
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localGet('buyTicket')])

  async function listSort(type: string, sort: string) {
    let ress;
    if (type === "time") {
      ress = await filterDraws('upcoming', sort, '', 10, 1).then()
    } else if (type === "prize") {
      ress = await filterDraws('upcoming', '', sort, 10, 1).then()
    }
    setlist(ress)
    setLazyListPageSize(10)
  }
  // const [lazyListNum, setLazyListNum] = useState<number>(2)
  const [lazyListPageSize, setLazyListPageSize] = useState<number>(10)
  const [isClickSort, setIsClickSort] = useState('')

  const generateItems = async () => {
    let ress;
    if (isClickSort === '') {
      ress = await filterDraws('upcoming', '', 'desc', 10, lazyListPageSize / 10 + 1).then();
    } else if (isClickSort === 'time') {
      ress = await filterDraws('upcoming', timeSortParame, '', 10, lazyListPageSize / 10 + 1).then()
    } else if (isClickSort === "prize") {
      ress = await filterDraws('upcoming', '', prizeSortParame, 10, lazyListPageSize / 10 + 1).then()
    }
    if (ress.length === 0) {
    } else {
      let arr = [...list]
      arr.push(...ress)
      setlist(arr)
      setLazyListPageSize(lazyListPageSize + 10)
      localSet('pageSize', lazyListPageSize + 10)
    }

  };
  useEffect(() => {
    if (confirmlogin && userInfo) {
      if (video1Ref.current) {
        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        });
        video1Ref.current.children[0]?.children[3]?.children[0]?.dispatchEvent(clickEvent)
      }

    }
  }, [userInfo, confirmlogin])
  useEffect(() => {
    console.log(userInfo)
    if (userInfo) {
      setTimeout(() => {
        if (localGet('reflesh') === true) {
          if (localGet('login') === true) {
          } else {
            // video1Ref.current.play()
            setConfirmLogin(true);
            localSet('login', true);
          }
        }
      }, 2000);


    }
    setJumpPath('upcoming')
    getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localGet('auth0_user'), userInfo, jumppath])

  useEffect(() => {
    gets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if (list.length === 0) {
  //     setShowLoading(true)
  //     setTimeout(() => {
  //       setShowLoading(false)
  //     }, 6000);
  //   }
  //   if (list.length > 0) {
  //     setShowLoading(false)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [list])

  const [sort, setSort] = useState(false)
  const [przie, setPrzie] = useState(true)
  const getUrl = () => {
    window.localStorage.setItem('link', history.location.pathname)
  }
  // const { loginWithRedirect } = useAuth0();
  // const contentStyle: React.CSSProperties = {
  //   height: 'auto',
  //   color: '#fff',
  //   lineHeight: '160px',
  //   textAlign: 'center',
  //   background: '#364d79',
  // };

  // get video src
  const [loginVideo, setLoginVideo] = useState<any>([])
  useEffect(() => {
    const getVideoSrc = async () => {
      const videos: any = await getAssets('alexblocks_content/login');
      setLoginVideo(videos)
    }
    getVideoSrc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localGet('assets')])



  return (
    <IonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => window.location.reload()}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className={style.upcoming}>
          <div className={style.movie}>
            {
              confirmlogin && userInfo ?
                <div ref={video1Ref}>
                  <Player
                    ref={videoRef}
                    preload='auto'
                    autoPlay={false}
                    playsInline={true}
                    // src={localGet('assets')?.find((item: any) => (item._id === 'login'))?.data.EN['720']}
                    src={loginVideo?.find((item: any) => (item._id === 'alexblocks_content/login'))?.data.EN['720']}
                    // src={loginvideo}
                    // src='https://storage.googleapis.com/oneinamillion-assets/login.mp4'
                    crossorigin={process.env.REACT_APP_SUPABASE_URL}
                    muted={true}
                    onEnded={() => {
                      videoRef.current.pause()
                      if (confirmlogin) {
                        setConfirmLogin(false)
                      }
                    }}

                  >
                  </Player>
                </div>
                :
                // <Carousel autoplay dots={false} >
                //   <div>
                //     <img style={contentStyle} src={back1} alt="" />
                //   </div>
                //   <div>
                //     <img style={contentStyle} src={back2} alt="" />
                //   </div>
                //   <div>
                //     <img style={contentStyle} src={back3} alt="" />
                //   </div>
                // </Carousel>
                // <Carousel items={[back1, back2, back3]} interval={2000} />
                <Swiper
                  className="upComingSwiper"
                  autoplay={{ delay: 5000, disableOnInteraction: false }}
                  loop
                  modules={[Autoplay]}
                >
                  <SwiperSlide>
                    <img src={back1} alt='' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={back2} alt='' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={back3} alt='' />
                  </SwiperSlide>
                </Swiper>
            }


            {/* <img src={back2} alt="" style={isAuthenticated && user && confirmlogin === false ? { display: 'block' } : { display: 'none' }} /> */}





          </div>
          <div className={style.lists}>
            <div className={style.change}  >

              <div className={style.changeleft}>
                <p
                  className={jumppath === 'upcoming' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('upcoming')
                    history.push('/upcoming')
                  }}
                >
                  Upcoming Draws
                </p>
                <p
                  className={jumppath === 'joinedgames' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('joinedgames')
                    history.push('/joinedgames')
                  }}
                  style={{ display: localGet('auth0_user') ? 'block' : 'none' }}
                >
                  Joined Draws
                </p>
                <p
                  className={jumppath === 'pastgames' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('pastgames')
                    history.push('/pastgames')
                  }}
                >
                  Past Draws
                </p>
                <p style={{ width: '90px', display: localGet('auth0_user') ? 'none' : 'block' }}></p>
              </div>


            </div>

            <div></div>
            <div className={style.line}></div>
            <div className={style.changerigth}>
              <p>Order by</p>
              <div className={style.changesvg} onClick={() => {
                setTimeSortParame(timeSortParame === "asc" ? 'desc' : 'asc')
                listSort('time', timeSortParame === "asc" ? 'desc' : 'asc')
                setIsClickSort('time')
                localSet('upcomingSort', 'time')
                localSet('upcomingSortParame', timeSortParame === "asc" ? 'desc' : 'asc')
                setSort(true)
                setPrzie(false)
              }}>
                <SVGIcon name="ClockIcon" width="18" height="18" viewBox="0 0 18 18"  fill="white"/>
                <div className={
                  sort === true ? (timeSortParame === "asc" ? style.nonechangesvgtop : style.changesvgtop1) : style.node
                } >
                <SVGIcon name="SortDownArrow" width="14" height="14" viewBox="0 0 14 14" fill="#9C94FF"/>
                </div>
                <span>Time</span>

              </div>



              <div className={style.changepath} onClick={() => {
                setPrizeSortParame(prizeSortParame === 'asc' ? "desc" : "asc")
                listSort('prize', prizeSortParame === 'asc' ? "desc" : "asc")
                setIsClickSort('prize')
                localSet('upcomingSort', 'prize')
                localSet('upcomingSortParame', prizeSortParame === 'asc' ? "desc" : "asc")
                setSort(false)
                setPrzie(true)
              }}>
                <SVGIcon name="SortPriceIcon" width="18" height="18" viewBox="0 0 18 18" fill="white"/>
                <div className={
                  przie === true ? (prizeSortParame === "asc" ? style.nonechangesvgtop : style.changesvgtop1) : style.node
                }
                >
                <SVGIcon name="SortDownArrow" width="14" height="14" viewBox="0 0 14 14" fill="#9C94FF"/>
                </div>
                <span>Prize</span>
              </div>


            </div>
            {list.length > 0 ?
              <IonList className={style.cards}>
                {list.map((item: any, index: any) => (
                  <IonItem
                    key={index}
                    className={item.isJoined ? style.lijoined : ''}
                    style={item.isJoined === true ? { marginBottom: '4px' } : {}}
                  >
                    <div className={style.left}>
                      <div className={style.leftimg} onClick={async () => {
                        if (item.isJoined === true) return
                        if (localGet('auth0_user')) {
                          localSet('click_Item', item)
                          changeModalState('isClickOpenModal', true)
                        } else {
                          changeModalState('isSignInModalOpen',true)
                          // loginWithRedirect()
                        }
                      }}>
                        <img src={item.drawImage === '' ? img1 : `${drawImages?.[item.drawImage] || img1}`} alt="" />
                      </div>
                      <div className={style.detail}>
                        <p>Join with ${addCommas(item.ticketPrice)}</p>
                        <p>${window.innerWidth <= 340 && item.prize >= 100000000 ? formatPrize(item.prize) : addCommas(item.prize)}</p>

                        <div className={style.progrebox}>

                          <div style={{
                            width: `${item.ticketsSold / item.maxTickets * 100}%`
                          }}
                            className={item.isJoined ? style.joined : style.progress}>

                          </div>
                        </div>

                        {item.winnerWallet !== "" && item.winnerNickname !== "" ?
                          <div className={style.count}>
                            <span>{item.winnerNickname} won this draw!</span>
                          </div>
                          :
                          <div className={style.count}>
                            <div className={style.coutimg}>
                              <img src={img} alt="" />
                              <img src={img2} alt="" />
                              <img src={img3} alt="" />

                            </div>
                            <span style={{ marginRight: '2px' }}>&</span>
                            <Countup
                              number={item?.ticketsSold || 10}
                              scrollTime={2000}
                              className={style.countUp}
                            />
                            <span style={{ marginLeft: '5px' }}>Joined</span>
                          </div>}

                      </div>
                    </div>
                    {item.winnerWallet !== "" ?
                      <div className={style.dice}>
                        <img style={{ borderRadius: '50%' }} src={
                          // `${item.winnerAvatarURL}?${new Date().getTime()}`
                          item.winnerAvatarURL
                        } alt="" />
                      </div>
                      : item.winnerWallet === "" && item.ticketsSold === item.maxTickets ?
                        <div className={style.dice}>
                          <img src={dice} alt="" />
                        </div> :
                        item.isJoined === true ? <div className={style.joinedicon}>
                          <SVGIcon name="JoinGameSuccessIcon" width="21" height="20" viewBox="0 0 21 20" fill="#42E05C"/>
                          Joined
                        </div> :
                          <div className={style.join} id="open-modal-add" onClick={async () => {
                            if (localGet('auth0_user')) {
                              localSet('click_Item', item)
                              changeModalState('isClickOpenModal', true)
                            } else {
                              if (navigator.onLine === false) {
                                showToastMessage('Please sign into your account first.')
                                return
                              }
                              changeModalState('isSignInModalOpen',true)
                              // loginWithRedirect()
                            }
                          }}>
                            <SVGIcon name="JoinGameAddIcon" width="14" height="14" viewBox="0 0 14 14" fill="black"/>

                          </div>

                    }
                  </IonItem>
                ))}
              </IonList>

              :
              <div className={style.cartex}>
                <img src={back} alt="" />

                <p>No Draws, Please Wait</p>
              </div>
            }
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                generateItems();
                setTimeout(() => ev.target.complete(), 500);
              }}
            >
              <IonInfiniteScrollContent ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </div>
        </div>



      </IonContent >
    </IonPage >
  );
};

export default Upcoming;
