import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
export const formatTimeAgo = (date: Date): string => {
  return dayjs(date).fromNow();
};

export const formatBetterDate = (date: Date) => {
  const now = dayjs();
  const inputDate = dayjs(date);
  const diff = now.diff(inputDate);
  if (diff < 60 * 60 * 1000) {
    return inputDate.fromNow();
  } else if (inputDate.isToday()) {
    return inputDate.format('LTS');
  } else {
    return inputDate.calendar(null, {
      lastDay: '[Yesterday at] LT',
      lastWeek: 'ddd, MMMM D, YYYY [at] LT',
      sameElse: 'ddd, MMM D, YYYY [at] LT'
    });
  }
};
