
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonModal, IonRefresher, IonRefresherContent } from "@ionic/react";
import { useState } from "react";
// import { selectBalanceHistory } from "../../../db/schema/balanceHistory";
import style from "../../../style/Account.module.css";
import { addCommas } from "../../../utils/addCommas";
// import { formatDate } from "../../../utils/formatDate";
import { useStore } from "../../../store";
import SVGIcon from "components/SvgComponent";

interface Props {
    syncBalace?: any;
    syncPoints?: any;
    balanceData?: any;
    setBalancaData?: any;
    getdatas?: any
}

const TransactionHistoryModal: React.FC<Props> = ({
    syncBalace,
    syncPoints,
    balanceData,
    setBalancaData,
    getdatas
}) => {
    const { isBalanceModalOpen,changeModalState } = useStore();
    const [sortIcon, setSortIcon] = useState(true)
    const [defaultType, setDefaultType] = useState('money')
    const [page, setPage] = useState(1)

    const handleModalWillPresent = () => {
        setSortIcon(true)
        setDefaultType('money')
        setPage(1)
        getdatas()
    }
    const handleSort = () => {
        setSortIcon(!sortIcon)
        let isasc = sortIcon ? 'desc' : 'asc'
        setPage(1)
        getdatas(defaultType, isasc)
    }

    const handleRefresher = (e: any) => {
        setTimeout(() => {
            getdatas(defaultType, sortIcon ? 'desc' : 'asc')
            setPage(1)
            e.detail.complete()
        }, 1000);
    }
    
    const handleOpenMoneyList = () => {
        if (defaultType === 'money') return
        setDefaultType('money')
        setSortIcon(true)
        getdatas('money')
    }

    const handleOpenGemsList = () => {
        if (defaultType === 'point') return
        setDefaultType('point')
        setSortIcon(true)
        getdatas('point')
    }

    const handleLoadMore = (ev: any) => {
        console.log('handleLoadMore')
        getdatas(defaultType, sortIcon ? 'desc' : 'asc', (page + 1) * 10);
        setPage(page + 1);
        setTimeout(() => ev.target.complete(), 500);
    }

    return (
        <IonModal
            isOpen={isBalanceModalOpen}
            onIonModalWillPresent={handleModalWillPresent}
            className={style.molbox}
            mode="ios"
            onDidDismiss={() => changeModalState('isBalanceModalOpen', false)}>
            <IonContent>
                <div className={style.balan}>
                    <div className={style.balantop}>
                        <div onClick={() => { changeModalState('isBalanceModalOpen', false) }}>
                            <svg width="10" height="16" viewBox="0 0 10 16"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 14L2 8L8 2" stroke="white" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                        </div>
                        <span>Transaction History</span>
                        <div
                            className={style.balantopIcon}
                            onClick={handleSort}>
                            <SVGIcon name="TransactionHistorySortIcon" style={sortIcon === true ? {} : { transform: ' rotate(180deg)' }} width="24" height="24" viewBox="0 0 24 24" fill="white"/>
                        </div>
                    </div>
                    <IonRefresher slot="fixed" onIonRefresh={(e) => handleRefresher(e)}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    <div className={style.showMoney}>
                        <div className={style.showdes}>
                            My Balance
                        </div>
                        <div className={style.money}>
                            <div className={style.diamond}>
                                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.13563 0.333374H10.5782C10.6606 0.33336 10.7418 0.357982 10.8152 0.405228C10.8886 0.452475 10.9522 0.521004 11.0006 0.605216L12.9967 4.07318C13.0322 4.13488 13.0497 4.21022 13.0462 4.28637C13.0427 4.36252 13.0184 4.43477 12.9774 4.49083L7.04854 12.6072C7.0241 12.6405 6.99446 12.6672 6.96148 12.6854C6.9285 12.7036 6.8929 12.713 6.85691 12.713C6.82092 12.713 6.78532 12.7036 6.75235 12.6854C6.71937 12.6672 6.68973 12.6405 6.66529 12.6072L0.736402 4.49149C0.695336 4.43539 0.670959 4.363 0.667436 4.28671C0.663912 4.21041 0.68146 4.13494 0.717082 4.07318L2.71322 0.605216C2.76166 0.521004 2.82519 0.452475 2.8986 0.405228C2.97202 0.357982 3.05324 0.33336 3.13563 0.333374Z" fill="#42E0A7" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.18652 11.6105L11.2067 14.3757C11.2311 14.4091 11.2608 14.4357 11.2937 14.4539C11.3267 14.4722 11.3623 14.4816 11.3983 14.4816C11.4343 14.4816 11.4699 14.4722 11.5029 14.4539C11.5358 14.4357 11.5655 14.4091 11.5899 14.3757L17.5188 6.25939C17.5597 6.20333 17.584 6.13107 17.5876 6.05492C17.5911 5.97878 17.5736 5.90344 17.5381 5.84173L15.542 2.37377C15.4936 2.28956 15.43 2.22103 15.3566 2.17378C15.2832 2.12654 15.202 2.10191 15.1196 2.10193H13.272L14.4066 4.07321C14.4421 4.13492 14.4596 4.21026 14.4561 4.28641C14.4525 4.36256 14.4282 4.43481 14.3873 4.49087L9.18652 11.6105Z" fill="#42E0A7" />
                                </svg>
                                {syncPoints?.toLocaleString()}
                            </div>
                            <div className={style.amount}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.02337 16.875C7.04099 16.7083 6.28726 16.3263 5.7622 15.7291C5.25407 15.1319 5 14.368 5 13.4375V12.4791H7.64228V13.5833C7.64228 14.4305 8.125 14.8541 9.09045 14.8541C10.0559 14.8541 10.5386 14.4166 10.5386 13.5416C10.5386 13 10.3692 12.5138 10.0305 12.0833C9.69173 11.6388 9.06504 11.125 8.15041 10.5416C7.01558 9.80551 6.21951 9.11801 5.7622 8.47913C5.30488 7.84024 5.07622 7.11801 5.07622 6.31246C5.07622 5.40968 5.32182 4.66663 5.81301 4.08329C6.32114 3.49996 7.05793 3.11802 8.02337 2.93746V1.66663H10.31V2.93746C11.2923 3.10413 12.0376 3.48607 12.5457 4.08329C13.0539 4.66663 13.3079 5.42357 13.3079 6.35413V6.77079H10.6656V6.20829C10.6656 5.34718 10.1999 4.91663 9.26829 4.91663C8.33672 4.91663 7.87093 5.33329 7.87093 6.16663C7.87093 6.66663 8.04031 7.1319 8.37907 7.56246C8.71782 7.97913 9.34451 8.48607 10.2591 9.08329C11.394 9.83329 12.19 10.5347 12.6474 11.1875C13.1047 11.8263 13.3333 12.5625 13.3333 13.3958C13.3333 14.3402 13.0708 15.118 12.5457 15.7291C12.0376 16.3263 11.2923 16.7083 10.31 16.875V18.3333H8.02337V16.875Z" fill="#9C94FF" />
                                </svg>
                                {addCommas(syncBalace)}
                            </div>
                        </div>
                    </div>
                    <div className={style.changeHistory}>
                        <div
                            className={defaultType === 'money' ? style.acts : ''}
                            onClick={handleOpenMoneyList}>
                            Money
                        </div>
                        <div
                            className={defaultType === 'point' ? style.acts : ''}
                            onClick={handleOpenGemsList}>
                            Gems
                        </div>
                    </div>
                        <IonList>
                        <ul className={style.balanlist}>
                        {
                            balanceData.map((i: any, index: any) => (
                                <li key={index} >
                                    <div className={style.path}>
                                        {
                                            (i.transactionDetail === 'external' || i.transactionType === 'in') ?
                                                <SVGIcon name="PigsIcon" width="44" height="44" viewBox="0 0 44 44" fill="none"/>
                                                :
                                                i.transactionDetail === 'winnings' ?
                                                <SVGIcon name="WinningsIcon" width="44" height="44" viewBox="0 0 44 44" fill="none"/>
                                                    :
                                                    (i.transactionDetail === 'ticket' || i.transactionType === 'out') ?
                                                        <SVGIcon name="TransactionTicketIcon" width="44" height="44" viewBox="0 0 44 44" fill="none"/>
                                                        :
                                                        i.transactionDetail === "external" ?
                                                            <SVGIcon name="ExternalIcon" width="44" height="44" viewBox="0 0 44 44" fill="none"/>
                                                            : i.transactionDetail === 'voucher' ?
                                                                <SVGIcon name="GiftIcon" width="44" height="44" viewBox="0 0 44 44" fill="none"/>
                                                                :
                                                                <SVGIcon name="PigsIcon" width="44" height="44" viewBox="0 0 44 44" fill="none"/>
                                        }
                                        <div className={style.text}>
                                            <p>{(i?.transactionType || i.type) === 'Ticket purchase' ? 'Ticket Purchase' : (i.type || i.transactionDetail)}</p>
                                            <span>{i.date}</span>
                                        </div>
                                    </div>
                                    <div className={i.payment === 'in' || i.transactionType === 'in' || i.transactionType === 'refund'? style.ching : style.red}>
                                        <span className={style.amount}>
                                            {defaultType !== 'point' ?
                                                i.payment === 'in' ? '+ $' : '$'
                                                : i.transactionType === 'in' ? "+ " : ""}
                                            {i.points || i.amount}</span>
                                        {/* <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.0771484 12.4866L1.25715 13.6666L7.92381 6.99992L1.25715 0.333252L0.0771484 1.51325L5.56381 6.99992L0.0771484 12.4866Z" fill="#A6A9B8" />
                      </svg> */}
                                    </div>
                                </li>
                            ))
                        }
                        </ul>
                        </IonList>
                        <IonInfiniteScroll
                            threshold="150px"
                            onIonInfinite={(ev) => handleLoadMore(ev)}
                        >
                            <IonInfiniteScrollContent ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default TransactionHistoryModal