import * as Database from '../';
import { localGet, localSet } from '../../utils/localStorage';
// eslint-disable-next-line
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { selectInvitesCountByinviteesId } from 'db/schema/invites';
import { checkVersion } from 'db/schema/newVersion';
import PubSub from 'pubsub-js';
import { externalJWT } from '../../api/auth';
import { getVideoUrl } from '../schema/assets';
import { selectInvites } from '../schema/invites';
import { selectUser } from '../schema/user';
const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
/*
function performChunk(datas, consumer, onComplete) {
    if (datas.length === 0) {
        onComplete([]);
        return;
    };
    let i = 0;
    let res = [];
    function _run() {
        if (i === datas.length) {
            onComplete(res);
            return;
        };
        requestIdleCallback(idle => {
            while (idle.timeRemaining() > 0 && i < datas.length) {
                const item = datas[i];
                res.push(consumer(item, i));
                i++;
            }
            _run();
        });
    }

    _run();
} */

const chunkSplitor = (task) => {
  setTimeout(() => {
    task((time) => time < 16);
  }, 30);
};

function performChunk(datas, consumer, onComplete, chunkSplitor) {
  if (datas.length === 0) {
    onComplete([]);
    return;
  }

  if (!chunkSplitor && global.requestIdleCallback) {
    chunkSplitor = (task) => {
      requestIdleCallback((idle) => {
        task(() => idle.timeRemaining() > 0);
      });
    };
  }
  let i = 0;
  let res = [];

  function _run() {
    if (i === datas.length) {
      onComplete(res);
      return;
    }

    chunkSplitor((hashTime) => {
      const now = Date.now();
      while (hashTime(Date.now() - now) && i < datas.length) {
        const item = datas[i];
        res.push(consumer(item, i));
        i++;
      }
      _run();
    });
  }

  _run();
}

// let eventQueue = [];
// let pending = false;

// async function processQueue(queue, authAddress, db, checkpoint, onSyncComplete) {
//     /* eslint-disable-next-line no-restricted-globals */
//     self.console.log('pending', pending, eventQueue.length);
//     if (!pending && queue.length > 0) {
//         pending = true;
//         const event = queue.shift();
//         await processEvent(event, authAddress, db, checkpoint, onSyncComplete);
//         processQueue(queue, authAddress, db, checkpoint, onSyncComplete);
//     }
// }
/* const payMentType = [
  'Deposit',
  'Draw Winnings',
  'Return Voucher',
  'Join split ticket'
]; */
const transactionsType = {
  voucher: { in: 'Received voucher', out: 'Voucher is purchased' },
  ticket: { in: 'Received ticket', out: 'Ticket is purchased' },
  quest: { in: 'Quest is received from quest', out: '' },
  'Split ticket': { in: 'Sold split ticket', out: 'Split_ticket is purchased' },
  external: {
    in: 'External is for deposit',
    out: 'Withdrawal from back account'
  },
  winnings: { in: 'Winnings is winnings from tickets/split tickets', out: '' }
};

// let drawIds = [];
// let localTickets;
let invites = []
async function processEvent(
  event,
  authAddress,
  db,
  checkpoint,
  onSyncComplete
) {
  let data = event.data;
  data = JSON.parse(data);
  /* eslint-disable-next-line no-restricted-globals */
  self.console.log('data', data, 'Date', new Date().toLocaleTimeString());

  /* let localImages;
    let syncImagesResult;
    db.images.bulkUpsert(data?.images).then((result) => syncImagesResult = result) */

  // save checkpoint
  const syncCheckpoint = data?.checkpoint;
  let currentCheckpoints = localGet('checkpoint');
  if (currentCheckpoints === null) {
    const jsonCheckpoints = JSON.parse(JSON.stringify(checkpoint));
    currentCheckpoints = jsonCheckpoints?.checkpoint;
  }
  const updatedCheckpoints = {
    ...currentCheckpoints,
    ...syncCheckpoint
  };
  localSet('checkpoint', updatedCheckpoints);
  await db.checkpoint.upsert({
    _id: authAddress || 'logged_out',
    checkpoint: updatedCheckpoints
  });

  // save user data
  const user = data?.user;
  if (user) {
    await db.user.upsert(user);
  } else {
    const user = await selectUser();
    if (!user) {
      (async () => {
        const userInfo = localGet('user_info');
        if (!userInfo) return;
        const exp = await externalJWT();
        localSet('id_token', exp.data);
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/getUser?wallet=${userInfo.user_metadata.user_wallet}`,
          {
            credentials: 'same-origin',
            method: 'GET',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
              authorization: `Bearer ${exp.data}`
            }
          }
        );
        const { data: _data, code } = await response.json();
        if (code === 200) {
          const { user } = _data;
          const { draws, ..._user } = user;
          await db.user.upsert(_user);
          PubSub.publish('syncData', { user: _user });
        }
      })();
    }
  }

  if (user !== null && user !== undefined && user.toString().length > 0) {
    // localSet('userBalance', user.money)
    localSet('userBalance', user);
    UserNot(user, onSyncComplete);
    console.log('syncBalance', user);
  }

  const balanceHistory = data?.transactions;
  if (balanceHistory.length > 0) {
    const balanceConsumer = (item) => {
      // const payment = payMentType.includes(item.type) ? 'in' : 'out';
      return {
        _id: item._id,
        type: transactionsType[item.transactionDetail][item.transactionType],
        user: item.user,
        amount: item.ammount,
        date: item.date,
        draw: item.draw,
        transactionDetail: item.transactionDetail,
        transactionType: item.transactionType,
        payment: item.payment
      };
    };
    if (global.requestIdleCallback) {
      performChunk(
        balanceHistory,
        balanceConsumer,
        async (syncBalanceHistory) => {
          const syncBalanceHistoryResult = await db.balance_history.bulkUpsert(
            syncBalanceHistory
          );
          if (syncBalanceHistoryResult.length > 0) {
            console.log(
              'syncBalanceHistoryResult',
              syncBalanceHistoryResult.length
            );
          }
        }
      );
    } else {
      performChunk(
        balanceHistory,
        balanceConsumer,
        async (syncBalanceHistory) => {
          const syncBalanceHistoryResult = await db.balance_history.bulkUpsert(
            syncBalanceHistory
          );
          if (syncBalanceHistoryResult.length > 0) {
            console.log(
              'syncBalanceHistoryResult',
              syncBalanceHistoryResult.length
            );
          }
        },
        chunkSplitor
      );
    }
  }

  // const syncBalanceHistoryResult = await db.balance_history.bulkUpsert(balanceConsumer);

  // sync assets data
  const assets = data?.assets;
  if (assets.length > 0) {
    const syncAssetsResult = await db.assets.bulkUpsert(assets);
    if (syncAssetsResult.length > 0) {
      localSet('assets', true);
      getVideoUrl();
      console.log('syncAssetsResult', syncAssetsResult.length);
    }
  }

  // sync channel data
  const channels = data?.channels;
  if (channels.length > 0) {
    const syncChannelsResult = await db.channels.bulkUpsert(channels);
    if (syncChannelsResult.length > 0) {
      console.log('syncChannelsResult', syncChannelsResult.length);
    }
  }

  // save draw data
  const draws = data.draws;
  // if (draws.length > 0) {
  //     drawIds = draws.map((draw) => draw._id);
  // }

  // wait sync ticket and image data
  // because we need to get the drawImage and owner from ticket and image data
  // let localImages;
  let syncImagesResult;
  let localTickets;
  let syncTicketsResult;
  let syncVoucherResult;
  let syncPointsResult;
  let invitesResult;
  let notificationResult;
  let ratiosResult;
  let questsResult;
  let newVersionResult;
  await Promise.all([
    db.images
      .bulkUpsert(data?.images ?? [])
      .then((result) => (syncImagesResult = result)),
    db.tickets
      .bulkUpsert(
        (data?.tickets ?? []).map((ticket) => {
          return {
            ...ticket,
            drawData: ticket.draw.toString()
          };
        })
      )
      .then((result) => (syncTicketsResult = result)),
    db.voucher
      .bulkUpsert(data?.vouchers ?? [])
      .then((result) => (syncVoucherResult = result)),
    db.points
      .bulkUpsert(data?.points ?? [])
      .then((result) => (syncPointsResult = result)),
    db.invites
      .bulkUpsert(data?.invites ?? [])
      .then((result) => (invitesResult = result)),
    db.notification
      .bulkUpsert(data?.notification ?? [])
      .then((result) => (notificationResult = result)),
    db.ratios
      .bulkUpsert(data?.ratios ?? [])
      .then((result) => (ratiosResult = result)),
    db.quests
      .bulkUpsert(data?.quests ?? [])
      .then((result) => (questsResult = result)),
    db.new_version
      .bulkUpsert(data?.version ?? [])
      .then((result) => (newVersionResult = result))
  ]).then(async () => {
    localTickets = await db.tickets
      .find({
        selector: {
          draw: { $in: draws.map((draw) => draw._id) }
        },
        index: ['draw', 'owner']
      })
      .exec();
    if (syncImagesResult.length > 0) {
      // localSet('images', true)
      localSet('images', new Date().getTime());
      console.log('syncImagesResult', syncImagesResult.length);
    }
    if (syncTicketsResult.length > 0) {
      ticketsNot(data?.tickets, onSyncComplete);
      splitTicketsWinningNot(data?.tickets, onSyncComplete);
      splitTicketInvitesNot(data?.tickets, onSyncComplete);
      console.log('syncTicketsResult', syncTicketsResult.length);
    }
    if (syncVoucherResult.length > 0) {
      voucherNot(data?.vouchers, onSyncComplete);
      console.log('syncVoucherResult', syncVoucherResult.length);
    }
    if (syncPointsResult.length > 0) {
      console.log('syncPointsResult', syncPointsResult.length);
    }
    if (invitesResult.length > 0) {
      console.log({ invitesResult });
      // eslint-disable-next-line no-undef
      const _result = invites.find(_=>_.supabaseId===invitesResult[0]._data.supabaseId)
      if (!_result) {
        PubSub.publish('invites', invitesResult[0]._data);
        invites = await selectInvites()
      }
      selectInvitesCountByinviteesId().then((res) => {
        localSet('invitesCount', res);
      });
    }
    if (notificationResult.length > 0) {
      notificationsNot(data?.notification, onSyncComplete);
      console.log('notificationResult', notificationResult.length);
    }
    if (ratiosResult.length > 0) {
      console.log('ratiosResult', ratiosResult.length);
    }
    if (questsResult.length > 0) {
      console.log('questsResult', questsResult.length);
    }
    if (newVersionResult.length > 0) {
      newVersionNot(data?.version, onSyncComplete);
      console.log('newVersionResult', newVersionResult.length);
    }
  });

  // sync draw data
  const localDraws = await db.draw
    .find({
      selector: {
        id: { $in: draws.map((draw) => draw._id.toString()) }
      }
    })
    .exec();

  const drawConsumer = (item) => {
    // const isJoined = localDraws.find((i) => i.id === item._id.toString())?.isJoined;
    const isEjected = localDraws.find(
      (i) => Number(i.id) === Number(item._id)
    )?.isEjected;

    // const drawImage = localImages.find((image) => image._id === item.drawImage);
    const isJoinedByTicket = localTickets.some(
      (ticket) =>
        ticket.draw === item._id &&
        ticket.owner === authAddress &&
        ticket.state === 'activate'
    );
    const isSplitTicket = localTickets.some(
      (ticket) =>
        ticket.draw === item._id &&
        ticket.owner === authAddress &&
        ticket.ticketShare < 10000
    );
    const ticketCount = localTickets.filter(
      (ticket) => ticket.draw === item._id
    ).length;

    return {
      id: item._id.toString(),
      drawId: item._id,
      drawName: '',
      description: item.description,
      contract: item.contract,
      prize: item.prize,
      maxTickets: item.maxTickets,
      maxTicketsPerUser: item.maxTicketsPerUser,
      ticketsSold: item.ticketsSold,
      ticketPrice: item.ticketPrice,
      winnerAvatarURL: item.winnerAvatarURL || '',
      active: item.active,
      winnerWallet: item.winnerWallet || '',
      winnerMembers: item.winnerMembers || [],
      drawImage: item.drawImage,
      isBiggest: false,
      isJoined: isJoinedByTicket || false,
      isSplitTicket: isSplitTicket || false,
      isEjected: isEjected || false,
      promoted: item.promoted,
      videoLinks: item.videoLinks,
      startTime: item.startTime,
      endTime: item.endTime,
      lastUpdateTime: item.lastUpdateTime,
      winnerNickname: item.winnerNickname || '',
      ticketCount: ticketCount
    };
  };
  if (global.requestIdleCallback) {
    performChunk(
      draws,
      drawConsumer,
      async (syncDraws) => {
        const syncDrawsResult = await db.draw.bulkUpsert(syncDraws);
        if (syncDrawsResult.length > 0) {
          drawsNot(syncDraws, onSyncComplete);
          scrollWinNotify(syncDraws, onSyncComplete);
        }
        const winning = syncDraws.filter(
          (item) => item.winnerWallet !== '' && item.isJoined === true
        );
        if (winning.length > 0) {
          // winningNot(winning, onSyncComplete);
        }
        if (syncDrawsResult.length > 0) {
          console.log('syncDrawsResult', syncDrawsResult.length);
        }
      },
      chunkSplitor
    );
  } else {
    performChunk(
      draws,
      drawConsumer,
      async (syncDraws) => {
        const syncDrawsResult = await db.draw.bulkUpsert(syncDraws);
        if (syncDrawsResult.length > 0) {
          drawsNot(syncDraws, onSyncComplete);
          scrollWinNotify(syncDraws, onSyncComplete);
        }
        const winning = syncDraws.filter(
          (item) => item.winnerWallet !== '' && item.isJoined === true
        );
        if (winning.length > 0) {
          // winningNot(winning, onSyncComplete);
        }
        if (syncDrawsResult.length > 0) {
          console.log('syncDrawsResult', syncDrawsResult.length);
        }
      },
      chunkSplitor
    );
  }

  // pending = false;
}

export const syncDatabase = async (onSyncComplete) => {
  const authAddress = localGet('authAddress');
  const db = await Database.get();
  const checkpoint = await db.checkpoint
    .findOne(authAddress || 'logged_out')
    .exec();
  let bodyObj = {
    checkpoint:
      checkpoint?.checkpoint.checkpoint || '1970-01-01T00:00:00.000000+00:00'
  };
  if (checkpoint?.checkpoint.checkpoint) {
    bodyObj.maxIterations = 10;
  }
  const exp = await externalJWT();
  const idToken = localGet('id_token') ?? exp.data;
  await fetchEventSource(BACKEND_BASE_URL + '/streamDatabase', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json'
    },
    /* body: JSON.stringify({
            minTimestampDraw: checkpoint?.checkpoint?.draw_checkpoint?.lastUpdateTime || '1970-01-01T00:00:00.000000+00:00',
            minTimestampTicket: checkpoint?.checkpoint?.ticket_checkpoint?.lastUpdateTime || '1970-01-01T00:00:00.000000+00:00',
            minTimestampUser: checkpoint?.checkpoint?.user_checkpoint?.lastUpdateTime || '1970-01-01T00:00:00.000000+00:00',
            minTimestampTransaction: checkpoint?.checkpoint?.transaction_checkpoint?.lastUpdateTime || '1970-01-01T00:00:00.000000+00:00',
            minTimestampImage: checkpoint?.checkpoint?.image_checkpoint?.lastUpdateTime || '1970-01-01T00:00:00.000000+00:00',
            minTimestampAsset: checkpoint?.checkpoint?.asset_checkpoint?.lastUpdateTime || '1970-01-01T00:00:00.000000+00:00',
            minTimestampChannel: checkpoint?.checkpoint?.channel_checkpoint?.lastUpdateTime || '1970-01-01T00:00:00.000000+00:00',
        }), */
    body: JSON.stringify(bodyObj),
    async onmessage(event) {
      if (event.event === 'Draw update') {
        // eventQueue.push(event);
        // await processQueue(eventQueue, authAddress, db, checkpoint, onSyncComplete);
        await processEvent(event, authAddress, db, checkpoint, onSyncComplete);
      }

      if (event.event === 'ping') {
        console.log('ping');
      }
    }
  });
};

const defaultSyncCompleteObj = {
  winning: null,
  draws: null,
  user: null,
  scrollWin: null,
  tickets: null,
  vouchers: null,
  notifications: null,
  splitTicketInvites: null,
  newVersion: null
};

// async function winningNot(data, onSyncComplete) {
//     let ejectData = [];
//     // if item.winnerWallet === localGet('authAddress') is winning
//     // if item.winnerWallet !== localGet('authAddress') is unWinning
//     let winningData = data.filter((item) => item.winnerWallet === localGet('authAddress') && item.isEjected === false);
//     let unWinningData = data.filter((item) => item.winnerWallet !== localGet('authAddress') && item.isEjected === false);
//     winningData = winningData.map((item) => ({ ...item, type: 'winning' }));
//     unWinningData = unWinningData.map((item) => ({ ...item, type: 'unWinning' }));
//     // winning and unWinning merge
//     ejectData = [...winningData, ...unWinningData];
//     if (ejectData.length === 0) return;
//     // if (onSyncComplete) onSyncComplete({ winning: ejectData, draws: null, balance: null });

//     /* // check if endTime is less than 2 minutes
//     const now = new Date().getTime();
//     const endTime = ejectData.map((item) => ({ ...item, endTime: new Date(item.endTime).getTime() }));
//     // const endTimeLessThanMinutes = endTime.filter((item) => (now - item.endTime) < 120000);
//     const endTimeLessThanMinutes = endTime.filter((item) => (now - item.endTime) < 18 * 60 * 60 * 1000);
//     if (endTimeLessThanMinutes.length === 0) return;
//     console.log('endTimeLessThanMinutes', endTimeLessThanMinutes.length);
//     if (onSyncComplete) onSyncComplete({ winning: endTimeLessThanMinutes, draws: null, balance: null }); */

//     // check if endTime is less than 2 minutes
//     const now = new Date();
//     now.setMinutes(now.getMinutes() - 5);
//     const endTimeLessThanMinutes = ejectData.filter((item) => item.endTime > now.toISOString());
//     if (endTimeLessThanMinutes.length === 0) return;
//     /* eslint-disable-next-line no-restricted-globals */
//     self.console.log('endTimeLessThanMinutes', endTimeLessThanMinutes.length);
//     if (onSyncComplete) onSyncComplete({ ...defaultSyncCompleteObj, winning: endTimeLessThanMinutes });

//     // set isEjected to true
//     /* const db = await Database.get();
//     const drawIds = ejectData.map((item) => ({ ...item }));
//     const updateDraws = await db.draw.bulkUpsert(drawIds.map((item) => ({ ...item, isEjected: true })));
//     console.log('updateDraws isEjected', updateDraws.length); */
//     // setEject(ejectData);
// }

// set isEjected to true
export const setEject = async (data) => {
  const db = await Database.get();
  const drawIds = data.map((item) => ({ ...item }));
  const updateDraws = await db.draw.bulkUpsert(
    drawIds.map((item) => ({ ...item, isEjected: true }))
  );
  console.log('updateDraws isEjected', updateDraws.length);
};

async function drawsNot(data, onSyncComplete) {
  if (data.length === 0) return;
  if (onSyncComplete)
    onSyncComplete({ ...defaultSyncCompleteObj, draws: data });
}

async function UserNot(data, onSyncComplete) {
  if (data === null) return;
  if (onSyncComplete) onSyncComplete({ ...defaultSyncCompleteObj, user: data });
}

const handleUtcDateChange = (utcDateString) => {
  var date = new Date(utcDateString);
  var utcTime = date.getTime() - date.getTimezoneOffset() * 60000;
  var utcDate = new Date(utcTime);
  return utcDate;
};

export async function scrollWinNotify(data, onSyncComplete) {
  if (data.length === 0) return;

  // only when endTime is greater than 60 minutes will there be a scroll notification
  const now = new Date();
  now.setMinutes(now.getMinutes() - 60);
  const filterData = data.filter(
    (item) =>
      handleUtcDateChange(item.endTime) > now && item.winnerWallet !== ''
  );
  // filterData is sorted from large to small according to endTime
  filterData.sort((a, b) => {
    const aTime = new Date(a.endTime).getTime();
    const bTime = new Date(b.endTime).getTime();
    return bTime - aTime;
  });
  if (filterData.length === 0) return;
  const scrollWinData = filterData.map((item) => {
    return {
      winnerAvatarURL: item.winnerAvatarURL,
      prize: item?.prize?.toLocaleString(),
      text: formatNamesWithFixedName(
        item.winnerNickname,
        item.prize,
        item.winnerMembers
      )
    };
  });
  if (onSyncComplete)
    onSyncComplete({ ...defaultSyncCompleteObj, scrollWin: scrollWinData });
}
function formatNamesWithFixedName(fixedName, prize, arr) {
  if (arr.length === 0)
    return `${fixedName} just won $${prize.toLocaleString()}!`;

  const names = arr.map((item) => item.nickname);
  const last = names.pop();

  if (names.length === 0)
    return `${fixedName} and ${last} split the winnings of the $${prize.toLocaleString()} draw!`;
  return `${fixedName}, ${names.join(
    ', '
  )} and ${last} split the winnings of the $${prize.toLocaleString()} draw!`;
}

async function ticketsNot(data, onSyncComplete) {
  if (data.length === 0) return;
  if (onSyncComplete)
    onSyncComplete({ ...defaultSyncCompleteObj, tickets: data });
}

async function voucherNot(data, onSyncComplete) {
  if (data.length === 0) return;
  const filterData = data.filter(
    (item) =>
      item.receiver._id === localGet('authAddress') && item.state === 'pending'
  );
  if (filterData.length === 0) return;
  if (onSyncComplete)
    onSyncComplete({ ...defaultSyncCompleteObj, vouchers: filterData });
}

async function notificationsNot(data, onSyncComplete) {
  if (data.length === 0) return;
  if (onSyncComplete)
    onSyncComplete({ ...defaultSyncCompleteObj, notifications: data });
}

async function splitTicketsWinningNot(data, onSyncComplete) {
  if (data.length === 0) return;
  const winningData = data.filter((item) => item.isWinner === true);
  if (winningData.length === 0) return;
  const now = new Date();
  now.setMinutes(now.getMinutes() - 1);
  const lastUpdateTimeLessThanMinutes = winningData.filter(
    (item) => item.lastUpdateTime > now.toISOString()
  );
  if (lastUpdateTimeLessThanMinutes.length === 0) return;
  if (onSyncComplete)
    onSyncComplete({
      ...defaultSyncCompleteObj,
      winning: lastUpdateTimeLessThanMinutes
    });
}

async function splitTicketInvitesNot(data, onSyncComplete) {
  if (data.length === 0) return;
  const tickets = data.filter(
    (item) => item.owner === localGet('authAddress') && item.state === 'pending'
  );
  if (tickets.length === 0) return;
  if (onSyncComplete)
    onSyncComplete({ ...defaultSyncCompleteObj, splitTicketInvites: tickets });
}

async function newVersionNot(data, onSyncComplete) {
  if (data.length === 0) return;
  const isUpdate = await checkVersion();
  if (isUpdate === true) {
    if (onSyncComplete)
      onSyncComplete({ ...defaultSyncCompleteObj, newVersion: true });
  }
}
