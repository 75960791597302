import { useEffect, useState } from 'react';

const usePreview = (): [string | null, (file: File | null) => void] => {
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      preview && URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const getPreview = (file: File | null) => {
    if (!file) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  };

  return [preview, getPreview];
};

export default usePreview;
