import {StateCreator} from "zustand"
import { User } from '@supabase/supabase-js';

export interface MyUserInterface {
  globalUserInfo: User | null ;
  globalIdToken: string | null;
  setGlobalIdToken: (data: string | null) => void;
  setUserInfo: (data: User | null ) => void;
}
export const defaultUserValue = null

export const createUserInfoSlice: StateCreator<MyUserInterface> = (set) => ({
  // set
  globalUserInfo: defaultUserValue,
  globalIdToken: null,
  setGlobalIdToken: (data) => set(() => ({globalIdToken:data})),
  setUserInfo: (data) => set(() => ({globalUserInfo:data}))
})
