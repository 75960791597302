

import { IonModal, IonContent, IonSelect, IonSelectOption, IonAlert } from "@ionic/react";
import { useState } from "react";
import { tokenApi } from "../../../api";
import style from "../../../style/Account.module.css";
import { addCommas } from "../../../utils/addCommas";
import PinCode from "../../../components/PinCode";
import { validateCreds } from "../../../utils/Webauthn";
import { setShowLoading, showToastMessage } from "../../../components/GlobalLoading";
import { useStore } from "../../../store";
import SVGIcon from "components/SvgComponent";

interface Props {
    syncBalace: any;
    syncPoints: any;
}
const WithdrawnModal: React.FC<Props> = ({
    syncBalace,
    syncPoints,
}) => {
    const { isWithDrawnModalOpen,changeModalState } = useStore();
    const [sumvalue, setSumValue] = useState('')
    // withdrawal selectvalue
    const [selectvalue, setSelectValue] = useState('')

    const [openAuth0Modal, setOpenAuth0Modal] = useState(false)
    // face id failed alert
    const [openFaceIDFailedAlert, setOpenFaceIDFailedAlert] = useState(false)
    const [biometricFailedMessage, setBiometricFailedMessage] = useState('')

    const subWithdraw = async () => {
        await tokenApi('/withdraw', { money: Number(sumvalue) }).then((res) => {
            if (res.code === 200) {
                changeModalState('isWithDrawalModalOpen', true)
                setShowLoading(false)
                changeModalState('isWithDrawnModalOpen', false)
                setOpenAuth0Modal(false)
            } else if (res.detail) {
                showToastMessage(res.detail[0].msg)
                setShowLoading(false)
            }

        })
    }
    const afterBuy = (data: any) => {
        changeModalState('isWithDrawalModalOpen', data)
    }

    const handleOnkeyDown = (e: any) => {
        const { key } = e;
        const filteredKeys = new Set(["e", "-", "+", "."]);
        if (filteredKeys.has(key)) {
            e.preventDefault();
        }
    }

    const handleOnWithdrawal = async () => {
        if (sumvalue === '' || selectvalue === '') {
            showToastMessage('Please check your input.')
            return
        }
        if (Number(sumvalue) > Number(syncBalace)) {
            showToastMessage('The withdrawal amount has exceeded the balance.')
            return
        }
        if (navigator.onLine === false) {
            showToastMessage('Please check your network and try later.')
            return
        }
        setShowLoading(true)

        // face id
        /* let faceIdRes: any;
        try {
            faceIdRes = await validateCreds();
        } catch (e) {
            console.log(e);

            setShowLoading(false)
            setOpenAuth0Modal(true)
            return;
        }
        if (!faceIdRes) {
            console.log('faceIdRes', faceIdRes);

            setShowLoading(false)
            setOpenAuth0Modal(true)
            return;
        }
        subWithdraw()*/

        let faceIdRes: any;
        try {
            faceIdRes = await validateCreds();
        } catch (e) {
            console.log('verify face id error:', e);
            setBiometricFailedMessage('Biometric verification enablement failed, you need to try again later or restart the application, do you want to switch to PIN code?')
            setOpenFaceIDFailedAlert(true)
            setShowLoading(false)
        }
        if (faceIdRes === true) {
            subWithdraw()
        } else if (faceIdRes === 'null') {
            setOpenAuth0Modal(true)
            setShowLoading(false)
        } else if (faceIdRes === 'not') {
            // setBiometricFailedMessage('Biometric verification enablement failed, you need to try again later or restart the application, do you want to switch to PIN code?')
            // setOpenFaceIDFailedAlert(true)
            setOpenAuth0Modal(true)
            setShowLoading(false)
        }
    }
    return (
        <>
            <IonModal
                isOpen={isWithDrawnModalOpen}
                className={style.withdrawn}
                mode="ios"
                onDidDismiss={() => {
                    setSumValue('')
                    changeModalState('isWithDrawnModalOpen', false)
                    setOpenAuth0Modal(false)
                }}
            >
                <IonContent>
                    <div className={style.withbox}>
                        <div className={style.withtop} onClick={() => changeModalState('isWithDrawnModalOpen', false)}>
                            <div>
                            <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                            </div>
                            <p>Withdrawal</p>
                            <div className={style.managesvg}></div>
                        </div>

                        <div className={style.withmin}>
                            <p className={style.mybalance}>
                                My Balance
                            </p>
                            <div className={style.withmoney}>
                                <div className={style.withdiamond}>
                                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.13563 0.333374H10.5782C10.6606 0.33336 10.7418 0.357982 10.8152 0.405228C10.8886 0.452475 10.9522 0.521004 11.0006 0.605216L12.9967 4.07318C13.0322 4.13488 13.0497 4.21022 13.0462 4.28637C13.0427 4.36252 13.0184 4.43477 12.9774 4.49083L7.04854 12.6072C7.0241 12.6405 6.99446 12.6672 6.96148 12.6854C6.9285 12.7036 6.8929 12.713 6.85691 12.713C6.82092 12.713 6.78532 12.7036 6.75235 12.6854C6.71937 12.6672 6.68973 12.6405 6.66529 12.6072L0.736402 4.49149C0.695336 4.43539 0.670959 4.363 0.667436 4.28671C0.663912 4.21041 0.68146 4.13494 0.717082 4.07318L2.71322 0.605216C2.76166 0.521004 2.82519 0.452475 2.8986 0.405228C2.97202 0.357982 3.05324 0.33336 3.13563 0.333374Z" fill="#42E0A7" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.18652 11.6105L11.2067 14.3757C11.2311 14.4091 11.2608 14.4357 11.2937 14.4539C11.3267 14.4722 11.3623 14.4816 11.3983 14.4816C11.4343 14.4816 11.4699 14.4722 11.5029 14.4539C11.5358 14.4357 11.5655 14.4091 11.5899 14.3757L17.5188 6.25939C17.5597 6.20333 17.584 6.13107 17.5876 6.05492C17.5911 5.97878 17.5736 5.90344 17.5381 5.84173L15.542 2.37377C15.4936 2.28956 15.43 2.22103 15.3566 2.17378C15.2832 2.12654 15.202 2.10191 15.1196 2.10193H13.272L14.4066 4.07321C14.4421 4.13492 14.4596 4.21026 14.4561 4.28641C14.4525 4.36256 14.4282 4.43481 14.3873 4.49087L9.18652 11.6105Z" fill="#42E0A7" />
                                    </svg>
                                    {syncPoints?.toLocaleString()}
                                </div>
                                <div className={style.withamount}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.02337 16.875C7.04099 16.7083 6.28726 16.3263 5.7622 15.7291C5.25407 15.1319 5 14.368 5 13.4375V12.4791H7.64228V13.5833C7.64228 14.4305 8.125 14.8541 9.09045 14.8541C10.0559 14.8541 10.5386 14.4166 10.5386 13.5416C10.5386 13 10.3692 12.5138 10.0305 12.0833C9.69173 11.6388 9.06504 11.125 8.15041 10.5416C7.01558 9.80551 6.21951 9.11801 5.7622 8.47913C5.30488 7.84024 5.07622 7.11801 5.07622 6.31246C5.07622 5.40968 5.32182 4.66663 5.81301 4.08329C6.32114 3.49996 7.05793 3.11802 8.02337 2.93746V1.66663H10.31V2.93746C11.2923 3.10413 12.0376 3.48607 12.5457 4.08329C13.0539 4.66663 13.3079 5.42357 13.3079 6.35413V6.77079H10.6656V6.20829C10.6656 5.34718 10.1999 4.91663 9.26829 4.91663C8.33672 4.91663 7.87093 5.33329 7.87093 6.16663C7.87093 6.66663 8.04031 7.1319 8.37907 7.56246C8.71782 7.97913 9.34451 8.48607 10.2591 9.08329C11.394 9.83329 12.19 10.5347 12.6474 11.1875C13.1047 11.8263 13.3333 12.5625 13.3333 13.3958C13.3333 14.3402 13.0708 15.118 12.5457 15.7291C12.0376 16.3263 11.2923 16.7083 10.31 16.875V18.3333H8.02337V16.875Z" fill="#9C94FF" />
                                    </svg>
                                    {addCommas(syncBalace)}
                                </div>
                            </div>
                        </div>

                        <div className={style.withinp}>
                            <input type="number" value={sumvalue}
                                pattern="^[1-9]\d*$"
                                onKeyDown={(e) => handleOnkeyDown(e)}
                                onChange={(e) => setSumValue(e.target.value.replace(/^0+/, '').replace(/[^\d]/g, ''))} />
                            <p>
                                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.04688 13.9297V12.2891C3.44792 12.2786 2.875 12.2266 2.32812 12.1328C1.78646 12.0391 1.34375 11.9167 1 11.7656V10.5C1.35938 10.6667 1.82031 10.8125 2.38281 10.9375C2.94531 11.0625 3.5 11.1302 4.04688 11.1406V7.70312C3.35938 7.51042 2.78906 7.29167 2.33594 7.04688C1.88802 6.79688 1.55208 6.49219 1.32812 6.13281C1.10417 5.77344 0.992188 5.33854 0.992188 4.82812C0.992188 4.29688 1.11719 3.84375 1.36719 3.46875C1.6224 3.08854 1.97917 2.79167 2.4375 2.57812C2.89583 2.36458 3.43229 2.24219 4.04688 2.21094V0.859375H5V2.19531C5.55729 2.21094 6.0651 2.27344 6.52344 2.38281C6.98177 2.48698 7.40625 2.6224 7.79688 2.78906L7.39062 3.875C7.03646 3.73438 6.65365 3.61458 6.24219 3.51562C5.83073 3.41667 5.41667 3.34896 5 3.3125V6.72656C5.69792 6.92969 6.28125 7.14583 6.75 7.375C7.22396 7.60417 7.58073 7.88802 7.82031 8.22656C8.0599 8.5651 8.17969 9.0026 8.17969 9.53906C8.17969 10.2995 7.90104 10.9141 7.34375 11.3828C6.78646 11.8464 6.00521 12.1276 5 12.2266V13.9297H4.04688ZM5 11.0703C5.64062 11.0078 6.11719 10.8542 6.42969 10.6094C6.7474 10.3594 6.90625 10.0312 6.90625 9.625C6.90625 9.33333 6.84375 9.09375 6.71875 8.90625C6.59896 8.71875 6.40104 8.55729 6.125 8.42188C5.84896 8.28125 5.47396 8.14844 5 8.02344V11.0703ZM4.04688 6.39844V3.34375C3.65625 3.36979 3.32812 3.44271 3.0625 3.5625C2.79688 3.68229 2.59635 3.83854 2.46094 4.03125C2.33073 4.22396 2.26562 4.45312 2.26562 4.71875C2.26562 5.02604 2.32292 5.28385 2.4375 5.49219C2.55729 5.69531 2.7474 5.86979 3.00781 6.01562C3.27344 6.15625 3.61979 6.28385 4.04688 6.39844Z" fill="#CCCCCC" />
                                </svg>
                            </p>
                            <span>Amount</span>
                        </div>

                        <div className={style.withselect}>
                            <p>Bank Name</p>


                            <IonSelect mode="ios" className={style.select} onIonChange={(e) => {
                                setSelectValue(e.target.value)
                            }}>
                                <IonSelectOption value="001-01234-1234567" >001-01234-1234567</IonSelectOption>
                                <IonSelectOption value="001-01234-45454">001-01234-45454</IonSelectOption>
                                <IonSelectOption value="001-01234-443883">001-01234-443883</IonSelectOption>
                            </IonSelect>

                            <span>Amount</span>
                        </div>

                        <div className={style.withbtn} onClick={handleOnWithdrawal}>
                            <p>Withdrawal  {sumvalue !== '' && '$' + addCommas(sumvalue)}</p>
                        </div>

                        <div className={style.withtext}>
                            The bank may charge a small receiving fee of their choosing - 1 in a
                            million is thus unable to guarantee the final amount the recipient receives.
                            This transaction may take up to 10 business day.
                        </div>


                    </div>

                </IonContent>
            </IonModal>
            {/* pin code modal  */}
            {openAuth0Modal && <PinCode
                openAuth0Modal={openAuth0Modal}
                setOpenAuth0Modal={setOpenAuth0Modal}
                buyFunc={subWithdraw}
                afterBuyCloseModal={afterBuy} />}

            <IonAlert
                isOpen={openFaceIDFailedAlert}
                onDidDismiss={() => {
                    setOpenFaceIDFailedAlert(false)
                }}
                mode="ios"
                message={biometricFailedMessage}
                buttons={[
                    {
                        text: 'Yes',
                        role: 'yes',
                        handler: async () => {
                            setOpenAuth0Modal(true)
                        },
                    },
                    {
                        text: 'No',
                        role: 'no',
                        handler: async () => {
                        }
                    }
                ]}
            />
        </>
    )
}
export default WithdrawnModal