

import { IonAlert, IonContent, IonModal } from "@ionic/react";
import { MouseEvent, useEffect, useState } from "react";
import style from "../../../style/Friends.module.css";
// import { debounce } from "../../../utils/debounce";
import { Share } from "@capacitor/share";
import { useUser } from "hooks";
import { throttle } from "utils/throttle";
import { tokenApi } from "../../../api";
import { inviteFriendsByUserID, newFriendList } from '../../../api/chat';
import { setShowLoading, showToastMessage } from "../../../components/GlobalLoading";
import back from "../../../image/upcoming/ill.png";
import { useStore } from '../../../store';
import errorstyle from "../../../style/Upcoming.module.css";
import { getContacts } from "../../../utils/contacts";
import HelpModal from "../../account/component/helpModal";

interface Props {
}
type NewFriend =
    { choose: boolean; user_id: any; user_name: any; avatar: any; }

const NewChatModal: React.FC<Props> = () => {
    const { isNewChatModalOpen, changeModalState } = useStore();
    const { getProfile } = useUser()
    // const [list, setList] = useState<any>([])
    const [friendList, setFriendList] = useState<NewFriend[]>([])
    const [searchText, setSearchText] = useState('');


    // new friend
    const [type, setType] = useState('')

    const [openIOSContactsFailedAlert, setOpenIOSContactsFailedAlert] = useState(false);
    const [friendsName, setFriendsName] = useState('')

    const [address, setAddress] = useState('')
    const validateEmail = (email: any) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const [phoneNum, setPhoneNum] = useState('')

    const handleSearch = async () => {
        if (!searchText.trim()) return
        setShowLoading(true)
        try {
            const friendList = await newFriendList(searchText);
            const results = friendList.map((friend) => {

                return { ...friend, choose: false }
            })
            setFriendList(results);
            setShowLoading(false)
            setIsTyping(false);
        } catch (err) {
            showToastMessage('An unknown error has occurred, please refresh')
            setShowLoading(false)
        }



    }
    const throttledHandleSearch = throttle(handleSearch, 1000);

    const [isTyping, setIsTyping] = useState(false);
    useEffect(() => {
        const delay = 500;
        let timer: any;

        if (isTyping) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                throttledHandleSearch()
            }, delay);
        }

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, isTyping]);
    const handleInviteFriends = (friend: NewFriend) => {
        return async (event: MouseEvent<HTMLDivElement>) => {
            setShowLoading(true)
            try {
                await inviteFriendsByUserID({ friendIds: [friend.user_id] })
                showToastMessage('Successfully invited friend.')

            } catch (error: any) {
                showToastMessage(error.message)
            }
            finally {
                handleSearch()
                setShowLoading(false)
            }
            /*             const data = {
                            channelName: `${item.user_id}-${localGet('usename').user_address}`,
                            lang: '',
                            isPublic: false,
                            isGroup: false
                        }
                        await formDatatokenApi('/createChannel', data).then(async (res) => {
                            if (res?.detail?.msg.includes('exists')) {
                                await tokenApi('/channelInvite', {
                                    channelId: res.detail.data.id,
                                    friendIds: [item.id]
                                })
                                setShowLoading(false)
                                showToastMessage('Invitation was sent successfully.')
                                changeModalState('isNewChatModalOpen', false)
                            } else {
                                try {
                                    await tokenApi('/channelInvite', {
                                        channelId: res.data.id,
                                        friendIds: [item.id]
                                    })
                                    setShowLoading(false)
                                    showToastMessage('Invitation was sent successfully.')
                                    changeModalState('isNewChatModalOpen', false)
                                } catch (e: any) {
                                    setShowLoading(false)
                                    showToastMessage(e)
                                }
                            }
                        }) */
        }

    }
    const onOpenNewChatModalBefore = () => {

    }
    return (
        <IonModal
            isOpen={isNewChatModalOpen}
            mode="ios"
            onDidPresent={onOpenNewChatModalBefore
                /* async () => {
                                setShowLoading(true)
                                try {
                                    const privatePublicChannel: any = await client.channel('team', `hide-${userInfo?.user_metadata?.wallet_address}`);
                                    const queryMember: any = await privatePublicChannel.queryMembers({})
                                    const members: any = []
                                    queryMember?.members.map((item: any) => {
                                        members.push(item.user_id)
                                        return queryMember?.members
                                    })
                                    setBeFriends(members)
                                    setShowLoading(false)
                                } catch (error) {
                                    console.log(error);
                                    setShowLoading(false)
                                }
            } */
            }
            onDidDismiss={() => {
                setSearchText('')
                setFriendList([])
                setIsTyping(false)
                // setChooseOne({})
                changeModalState('isNewChatModalOpen', false)
            }}>
            <IonContent>
                <div className={style.newChatModal}>
                    {
                        type === '' ? <>
                            <div className={style.tit}>
                                <span onClick={() => changeModalState('isNewChatModalOpen', false)}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                                    </svg>
                                </span>
                                <div>
                                    <span>New Friends</span>
                                </div>
                                <span style={{ width: '56px' }} ></span>
                            </div>
                            <div className={style.addSerch} style={{ padding: '0 16px' }}>
                                <div>
                                    <input type="text" name="search" placeholder="Search new friends" value={searchText}
                                        onChange={async (e: any) => {
                                            setSearchText(e.target.value);
                                            setFriendList([])
                                            setIsTyping(true)
                                        }}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                throttledHandleSearch();
                                            }
                                        }}
                                    />
                                    <span onClick={throttledHandleSearch}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z" fill="white" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </> :
                            <div className={style.addFriendModal}>
                                <div className={style.tit} style={{ padding: 0 }}>
                                    {
                                        type === 'email' &&
                                        <>
                                            <span onClick={() => {
                                                setType('')
                                                setFriendsName('')
                                                setAddress('')
                                            }}>
                                                <svg
                                                    width="10"
                                                    height="16"
                                                    viewBox="0 0 10 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M8 14L2 8L8 2" stroke="white" strokeWidth="2" strokeLinecap="square" />
                                                </svg>
                                            </span>
                                            <span>Invite Friends by Email</span>
                                            <span></span>
                                        </>
                                    }
                                    {
                                        type === 'SMS' &&
                                        <>
                                            <span onClick={() => {
                                                setFriendsName('')
                                                setPhoneNum('')
                                                setType('')
                                            }}>
                                                <svg
                                                    width="10"
                                                    height="16"
                                                    viewBox="0 0 10 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M8 14L2 8L8 2" stroke="white" strokeWidth="2" strokeLinecap="square" />
                                                </svg>
                                            </span>
                                            <span>Invite Friends by SMS</span>
                                            <span></span>
                                        </>
                                    }
                                </div>
                            </div>
                    }
                    {
                        searchText === '' ?
                            <div className={style.addFriendModal}>
                                {
                                    type === "" &&
                                    <ul className={style.acts}>
                                        <li onClick={
                                            () => setType('email')
                                            // () => showToastMessage('This feature is not yet available.')
                                        }>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect x="2.75" y="4.09375" width="18.501" height="15.8125" rx="2.25" stroke="#A2AAB5" strokeWidth="1.5" />
                                                <path d="M2.76562 6.80994L11.9996 12.8904L21.2339 6.80994" stroke="#A2AAB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span>
                                                Invite Friends by Email
                                            </span>
                                        </li>
                                        <li onClick={async () => {
                                            const contactsResp: any = await getContacts();
                                            if (contactsResp === 'failed') {
                                                showToastMessage("This feature is not yet supported on your device.")
                                                return
                                            } else if (contactsResp === 'ios14.5+') {
                                                setOpenIOSContactsFailedAlert(true)
                                                return
                                            }
                                            const num = contactsResp[0]?.tel[0].replace(/-/g, "").replace(/\s/g, "");
                                            setPhoneNum(num)
                                            setFriendsName(contactsResp[0]?.name[0])
                                            setType('SMS')
                                            // showToastMessage('The function is not available yet.')
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M21.2282 11.9999C21.2282 13.1678 21.0067 14.2828 20.6036 15.3074C20.416 15.7842 20.433 16.3577 20.4797 16.8385C20.5185 17.2371 20.5913 17.6757 20.6595 18.0866C20.6772 18.1932 20.6945 18.2978 20.7109 18.3995C20.7947 18.919 20.8551 19.3703 20.8516 19.7357C20.8479 20.129 20.7694 20.2431 20.7576 20.2555C20.684 20.3331 20.5027 20.4341 20.0885 20.4793C19.688 20.523 19.1958 20.5023 18.6322 20.4589C18.4849 20.4475 18.3312 20.4344 18.1744 20.4211C17.7608 20.3859 17.3252 20.3489 16.9271 20.3367C16.3861 20.3201 15.7861 20.3417 15.2828 20.5311C14.2641 20.9147 13.158 21.1251 12.0003 21.1251C6.89659 21.1251 2.77246 17.0323 2.77246 11.9999C2.77246 6.96754 6.89659 2.87476 12.0003 2.87476C17.1041 2.87476 21.2282 6.96754 21.2282 11.9999Z" stroke="#A2AAB5" strokeWidth="1.5" />
                                                <circle cx="8.5" cy="12" r="1.25" fill="#A2AAB5" />
                                                <circle cx="12" cy="12" r="1.25" fill="#A2AAB5" />
                                                <circle cx="15.5" cy="12" r="1.25" fill="#A2AAB5" />
                                            </svg>
                                            <span>
                                                Invite Friends by SMS
                                            </span>
                                        </li>
                                        <li onClick={async () => {
                                            const value = await Share.canShare()
                                            if (value?.value) {
                                                setShowLoading(true)
                                                let res, user
                                                try {
                                                    res = await tokenApi('/api/inviteShare', {})
                                                    user = await getProfile()
                                                } catch (err: any) {
                                                    showToastMessage(err.message)
                                                }

                                                if (res.data.inviteID) {
                                                    const username = user?.user_metadata.user_name
                                                    Share.share({
                                                        title: `${username} has invited you to join the One in a Million app!`,
                                                        dialogTitle: `${username} has invited you to join the One in a Million app!`,
                                                        text: `Hi, ${username} Thinks you could be one in a million!
            Your friend ${username} and others have signed up for a chance to win 1 Million dollars. One lucky person is guaranteed to win and she is wondering, are you that person?
            As part of this exclusive invitation, we are giving you 58 reward gems as soon as you sign up to kickstart your lottery adventure! Spread the word and invite your friends to earn more gems. The more, the merrier, and who knows, you might even end up winning together! Thank you for taking the time to read this message.
            Click the link below and let's find out…${process.env.REACT_APP_BACKEND_BASE_URL?.replace('/api', '')}/share?code=${res.data.inviteID}`,
                                                        url: `${process.env.REACT_APP_BACKEND_BASE_URL?.replace('/api', '')}/share?code=${res.data.inviteID}`,
                                                    })
                                                    setShowLoading(false)

                                                }
                                            } else {
                                                showToastMessage("This feature is not yet supported on your device.")
                                            }
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.6209 12C20.6209 16.9697 16.5921 20.9984 11.6224 20.9984C6.65275 20.9984 2.62402 16.9697 2.62402 12C2.62402 7.03031 6.65275 3.00159 11.6224 3.00159" stroke="#A2AAB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.3975 3.00159H20.6081V9.21226" stroke="#A2AAB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M20.6086 3.00586L12.2393 11.3749" stroke="#A2AAB5" strokeWidth="1.5" strokeLinecap="round" />
                                            </svg>
                                            <span>
                                                Invite Friends by...
                                            </span>
                                        </li>
                                    </ul>
                                }
                                {
                                    type === 'email' &&
                                    <>
                                        <div className={style.contain}>
                                            <div className={style.inps}>
                                                <span>Email</span>
                                                <input type="text" value={address} onChange={(e) => setAddress(e.target.value.trim())} />
                                            </div>
                                        </div>
                                        <div
                                            className={style.create}
                                            onClick={async () => {
                                                setShowLoading(true)
                                                if (address === '') {
                                                    setShowLoading(false)
                                                    showToastMessage("Please enter your friend's email address.")
                                                    return
                                                }
                                                if (!validateEmail(address)) {
                                                    setShowLoading(false)
                                                    showToastMessage("Please enter the correct email address.")
                                                    return
                                                }

                                                await tokenApi('/inviteFriendByEmail', { friends: [address] }).then(async (res: any) => {
                                                    setShowLoading(false)
                                                    if (res?.code === 200) {
                                                        showToastMessage("The invitation email was sent successfully.")
                                                        setAddress('')
                                                        setType('')
                                                        changeModalState('isNewChatModalOpen', false)
                                                    } else if (res?.detail) {
                                                        showToastMessage(res?.detail)
                                                    } else {
                                                        showToastMessage('Fali to send.')
                                                    }
                                                })
                                            }
                                            }>
                                            Invite
                                        </div>
                                    </>
                                }
                                {
                                    type === 'SMS' &&
                                    <>
                                        <div className={style.contain}>
                                            <div className={style.inps}>
                                                <span>Name</span>
                                                <input type="text" value={friendsName} onChange={(e) => setFriendsName(e.target.value.trim())} />
                                            </div>
                                            <div className={style.inps}>
                                                <span>Phone</span>
                                                <input type="number"
                                                    style={{ paddingLeft: '24px' }}
                                                    // disabled={true}
                                                    value={phoneNum}
                                                    onKeyDown={(e) => {
                                                        const { key } = e;
                                                        const filteredKeys = new Set(["e", "+", "-", "."]);
                                                        if (filteredKeys.has(key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => setPhoneNum(e.target.value.replace(/^0+/, '').replace(/[^\d]/g, ''))}
                                                />
                                                <div>+1</div>
                                            </div>
                                        </div>
                                        <div className={style.create} onClick={() => {
                                            setShowLoading(true)
                                            tokenApi('/inviteFriendBySMS', {
                                                friends: [`+1${phoneNum}`]
                                            }).then(async (res) => {
                                                if (res.detail) {
                                                    showToastMessage(res.detail)
                                                } else {
                                                    changeModalState('isNewChatModalOpen', false)
                                                    showToastMessage('The invitation SMS was sent successfully.')
                                                }
                                                setShowLoading(false)
                                            })

                                        }}>Invite</div>
                                    </>
                                }
                            </div>
                            :
                            isTyping === false ?
                                friendList?.length > 0 ?
                                    <ul className={style.addlists}>
                                        {
                                            friendList?.map((item) => (
                                                <li key={item.user_id}
                                                    style={item.choose ? { background: '#2D323E' } : {}}>
                                                    <div>
                                                        <img src={item.avatar} alt="avatar" />
                                                    </div>
                                                    <span>
                                                        {item.user_name}
                                                        <div onClick={handleInviteFriends(item)}
                                                        >Invite</div>
                                                    </span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    :
                                    <div className={errorstyle.cartex}>
                                        <img src={back} alt="" />
                                        <p style={{ width: '100%', textAlign: 'center' }}>Friends have been invited or not found.</p>
                                    </div>
                                : <></>
                    }
                </div>
                <IonAlert
                    isOpen={openIOSContactsFailedAlert}
                    onDidDismiss={() => {
                        setOpenIOSContactsFailedAlert(false)
                    }}
                    mode="ios"
                    message="Need to open Contact Picker API in Safari settings to use contact function, do you want to watch the help video?"
                    buttons={[
                        {
                            text: 'Yes',
                            role: 'yes',
                            handler: async () => {
                                changeModalState('isHelpModalOpen', true)
                            },
                        },
                        {
                            text: 'No',
                            role: 'no',
                            handler: async () => {
                            }
                        }
                    ]}
                />
                {/* help modal  */}
                <HelpModal />
            </IonContent>
        </IonModal>
    )
}
export default NewChatModal