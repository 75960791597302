import { saveFaceId, selectFaceId } from "../db/schema/faceId";

const enc = new TextEncoder();

function generateRandomChallenge() {
  const randomBuffer: any = new Uint8Array(32);
  window.crypto.getRandomValues(randomBuffer);
  return btoa(String.fromCharCode.apply(null, randomBuffer));
}

function strToBin(str: any) {
  return Uint8Array.from(atob(str), (c) => c.charCodeAt(0));
}

function binToStr(bin: any) {
  return btoa(
    new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), "")
  );
}

let isUserVerifyingPlatformAuthenticatorAvailable = false;
if (window.PublicKeyCredential) {
  PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((res) => {
    isUserVerifyingPlatformAuthenticatorAvailable = res;
    console.log(isUserVerifyingPlatformAuthenticatorAvailable);
  });
}

export const createCreds = async function () {
  // return 'not';
  // const isUserVerifyingPlatformAuthenticatorAvailable = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  // if (!isUserVerifyingPlatformAuthenticatorAvailable) {
  //   return 'not';
  // }

  if (!isUserVerifyingPlatformAuthenticatorAvailable) {
    return 'not';
  }

  const randomChallenge: any = generateRandomChallenge();

  const publicKey: any = {
    challenge: enc.encode(randomChallenge),
    rp: {
      name: "OIAM",
    },
    user: {
      id: enc.encode(new Date().getTime().toString()),
      name: "***",
      displayName: "OIAM",
    },
    authenticatorSelection: {
      userVerification: "preferred",
      authenticatorAttachment: "platform",
    },
    attestation: "direct",
    pubKeyCredParams: [
      {
        type: "public-key",
        alg: -7, // ES256
      },
      {
        type: "public-key",
        alg: -257, // RS256
      },
    ],
  };

  const res: any = await navigator.credentials.create({
    publicKey: publicKey,
  });

  const result = res.rawId !== undefined || res.rawId !== null;
  if (result) {
    await saveFaceId(binToStr(res.rawId), randomChallenge)
  }

  return result;
};

export const validateCreds = async function () {
  // const isUserVerifyingPlatformAuthenticatorAvailable = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  // if (!isUserVerifyingPlatformAuthenticatorAvailable) {
  //   return 'not';
  // }

  if (!isUserVerifyingPlatformAuthenticatorAvailable) {
    return 'not';
  }

  const faceId = await selectFaceId();
  if (faceId === null) {
    return 'null';
  }

  const rawId = faceId.faceId;
  const AUTH_CHALLENGE: any = faceId.challenge;
  const publicKey: any = {
    challenge: enc.encode(AUTH_CHALLENGE),
    allowCredentials: [
      {
        id: strToBin(rawId),
        type: "public-key",
      },
    ],
    authenticatorSelection: {
      userVerification: "preferred",
      authenticatorAttachment: "platform",
    },
  };
  const res: any = await navigator.credentials.get({
    publicKey: publicKey,
  });

  // console.log(res);

  const extractedData = {
    id: res.id,
    rawId: binToStr(res.rawId),
    clientDataJSON: binToStr(res.response.clientDataJSON),
  };

  const dataFromClient = JSON.parse(atob(extractedData.clientDataJSON));
  const retrievedChallenge = atob(dataFromClient.challenge);

  // console.log(retrievedChallenge);
  //   if (retrievedChallenge === AUTH_CHALLENGE) {
  //     alert("success");
  //   } else {
  //     alert("failed");
  //   }
  return retrievedChallenge === AUTH_CHALLENGE;
};
