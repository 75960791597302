import * as Database from '../';

export const shareSchema = {
    title: 'shareSchema',
    description: 'share info',
    version: 1,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            maxLength: 100,
        },
        voucherId: {
            type: 'string',
            maxLength: 100,
        }
    }
};

export const saveShare = async (shareId, voucherId) => {
    const db = await Database.get();
    await db.share.upsert({ id: shareId, voucherId: voucherId });
};

export const getShare = async () => {
    const db = await Database.get();
    return await db.share.findOne().exec();
};

export const deleteShare = async () => {
    const db = await Database.get();
    await db.share.remove();
};