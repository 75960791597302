Storage.prototype.setItem = new Proxy(Storage.prototype.setItem, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent('localstorage', {
      detail: {
        key: argumentList[0],
        oldValue: thisArg.getItem(argumentList[0]),
        newValue: argumentList[1]
      }
    });
    window.dispatchEvent(event);
    return Reflect.apply(target, thisArg, argumentList);
  }
});

Storage.prototype.removeItem = new Proxy(Storage.prototype.removeItem, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent('localstorage', {
      detail: {
        key: argumentList[0]
      }
    });
    window.dispatchEvent(event);
    return Reflect.apply(target, thisArg, argumentList);
  }
});

Storage.prototype.clear = new Proxy(Storage.prototype.clear, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent('localstorage', {
      detail: {
        key: '__all__'
      }
    });
    window.dispatchEvent(event);
    return Reflect.apply(target, thisArg, argumentList);
  }
});
export const localGet = (key: string, isExpire = false) => {
  if (typeof window !== 'undefined') {
    let stringValue = localStorage.getItem(key);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);
      if (value.expirationDate) {
        let expirationDate = new Date(value.expirationDate);
        if (isExpire || expirationDate > new Date()) {
          return value.value;
        } else {
          localStorage.removeItem(key);
        }
      } else {
        return value.value;
      }
    }
    return null;
  }
};

export const localSet = (key: string, value: any, expirationInMin?: number) => {
  if (typeof window !== 'undefined') {
    let newValue;
    if (expirationInMin) {
      let expirationDate = new Date(
        new Date().getTime() + 60000 * expirationInMin
      );
      newValue = {
        value,
        expirationDate: expirationDate.toISOString()
      };
    } else {
      newValue = {
        value
      };
    }
    localStorage.setItem(key, JSON.stringify(newValue));
  }
};
